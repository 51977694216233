import React, { useState, useRef, useMemo, useContext } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { SessionContext, SessionStatus } from './SessionWrapper';
import ModelBusy from './ModelBusy';
import './dialogs.scss';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mtb/ui';

const CSV = 'csv';
const MWX = 'mwx';

const DownloadDataDialog = ({
  id,
  name,
  onDownloadError,
  download,
  prepareDownload,
  header,
  busyHeader,
  closeDialog
}) => {
  const [t] = useTranslation();

  const downloadRef = useRef(null);

  const [radioGroupValue, setRadioGroupValue] = useState(CSV);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadMessageSent, setDownloadMessageSent] = useState(false);

  const session = useContext(SessionContext);

  const queryKey = useMemo(() => `download-${id}-${radioGroupValue}`, [id, radioGroupValue]);
  useQuery(queryKey, () => download(id, radioGroupValue), {
    onSuccess: async blob => {
      if (!downloadMessageSent) {
        setDownloadMessageSent(true);
        await prepareDownload(id, radioGroupValue);
      } else if (blob?.size > 0) {
        const a = downloadRef.current;
        a.href = window.URL.createObjectURL(blob);
        a.download = `${name}.${radioGroupValue}`;
        a.click();
        window.URL.revokeObjectURL(blob);
        a.href = '';
        a.download = '';
        setDownloadLoading(false);
        closeDialog();
      }
    },
    onError: async error => {
      if (!downloadMessageSent) {
        // Error from previous download, retry.
        setDownloadMessageSent(true);
        await prepareDownload(id, radioGroupValue);
      } else {
        console.error(error);
        setDownloadLoading(false);
        closeDialog();
        onDownloadError(error.body?.errorCode);
      }
    },
    enabled        : downloadLoading && session?.sessionStatus !== SessionStatus.Expired,
    refetchInterval: 1000,
  });

  return (
    <>
      {!downloadLoading &&
        <div className='dialogContainer'>
          <div className='modalDialog downloadData'>
            <h3>{header}</h3>
            <FormControl className='download-type-container'>
              <FormLabel id='download-type-group-label'>{t('fileFormat')}</FormLabel>
              <RadioGroup
                aria-labelledby='download-type-group-label'
                name='download-type-group'
                value={radioGroupValue}
                onChange={(e) => setRadioGroupValue(e.target.value)}>
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={t('csv')}
                  value={CSV} />
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={t('minitabWorksheet')}
                  value={MWX} />
              </RadioGroup>
            </FormControl>
            <div className='buttons'>
              <button
                className='cancelButton'
                onClick={() => {
                  setDownloadLoading(false);
                  closeDialog();
                }}>
                {t('cancel')}
              </button>
              <button
                className='confirmButton'
                onClick={() => setDownloadLoading(true) }>
                {t('ok')}
              </button>
            </div>
          </div>
        </div>
      }
      {downloadLoading &&
        <ModelBusy
          title={busyHeader || header}
          onCancel={() => {
            setDownloadLoading(false);
            closeDialog();
          }} />
      }
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a
        ref={downloadRef}
        className='hidden-download'
        tabIndex={-1} />
    </>
  );
};

export default DownloadDataDialog;