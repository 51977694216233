import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mtb/ui';
import { formatShortLocalDateTime, isDateRangeCurrent } from '../utils/locales';

// TODO: Delete this component once drift_navigation flag is defaulted to true
const BinNavigation = (props) => {
  const {
    additionalInfo,
    binDateRange,
    binIndex,
    totalBins,
    setBinIndex,
    setIsCurrentDate,
    timezone
  } = props;

  const [t] = useTranslation();
  const [disableMoveForward, setDisableMoveForward] = useState();
  const [disableMoveBackward, setDisableMoveBackward] = useState();

  const formattedDateRange = useMemo(() => {
    return {
      start: formatShortLocalDateTime(binDateRange.start, timezone),
      end  : formatShortLocalDateTime(binDateRange.end, timezone)
    };
  }, [timezone, binDateRange.end, binDateRange.start]);

  useEffect(() => {
    setDisableMoveForward(binIndex === totalBins - 1);
    setDisableMoveBackward(binIndex === 0);
    const { start, end } = binDateRange;
    setIsCurrentDate(isDateRangeCurrent(start, end));
  }, [binIndex, totalBins, binDateRange, setIsCurrentDate]);

  return (
    <div className="period-selector">
      <div className="period-label-and-toggle-buttons">
        <label>{t('dateRange', formattedDateRange)}</label>
        {additionalInfo}
        <div className="nav-buttons">
          <Button
            disabled={disableMoveBackward}
            onClick={() => setBinIndex(binIndex - 1)}>
            {t('previous')}
          </Button>
          <Button
            disabled={disableMoveForward}
            onClick={() => setBinIndex(binIndex + 1)}>
            {t('next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BinNavigation;