import React from 'react';
import { ClickAwayListener } from '@mtb/ui';

import './context-menu.scss';

const ContextMenu = ({ children, onClose }) => {
  return (
    <div className="context-menu">
      <ClickAwayListener
        onClickAway={onClose}>
        {children}
      </ClickAwayListener>
    </div>
  );
};

export default ContextMenu;