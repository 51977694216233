import React, { useCallback, useEffect, useState, useContext } from 'react';
import { ModelerLogo, UploadIcon } from '../images';
import { Icon } from 'react-icons-kit';
import { ic_help, ic_person, ic_settings, ic_warning } from 'react-icons-kit/md';
import { serializeError } from 'serialize-error';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, MenuItem, Select } from '@mtb/ui';
import { availableLocales, supportedLanguages, toLocalizedString, formatTime } from '../utils/locales';
import Settings from '../api/settings';
import ContextMenu from './ContextMenu';
import { ErrorsContext, FlagsContext, SettingsContext } from '../utils/context';
import { applyGlobalHighchartOptions } from '../utils/chart-utils';

import './header.scss';
import './dialogs.scss';

function LanguageDialog({ setSettings, setShowLanguageSettings }) {
  const [t] = useTranslation();
  const { onError } = useContext(ErrorsContext);
  const settings = useContext(SettingsContext);

  const availableLanguages = availableLocales.filter(ac => supportedLanguages.includes(ac.value));
  const defaultRegionLocale =
    availableLocales.find(
      al => al.value.startsWith(settings?.locale?.regionCode) && al.value.endsWith(settings?.locale?.regionCode),
    ) || availableLocales[0];
  const defaultLanguageLocale =
    availableLocales.find(
      al => al.code === settings?.locale?.languageCode,
    ) || availableLanguages[0];
  const [regionLocale, setRegionLocale] = useState(defaultRegionLocale);
  const [languageLocale, setLanguageLocale] = useState(defaultLanguageLocale);

  const updateLanguageSettings = async () => {
    try {
      if (defaultRegionLocale !== regionLocale || defaultLanguageLocale !== languageLocale) {
        const locale = {
          languageCode: languageLocale.code,
          regionCode  : regionLocale.value
        };
        await Settings.patch({ locale });
        setSettings({ ...settings, locale });
        applyGlobalHighchartOptions(regionLocale);
      }
    } catch (e) {
      onError(serializeError(e));
    } finally {
      setShowLanguageSettings(false);
    }
  };

  return (
    <div className="dialogContainer">
      <div className="modalDialog languageSettings">
        <h3>{t('languageSettings')}</h3>
        <div className='instructions'>{t('languageSettingsDescription')}</div>
        <div className='languageContent'>
          <div className='locale'>
            <div className='formControl language'>
              <FormControl>
                <FormLabel>{t('languageHeader')}</FormLabel>
                <Select
                  disabled={availableLanguages.length === 1}
                  fullWidth
                  id='languageSelect'
                  value={languageLocale}
                  onChange={(e) => setLanguageLocale(e.target.value)}>
                  {availableLanguages.map(locale => (
                    <MenuItem
                      key={locale.value}
                      value={locale}>{locale.language}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='formControl region'>
              <FormControl
                fullWidth>
                <FormLabel>{t('regionHeader')}</FormLabel>
                <Select
                  disabled={availableLocales.length === 1}
                  fullWidth
                  id='regionSelect'
                  value={regionLocale}
                  onChange={(e) => setRegionLocale(e.target.value)}>
                  {availableLocales.map(locale => (
                    <MenuItem
                      key={locale.value}
                      value={locale}>{locale.region}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='examples'>
            <div className='example'>
              <div className='exampleHeader'>{t('numberFormatHeader')}</div>
              <div className='exampleValue'>{toLocalizedString(1123.24, regionLocale.value)}</div>
            </div>
            <div className='example'>
              <div className='exampleHeader'>{t('dateTimeFormatHeader')}</div>
              <div className='exampleValue'>{formatTime(new Date())}</div>
            </div>
            <div className='example'>
              <div className='exampleHeader'>{t('listHeader')}</div>
              <div className='exampleValue'>{t('listExample', { listSeparator: regionLocale.listSeparator })}</div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button
            className="cancelButton"
            onClick={() => setShowLanguageSettings(false)}>
            {t('cancel')}
          </button>
          <button
            className="confirmButton"
            onClick={updateLanguageSettings}>
            {t('ok')}
          </button>
        </div>
      </div>
    </div>
  );
}

function Header({ pending, clearErrors, user, setSettings }) {
  const [t] = useTranslation();
  const flags = useContext(FlagsContext);
  const { errors } = useContext(ErrorsContext);

  const [showUserInformationDropdown, setShowUserInformationDropdown] = useState(false);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showLanguageSettings, setShowLanguageSettings] = useState(false);
  const [accountUri, setAccountUri] = useState();

  const toggleUserInformationMenu = useCallback(e => {
    e?.stopPropagation();
    setShowUserInformationDropdown(prev => !prev);
  }, []);

  const toggleSettingsMenu = useCallback(e => {
    e?.stopPropagation();
    setShowSettingsDropdown(prev => !prev);
  }, []);

  useEffect(() => {
    if (!user || !user.identifyingPartyUri) {
      return;
    }
    setAccountUri(`${user.identifyingPartyUri.replace(/\/$/, '')}/account`);
  }, [user]);

  return (
    <>
      <div className="header">
        <div className="logo">
          <img
            alt="Logo"
            src={ModelerLogo} />
        </div>
        <div className="title">
          <h1>Minitab Model Ops<sup>&reg;</sup></h1>
        </div>
        <div className="status-tray">{pending && <UploadIcon />}</div>
        {errors.length > 0 && (
          <div
            className="header-tool error-icon"
            onClick={() => setShowErrors(true)}>
            <Icon
              icon={ic_warning}
              size={'100%'}
              title={t('errorDetails')} />
          </div>
        )}
        <div
          className="header-tool help-icon"
          onClick={() => window.open(flags.helpURL)}>
          <Icon
            icon={ic_help}
            size={'100%'}
            title={t('help')} />
        </div>
        {user && (
          <>
            <div
              className="header-tool settings-icon"
              onClick={toggleSettingsMenu}>
              <Icon
                icon={ic_settings}
                size={'100%'}
                title={t('settings')} />
              {showSettingsDropdown && (
                <ContextMenu onClose={toggleSettingsMenu}>
                  <div
                    className="link"
                    title={t('languageSettings')}
                    onClick={() => setShowLanguageSettings(true)}>{t('languageSettings')}</div>
                </ContextMenu>
              )}
            </div>
            <div
              className="header-tool user-icon"
              onClick={toggleUserInformationMenu}>
              <Icon
                icon={ic_person}
                size={'100%'}
                title={t('userProfile')} />
              {showUserInformationDropdown && (
                <ContextMenu onClose={toggleUserInformationMenu}>
                  <div className="name">{user.name}</div>
                  <div className="email">{user.email}</div>
                  <div className="link">
                    <a
                      href={accountUri}
                      title={t('manageAccount')}>{t('manageAccount')}</a>
                  </div>
                  <div className="link">
                    <a
                      href="/account/signout"
                      title={t('signOut')}>{t('signOut')}</a>
                  </div>
                </ContextMenu>
              )}
            </div>
          </>
        )}
      </div>

      {showErrors && (
        <div className="dialogContainer">
          <div className="modalDialog errorInfo">
            <h3>{t('errors')}</h3>
            {errors.length === 0
              ? <p>{t('noCurrentlyReportedErrors')}</p>
              : <div className="errorList">
                {errors.sort((first, second) => (first.timeStamp > second.timeStamp ? -1 : 1))
                  .map(e => (
                    <div
                      key={e.timeStamp}
                      className="errorDetails">
                      <label>{t('incidentDetails')}</label>
                      <p>{t('timestampInfo', { timestamp: e.timeStamp })}</p>
                      <p>{t('messageInfo', { message: e.originalError.message })}</p>
                    </div>
                  ))
                }
              </div>
            }
            <div className="buttons">
              <button
                className="cancelButton"
                onClick={clearErrors}>
                {t('clear')}
              </button>
              <button
                className="confirmButton"
                onClick={() => setShowErrors(false)}>
                {t('close')}
              </button>
            </div>
          </div>
        </div>
      )}

      {showLanguageSettings && (
        <LanguageDialog
          setSettings={setSettings}
          setShowLanguageSettings={setShowLanguageSettings} />
      )}
    </>
  );
}

export default Header;
