import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { LoadingSpinner } from '../images';
import './LoadingWrapper.scss';

const LoadingWrapper = ({ caption, delay, className, isLoading = true, inline = false }) => {
  const [show, setShow] = useState(isLoading);
  // If the value changes before the delay timeout ends, it needs be reflected after the delay has passed.
  // The only way to do this in a setTimeout is with a reference, otherwise it'll use the value it was invoked with.
  const isLoadingRef = useRef(isLoading);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setShow(isLoadingRef.current);
      }, delay || 0);
    } else {
      setShow(false);
    }
  }, [isLoading, delay]);

  useEffect(() => isLoadingRef.current = isLoading, [isLoading]);

  return (show ?
    <div className={classNames('loading-wrapper', { inline, ...(className && { [className]: true }) })}>
      <div>
        <LoadingSpinner className='loading-spinner' />
        {caption && <div className="caption">{caption}</div>}
      </div>
    </div>
    : null);
};

export default LoadingWrapper;