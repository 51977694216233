import moment from 'moment-timezone';
import Models from '../api/models';
import {
  DriftStatusMinimalIcon,
  DriftStatusModerateIcon,
  DriftStatusSevereIcon
} from '../images';
import { SCORING_BACKDATE_LIMIT } from '../components/Integration';

const DEFAULT_DATE_FORMAT = 'MMMM D, YYYY, HH:mm:ss';
const ALTERNATE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const PeriodSettings = {
  Daily    : 'day',
  Weekly   : 'week',
  Monthly  : 'month',
  Quarterly: 'quarter'
};

export const DefaultSettings = {
  Timezone                   : 'UTC',
  AdjustedOffset             : 0,
  IdVariableName             : 'ObservationID',
  RequireIdVariable          : 'false',
  TimestampVariableName      : 'Timestamp',
  ProductionDataPeriod       : PeriodSettings.Weekly,
  ModerateDriftThreshold     : 0.1,
  SevereDriftThreshold       : 0.2,
  ShowModeratePredictionDrift: 'true',
  ShowSeverePredictionDrift  : 'true',
  ShowModerateDriftVariables : 'false',
  ShowSevereDriftVariables   : 'false',
  NumModerateDriftVariables  : 1,
  NumSevereDriftVariables    : 1
};

export const convertToUTC = (timestamp, useAlternateFormat = false) => {
  const format = useAlternateFormat ? ALTERNATE_DATE_FORMAT : DEFAULT_DATE_FORMAT;
  return timestamp ? `${moment.utc(timestamp).format(format)}Z` : '';
};

export const sortHelper = ((a, b, sortAscending) => {
  if (typeof a === 'string' && typeof b === 'string') {
    return sortAscending ?
      a.localeCompare(b, undefined, { sensitivity: 'case' }) :
      b.localeCompare(a, undefined, { sensitivity: 'case' });
  }
  return sortAscending ?
    (a < b ? -1 : a > b ? 1 : 0) :
    (a > b ? -1 : a < b ? 1 : 0);
});

export const round = (val, precision) => {
  try {
    // Note the plus sign that drops any trailing zeroes in order to only use as many decimal places as necessary.
    // e.g. let x = 1.5; x.toFixed(2) returns the string '1.50'
    // but +x.toFixed(2) = +'1.50' = 0 + '1.50' = 0 + 1.50 (implicit coercion) = 1.5.
    return +val.toFixed(precision);
  } catch (err) {
    // Undefined or non-numeric value
    return val;
  }
};

export const getPromotions = async (t, promotions) => {
  const uniqueModelIds = [...new Set(promotions?.map(entry => entry[0]))];
  const promises = uniqueModelIds.map(id => id && Models.get(id, 'name').catch(() => ''));
  const modelNames = await Promise.all(promises);
  // Promises come back in the same order as they were sent
  return promotions?.reduce((list, entry) => {
    const [modelId, timestamp] = entry;
    const index = uniqueModelIds.indexOf(modelId);
    const modelName = (index >= 0 && modelNames[index].name) ?? t('unknown');
    return [...list, [modelName, timestamp, modelId]];
  }, []);
};

export const getPromotionsForModel = (modelId, promotions) => {
  const filteredPromotions = [];
  for (let i = 0; i < promotions.length; i++) {
    const [id, timestamp] = promotions[i];
    if (id === modelId) {
      const start = new Date(timestamp);
      // Very first promotion is initial challenger, add 3 month backdate allowance
      if (i === 0) {
        start.setUTCMonth(start.getUTCMonth() - SCORING_BACKDATE_LIMIT);
        start.setUTCHours(0, 0, 0, 0);
      }
      filteredPromotions.push({
        start,
        end: i === promotions.length - 1 ? new Date() : new Date(promotions[i + 1][1])
      });
    }
  }
  return filteredPromotions;
};

export const getDriftStatusIcon = severity => {
  switch (severity) {
    case 'minimal':
      return <DriftStatusMinimalIcon />;
    case 'moderate':
      return <DriftStatusModerateIcon />;
    case 'severe':
      return <DriftStatusSevereIcon />;
    default:
  }
};

export const getOverallDriftTooltip = (t, deployment, showLocalTimeReminder) => {
  if (!deployment.severity) {
    return t('overallDriftTooltipNoStatus');
  }

  const timezone = deployment.timezone ?? 'UTC';
  const period = deployment.production_data_period ?? DefaultSettings.ProductionDataPeriod;
  const date = moment.tz(deployment.severity_updated, timezone);

  // Get date range for the last calculated production data period
  const startDate = date.clone().subtract(1, period).format('YYYY-MM-DD');
  const endDate = date.clone().subtract(1, 'millisecond').format('YYYY-MM-DD');

  let overallDriftKey = startDate === endDate ? 'overallDriftTooltip' : 'overallDriftTooltipRange';
  if (showLocalTimeReminder) {
    overallDriftKey += 'DeploymentTime';
  }
  return t(overallDriftKey, { startDate, endDate });
};

const dateFormatString = '[1-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]';
const dateTimeFormat1 = new RegExp(`^${dateFormatString}$`);
const timeFormat1String = '[0-2][0-9]:[0-5][0-9]:[0-5][0-9]';
const timeZoneString = '(Z?|[+-][0-1][0-9](|:?[0-5][0-9]))';
const dateTimeFormat2 = new RegExp(`^${dateFormatString}( | T)${timeFormat1String}${timeZoneString}$`);
const timeFormat2String = `${timeFormat1String}(.[0-9][0-9][0-9])?`;
const dateTimeFormat3 = new RegExp(`^${dateFormatString}T${timeFormat2String}${timeZoneString}$`);
export const isDateTime = (value) =>
  value.match(dateTimeFormat1) || value.match(dateTimeFormat2) || value.match(dateTimeFormat3);

export const isUniqueName = (current, newName) =>
  !current?.some(i => i.name.toLowerCase() === newName.trim().toLowerCase());

export const isNumeric = value =>
  value.toString().trim().length && Number(value) !== Infinity && (!isNaN(value) || isDateTime(value));

// Takes a stringified object with escape characters and parses it for display.
// e.g. \"1\" \\ \"\"2\"\" becomes "1" \ ""2""
export const removeEscapeChars = str => {
  return str
    .replace(/(^|[^\\])(\\\\)*\\$/, '$&\\')
    .replace(/(?:\\(.))/g, '$1');
};

export const escapeSingleQuotes = str => str.replace(/'/g, '\'\\\'\'');

export const removeWrappedQuotes = value => {
  if (typeof value === 'string' && value.length > 1) {
    const [first, last] = [value.slice(0, 1), value.slice(value.length - 1)];
    if (first === last && ['\'', '"'].includes(first)) {
      const cleanValue = value.slice(1, value.length - 1);
      return cleanValue;
    }
  }
  return value;
};

export const removeQuotesFromNumeric = str =>
  str.replace(/(?:")([+-])?(?:0+(?!\.))?(\d*\.?\d+)([eE][-+]?\d+)?(?:")/g, '$1$2$3');

export const encodeEnclosingBrackets = obj => {
  Object.entries(obj).forEach(([k, v]) => {
    if (/\[.+?\]/.test(k)) {
      obj[encodeURIComponent(k)] = v;
      delete obj[k];
    }
  });
};

export const formatModelNameForDisplay = name => name.replace(/ /g, '\u00A0').normalize('NFD');