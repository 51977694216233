/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import _isEqual from 'lodash/isEqual';
import _isInteger from 'lodash/isInteger';
import _isNumber from 'lodash/isNumber';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@mtb/ui';
import './Settings.scss';
import { useHistory } from 'react-router-dom';
import { getTimeOfDay, getOffset } from '../utils/locales';
import { DefaultSettings as Defaults, PeriodSettings } from '../utils/model-utils';
import ValidationDialog from './ValidationDialog';
import { LocalizedInput, InputTypes } from './Inputs';
import DataPeriod from './DataPeriod';
import EmailSelector from './EmailSelector';
import TimezoneSelector from './TimezoneSelector';
import LoadingWrapper from './LoadingWrapper';
import Deployments from '../api/deployments';

const MAX_NAME_LENGTH = 50;

const ErrorCodes = {
  MetadataNotUnique: 'MetadataNotUnique',
  NumDriftPredVar  : 'InvalidNumDriftPredVar',
  InvalidThreshold : 'InvalidThresholdValue'
};

const LabelInputError = ({ label, value, placeholder, changeValue, errorText, disabled = false }) => {
  return (
    <div className='label-input'>
      <label>{label}</label>
      <div className='input-area'>
        <input
          className={classNames({ 'error': !!errorText })}
          disabled={disabled}
          maxLength={MAX_NAME_LENGTH}
          placeholder={placeholder}
          value={value ?? ''}
          onChange={e => changeValue(e.target.value)} />
        {!!errorText && <div className='error-info'>{errorText}</div>}
      </div>
    </div>);
};

const Settings = ({
  id,
  metadata,
  setMetadata,
  confirm }) => {
  const history = useHistory();
  const [t] = useTranslation();
  const location = useRef();

  const [refresh, setRefresh] = useState(true);
  const [emails, setEmails] = useState();
  const [invalidFields, setInvalidFields] = useState({});
  const [canModifyVariableId, setCanModifyVariableId] = useState(metadata?.modifiablevarid === 'true');
  const [continueNav, setContinueNav] = useState(() => () => {});
  const [showPendingChangesDialog, setShowPendingChangesDialog] = useState(false);
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const [navigateAfter, setNavigateAfter] = useState(false);
  const [modifiedSettings, setModifiedSettings] = useState([]);

  const isDefaultSetting = (newValue, settingName) => {
    const defaults = {
      timezone                   : metadata?.timezone ?? Defaults.Timezone,
      adjustedOffset             : metadata?.adjusted_offset ?? Defaults.AdjustedOffset,
      requireIdVariable          : metadata?.require_id_var_to_score ?? Defaults.RequireIdVariable,
      idVarName                  : metadata?.id_variable_name ?? Defaults.IdVariableName,
      timestampVarName           : metadata?.timestamp_variable_name ?? Defaults.TimestampVariableName,
      productionDataPeriod       : metadata?.production_data_period ?? Defaults.ProductionDataPeriod,
      moderateDriftThreshold     : metadata?.moderate_drift_threshold ?? Defaults.ModerateDriftThreshold,
      severeDriftThreshold       : metadata?.severe_drift_threshold ?? Defaults.SevereDriftThreshold,
      showModeratePredictionDrift: metadata?.show_moderate_prediction_drift ?? Defaults.ShowModeratePredictionDrift,
      showSeverePredictionDrift  : metadata?.show_severe_prediction_drift ?? Defaults.ShowSeverePredictionDrift,
      showModerateDriftVariables : metadata?.show_moderate_drift_variables ?? Defaults.ShowModerateDriftVariables,
      showSevereDriftVariables   : metadata?.show_severe_drift_variables ?? Defaults.ShowSevereDriftVariables,
      numModerateDriftVariables  : metadata?.num_moderate_drift_variables ?? Defaults.NumModerateDriftVariables,
      numSevereDriftVariables    : metadata?.num_severe_drift_variables ?? Defaults.NumSevereDriftVariables,
      emails                     : metadata?.alert_emails ? JSON.parse(metadata.alert_emails) : []
    };

    switch (settingName) {
      case 'idVarName':
      case 'timestampVarName':
        return newValue === '' || newValue === defaults[settingName];
      case 'emails':
        return _isEqual(newValue, defaults[settingName]);
      // For CC, severe and moderate settings are merged into one setting.
      // To avoid creating another setting value, we will evaluate settings on OR basis, meaning
      // show prediction drift is true if either show moderate or show severe is true. On save
      // both settings will be updated with the new value and be the same
      case 'showModeratePredictionDrift':
      case 'showSeverePredictionDrift':
        return newValue ===
          (defaults.showModeratePredictionDrift === 'true' || defaults.showSeverePredictionDrift === 'true').toString();
      case 'showModerateDriftVariables':
      case 'showSevereDriftVariables':
        return newValue ===
          (defaults.showModerateDriftVariables === 'true' || defaults.showSevereDriftVariables === 'true').toString();
      // Number of drift predictor variables will always default to the moderate setting and
      // update to be the same number on save
      case 'numSevereDriftVariables':
        return newValue === defaults['numModerateDriftVariables'].toString();
      case 'moderateDriftThreshold':
      case 'severeDriftThreshold':
        return defaults[settingName] === newValue.value && InputTypes.Numeric === newValue.type;
      case 'timeOfDay':
        return newValue === getTimeOfDay(defaults.timezone, Number(defaults.adjustedOffset));
      case 'adjustedOffset':
        return newValue === Number(defaults.adjustedOffset);
      default:
        return newValue === defaults[settingName].toString();
    }
  };

  // Default model monitors settings; will be in refresh useEffect
  const [timezone, setTimezone] = useState();
  const [timeOfDay, setTimeOfDay] = useState();
  const [idVariableName, setIdVariableName] = useState();
  const [requireIdVariable, setRequireIdVariable] = useState();
  const [timestampVariableName, setTimestampVariableName] = useState();
  const [productionDataPeriod, setProductionDataPeriod] = useState();
  const [moderateDriftThreshold, setModerateDriftThreshold] = useState({});
  const [severeDriftThreshold, setSevereDriftThreshold] = useState({});
  const [showModeratePredictionDrift, setShowModeratePredictionDrift] = useState();
  const [showSeverePredictionDrift, setShowSeverePredictionDrift] = useState();
  const [showModerateDriftVariables, setShowModerateDriftVariables] = useState();
  const [showSevereDriftVariables, setShowSevereDriftVariables] = useState();
  const [numModerateDriftVariables, setNumModerateDriftVariables] = useState();
  const [numSevereDriftVariables, setNumSevereDriftVariables] = useState();

  useEffect(() => {
    let current = true;
    if (current && refresh && !pendingSubmit) {
      (async () => {
        const currentMetadata = await Deployments.get(id);
        const timezone = currentMetadata?.timezone ?? Defaults.Timezone;
        setTimezone(timezone);
        const adjustedOffset = Number(currentMetadata?.adjusted_offset ?? Defaults.AdjustedOffset);
        setTimeOfDay(getTimeOfDay(timezone, adjustedOffset));
        setCanModifyVariableId(currentMetadata?.modifiablevarid === 'true');
        setIdVariableName('');
        setRequireIdVariable(currentMetadata?.require_id_var_to_score ?? Defaults.RequireIdVariable);
        setTimestampVariableName('');
        setProductionDataPeriod(currentMetadata?.production_data_period ?? Defaults.ProductionDataPeriod);
        setModerateDriftThreshold({ value: currentMetadata?.moderate_drift_threshold ?? Defaults.ModerateDriftThreshold, type: InputTypes.Numeric });
        setSevereDriftThreshold({ value: currentMetadata?.severe_drift_threshold ?? Defaults.SevereDriftThreshold, type: InputTypes.Numeric });
        setShowModeratePredictionDrift(currentMetadata?.show_moderate_prediction_drift ?? Defaults.ShowModeratePredictionDrift);
        setShowSeverePredictionDrift(currentMetadata?.show_severe_prediction_drift ?? Defaults.ShowSeverePredictionDrift);
        setShowModerateDriftVariables(currentMetadata?.show_moderate_drift_variables ?? Defaults.ShowModerateDriftVariables);
        setShowSevereDriftVariables(currentMetadata?.show_severe_drift_variables ?? Defaults.ShowSevereDriftVariables);
        setNumModerateDriftVariables(currentMetadata?.num_moderate_drift_variables ?? Defaults.NumModerateDriftVariables);
        setNumSevereDriftVariables(currentMetadata?.num_severe_drift_variables ?? Defaults.NumSevereDriftVariables);
        setEmails(currentMetadata?.alert_emails ? JSON.parse(currentMetadata.alert_emails) : []);
        setMetadata(currentMetadata);
        setModifiedSettings([]);
        setInvalidFields({});
        setRefresh(false);
      })();
    }
    return () => current = false;
  }, [id, refresh, pendingSubmit, setMetadata]);

  useEffect(() => {
    if (confirm) {
      // Overwrite confirm function with custom validation logic
      confirm.current = (next) => {
        if (modifiedSettings.length > 0) {
          pendingSubmit ? setNavigateAfter(true) : setShowPendingChangesDialog(true);
          setContinueNav(() => () => {
            next();
          });
        } else {
          // Call next to switch tabs as normal if no pending changes
          next();
        }
      };
      // On component unmount, reset confirm to default func
      return () => {
        confirm.current = next => next();
      };
    }
  }, [modifiedSettings.length, pendingSubmit, confirm]);

  useEffect(() => {
    // On component mount, call history's block function to register
    // a callback function on page switch
    const unblock = history.block((tx) => {
      if (modifiedSettings.length > 0) {
        location.current = tx.pathname;
        setContinueNav(() => () => {
          // Call unblock to allow navigation to go through
          unblock();
          history.push(location.current);
        });
        pendingSubmit ? setNavigateAfter(true) : setShowPendingChangesDialog(true);
        // Return false to block navigation.
        return false;
      }
      // Allow navigation to continue
      return true;
    });

    // On component unmount, call unmount to stop blocking
    return () => unblock();
  }, [modifiedSettings.length, history, pendingSubmit]);

  useEffect(() => {
    if (!pendingSubmit && navigateAfter) {
      continueNav();
    }
  }, [pendingSubmit, navigateAfter, continueNav]);

  const applyFieldErrors = err => {
    const invalids = {};
    switch (err.errorCode) {
      case ErrorCodes.MetadataNotUnique:
        const duplicates = err.message;
        ['id_variable_name', 'timestamp_variable_name'].forEach(field => {
          const name = field === 'id_variable_name' ? idVariableName : timestampVariableName;
          if (duplicates.includes(name.toLowerCase())) {
            invalids[field] = t('validateUniqueVariableNames');
          }
        });
        break;
      case ErrorCodes.NumDriftPredVar:
        invalids['num_moderate_drift_variables'] = t('validateNumDataDriftVar');
        break;
      case ErrorCodes.InvalidThreshold:
        const key = modifiedSettings.includes('moderateDriftThreshold') ? 'moderate_drift_threshold' :
          'severe_drift_threshold';
        invalids[key] = err.message.includes('0') ? t('validateZeroDriftThreshold') : t('validateModerateDriftThreshold');
        break;
      default:
        invalids['general'] = t('invalidSettings');
    }
    setInvalidFields(invalids);
  };

  const submitSettings = async () => {
    if (pendingSubmit) {
      return;
    }
    const settings = {
      timezone,
      adjusted_offset        : getOffset(timezone, timeOfDay).toString(),
      require_id_var_to_score: requireIdVariable.toString(),
      ...(canModifyVariableId && idVariableName !== '' && {
        id_variable_name: idVariableName
      }),
      ...(timestampVariableName !== '' && {
        timestamp_variable_name: timestampVariableName
      }),
      production_data_period        : productionDataPeriod,
      moderate_drift_threshold      : moderateDriftThreshold.value.toString(),
      severe_drift_threshold        : severeDriftThreshold.value.toString(),
      show_moderate_prediction_drift: showModeratePredictionDrift.toString(),
      show_severe_prediction_drift  : showSeverePredictionDrift.toString(),
      show_moderate_drift_variables : showModerateDriftVariables.toString(),
      show_severe_drift_variables   : showSevereDriftVariables.toString(),
      num_moderate_drift_variables  : numModerateDriftVariables.toString(),
      num_severe_drift_variables    : numSevereDriftVariables.toString(),
      alert_emails                  : JSON.stringify(emails)
    };
    try {
      if (moderateDriftThreshold.type === InputTypes.Text || severeDriftThreshold.type === InputTypes.Text) {
        const err = { body: { errorCode: 'InvalidMetadata' } };
        throw err;
      }
      setPendingSubmit(true);
      await Deployments.patchDeployment(id, settings);
      setModifiedSettings([]);
      setRefresh(true);
    } catch (err) {
      if (err?.body?.errorCode) {
        applyFieldErrors(err.body);
      } else {
        console.error(err);
        setModifiedSettings([]);
        setRefresh(true);
      }
    } finally {
      setPendingSubmit(false);
    }
  };

  const updateModifiedSettings = (newValue, settingName) => {
    setModifiedSettings(modifiedSettings => {
      if (isDefaultSetting(newValue, settingName)) {
        return modifiedSettings.filter(setting => settingName !== setting);
      }
      return !modifiedSettings.includes(settingName) ? [...modifiedSettings, settingName] : modifiedSettings;
    });
  };

  const changeValue = (value, setValue, settingName, isInteger = false, isFloat = false) => {
    if ((isInteger && (!_isInteger(Number(value)) || value < 0)) ||
        (isFloat && (!_isNumber(Number(value)) || value < 0))) {
      return;
    }
    setValue(value);
    setInvalidFields({});
    updateModifiedSettings(value, settingName);
  };

  const startOfDayInfo = useMemo(() => {
    const timezoneOffset = moment.tz(timezone ?? Defaults.Timezone).utcOffset() / 60;
    const offset = timezoneOffset + getOffset(timezone, timeOfDay);
    const time = `${timeOfDay}:00`;
    return t(offset < 0 ? 'startOfDayInfoNegative' : 'startOfDayInfoPositive', { time });
  }, [t, timezone, timeOfDay]);

  return <>
    {timezone === undefined ?
      <LoadingWrapper caption={t('loading')} />
      : <div className="settings">
        <div className='button-group'>
          <Button
            className='cancel'
            disabled={pendingSubmit || modifiedSettings.length === 0}
            id='cancel-settings'
            variant='contained'
            onClick={() => setRefresh(true)}>
            {t('cancel')}
          </Button>
          <Button
            disabled={pendingSubmit || modifiedSettings.length === 0}
            id='save-settings'
            variant='contained'
            onClick={() => submitSettings()}>
            {t('saveChanges')}
          </Button>
          {!!invalidFields.general && <div className='error-info'>{invalidFields.general}</div>}
        </div>
        { /* Timezone, Time of Day */ }
        <div className='section'>
          <h4>{t('startOfDay')}</h4>
          <div className='section-instructions'>
            {t('startOfDayDescription')}
          </div>
          <TimezoneSelector
            setTimeOfDay={value => changeValue(value, setTimeOfDay, 'timeOfDay')}
            setTimezone={value => changeValue(value, setTimezone, 'timezone')}
            timeOfDay={timeOfDay}
            timezone={timezone} />
          {timeOfDay !== undefined && (
            <span className='section-instructions emphasize'>
              {startOfDayInfo}
            </span>
          )}
        </div>
        { /* Observation ID */ }
        <div className='section'>
          <h4>{t('observationId')}</h4>
          <div className='section-instructions'>
            {t('observationIdInstruction')}
            <span className='emphasize'>{` ${t('observationIdInstructionWarning')}`}</span>
          </div>
          <div>
            <LabelInputError
              changeValue={value => changeValue(value, setIdVariableName, 'idVarName')}
              disabled={!canModifyVariableId}
              errorText={invalidFields['id_variable_name']}
              label={t('idName')}
              placeholder={metadata?.id_variable_name ?? Defaults.IdVariableName}
              value={idVariableName} />
          </div>
          <div className='inline-checkbox require-id-var'>
            <input
              checked={requireIdVariable === 'true'}
              className="checkbox"
              id="require-id-var"
              name="require-id-var-checkbox"
              type="checkbox"
              onChange={e => changeValue(e.target.checked.toString(), setRequireIdVariable, 'requireIdVariable')} />
            <label htmlFor="require-id-var">{t('requireObservationId')}</label>
          </div>
          <div className='section-instructions'>{t('requireObservationIdDescription')}</div>
        </div>
        { /* Prediction Timestamp */ }
        <div className='section'>
          <h4>{t('predictionTimestamp')}</h4>
          <div className="section-instructions">{t('predictionTimestampInstructions')}</div>
          <LabelInputError
            changeValue={value => changeValue(value, setTimestampVariableName, 'timestampVarName')}
            errorText={invalidFields['timestamp_variable_name']}
            label={t('timestampVariableName')}
            placeholder={metadata?.timestamp_variable_name ?? Defaults.TimestampVariableName}
            value={timestampVariableName} />
        </div>
        { /* Production Data Period */}
        <div className='section'>
          <h4>{t('productionDataPeriod')}</h4>
          <div className="section-instructions">
            {t('specifyPeriodThresholdForDeployment')}
          </div>
          <DataPeriod
            dataPeriod={productionDataPeriod}
            excludedOptions={[PeriodSettings.Quarterly]}
            t={t}
            onChange={e => changeValue(e, setProductionDataPeriod, 'productionDataPeriod')} />
        </div>
        { /* Variable Drift Thresholds */}
        <div
          className='section'
          id='variable-thresholds'>
          <h4>{t('variableDriftThresholds')}</h4>
          <div className="section-instructions">{t('specifyDriftThreshold')}</div>
          <div
            className="drift-inline"
            id="moderate-drift-psi">
            <label>{t('moderateDriftLabel')}</label>
            {t('psiGreaterThanOrEqualTo')}
            <LocalizedInput
              error={!!invalidFields['moderate_drift_threshold']}
              forceReset={refresh}
              initialValue={moderateDriftThreshold.value}
              onChange={value => changeValue(value, setModerateDriftThreshold, 'moderateDriftThreshold', false, true)} />
          </div>
          <br />
          <div
            className="drift-inline"
            id="severe-drift-psi">
            <label>{t('severeDriftLabel')}</label>
            {t('psiGreaterThanOrEqualTo')}
            <LocalizedInput
              error={!!invalidFields['severe_drift_threshold']}
              forceReset={refresh}
              initialValue={severeDriftThreshold.value}
              onChange={value => changeValue(value, setSevereDriftThreshold, 'severeDriftThreshold', false, true)} />
          </div>
          {Object.keys(invalidFields).length > 0 &&
           Object.keys(invalidFields).every(invalid => ['moderate_drift_threshold', 'severe_drift_threshold'].includes(invalid)) && (
            <div className='error-info'>
              {invalidFields['moderate_drift_threshold'] || invalidFields['severe_drift_threshold']}
            </div>
          )}
        </div>
        { /* Overall Model Drift */}
        <div className='section wide'>
          <h4>{t('overallModelDrift')}</h4>
          <div className="section-instructions">
            {t('specificyOverallDriftCriteriaForDeployment')}
          </div>
          <div className="overall-drift-criteria">
            <div className='criteria-inline'>
              <input
                checked={showModeratePredictionDrift === 'true' || showSeverePredictionDrift === 'true'}
                className='checkbox'
                id='show-drift'
                name='show-drift'
                type='checkbox'
                onChange={() => {
                  const newValue = !(showModeratePredictionDrift === 'true' || showSeverePredictionDrift === 'true');
                  changeValue(newValue.toString(), setShowModeratePredictionDrift, 'showModeratePredictionDrift');
                  changeValue(newValue.toString(), setShowSeverePredictionDrift, 'showSeverePredictionDrift');
                }} />
              <label htmlFor='show-drift'>{t('predictionDrift')}</label>
            </div>
            <div className="criteria-inline">
              <input
                checked={showModerateDriftVariables === 'true' || showSevereDriftVariables === 'true'}
                className="checkbox"
                id="num-vars-drift"
                name="num-vars-drift"
                type='checkbox'
                onChange={() => {
                  const newValue = !(showModerateDriftVariables === 'true' || showSevereDriftVariables === 'true');
                  changeValue(newValue.toString(), setShowModerateDriftVariables, 'showModerateDriftVariables');
                  changeValue(newValue.toString(), setShowSevereDriftVariables, 'showSevereDriftVariables');
                }} />
              <input
                className={classNames({ 'error': !!invalidFields['num_moderate_drift_variables'] })}
                type="number"
                value={numModerateDriftVariables ?? ''}
                onChange={e => {
                  changeValue(e.target.value, setNumModerateDriftVariables, 'numModerateDriftVariables', true);
                  changeValue(e.target.value, setNumSevereDriftVariables, 'numSevereDriftVariables', true);
                }} />
              <label htmlFor="num-vars-drift">{t('variableDataDrift')}</label>
            </div>
            {!!invalidFields['num_moderate_drift_variables'] &&
              <div className='error-info'>{invalidFields['num_moderate_drift_variables']}</div>}
          </div>
        </div>
        <div className="section">
          <h4 className="email-alerts-header">{t('emailAlerts')}</h4>
          <div className="section-instructions">{t('emailInstructions')}</div>
          <EmailSelector
            emails={emails ?? []}
            setEmails={values => changeValue(values, setEmails, 'emails')} />
          <div className='email-info'>
            <Trans
              components={{ bold: <span /> }}
              i18nKey="modelSettingsEmailInfo"
              values={{ domain: 'noreply@minitab.com' }} />
          </div>
        </div>
        {showPendingChangesDialog &&
          <ValidationDialog
            description={t('unsavedChanges')}
            header={t('savePendingChanges')}
            submitButtonText={t('saveAndContinue')}
            onCancel={() => {
              setShowPendingChangesDialog(false);
            }}
            onContinueWithoutSave={() => {
              continueNav();
              setModifiedSettings([]);
              setShowPendingChangesDialog(false);
            }}
            onSubmit={() => {
              submitSettings();
              setNavigateAfter(true);
              setShowPendingChangesDialog(false);
            }} />}
      </div>}
  </>;
};

export default Settings;