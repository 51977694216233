import { apiFetch } from './api-utils';

/*

Defines API method calls for settings.

*/

const USER_DEFAULTS = {
  application: {
    deploymentsSortProperty : 'createdon',
    deploymentsSortAscending: false,
    modelsSortProperty      : 'createdon',
    modelsSortAscending     : false,
    apiKeysFilter           : 'active',
    apiKeysSortProperty     : 'expireson',
    apiKeysSortAscending    : true,
    auditLogFilter          : 'all',
    auditLogPage            : 1
  },
  deployment: {
    activeTab                  : 'models',
    performanceReportTab       : 'drift',
    modelVariablesSortProperty : 'name',
    modelVariablesSortAscending: true,
    modelVariablesPage         : 1,
    auditLogPage               : 1,
    auditLogFilter             : 'all',
    integrationApiKeyFilter    : 'active',
    integrationRequestType     : 'prediction',
    integrationRequestData     : 'singleRow',
    integrationRequestMethod   : 'curlPost',
    showPromotions             : true
  },
  drift: {
    sortProperty     : 'variableImportance',
    sortAscending    : false,
    binIndex         : 0,
    predictorPage    : 1,
    expandedVariables: []
  },
  model: {
    schemaSortProperty : 'name',
    schemaSortAscending: true,
    schemaPage         : 1
  },
  stability: {
    xMax: 0,
    xMin: 0
  }
};

export const getDefaultUserAppSettings = type => USER_DEFAULTS.application[type];
export const getDefaultUserDeploymentSettings = () => USER_DEFAULTS.deployment;
export const getDefaultUserReportSettings = type => USER_DEFAULTS[type];

const Settings = {
  // Returns the global settings for this subscription
  get: async () => await apiFetch('/api/settings'),

  patch: async (settings) => await apiFetch('/api/settings', {
    method : 'PATCH',
    body   : JSON.stringify(settings),
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  getUserApplicationSettings: async () => {
    const settings = await apiFetch('/api/settings/application');
    return { ...USER_DEFAULTS.application, ...settings };
  },

  patchUserApplicationSettings: async (settings) => await apiFetch('/api/settings/application', {
    method : 'PATCH',
    body   : JSON.stringify(settings),
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  getUserReportSettings: async (deploymentId, type, { start, end, period }, flags) => {
    const settings = await apiFetch(`/api/settings/${deploymentId}/${type}?${new URLSearchParams({ start, end, period })}`);
    const defaults = { ...USER_DEFAULTS[type] };
    if (flags.drift_navigation && type === 'drift') {
      delete defaults.binIndex;
    }
    return { ...defaults, ...settings };
  },

  patchUserReportSettings: async (deploymentId, type, { start, end, period }, settings) =>
    await apiFetch(`/api/settings/${deploymentId}/${type}?${new URLSearchParams({ start, end, period })}`, {
      method : 'PATCH',
      body   : JSON.stringify(settings),
      headers: {
        'Content-Type': 'application/json'
      }
    }),

  getUserDeploymentSettings: async deploymentId => {
    const settings = await apiFetch(`/api/settings/${deploymentId}`);
    return { ...USER_DEFAULTS.deployment, ...settings };
  },

  patchUserDeploymentSettings: async (deploymentId, settings) => await apiFetch(`/api/settings/${deploymentId}`, {
    method : 'PATCH',
    body   : JSON.stringify(settings),
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  getUserModelSettings: async modelId => {
    const settings = await apiFetch(`/api/settings/models/${modelId}`);
    return { ...USER_DEFAULTS.model, ...settings };
  },

  patchUserModelSettings: async (modelId, settings) => await apiFetch(`/api/settings/models/${modelId}`, {
    method : 'PATCH',
    body   : JSON.stringify(settings),
    headers: {
      'Content-Type': 'application/json'
    }
  }),
};

export default Settings;
