import _isEqual from 'lodash/isEqual';
import React, { useEffect, useState, useCallback, useRef, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@mtb/ui';
import { serializeError } from 'serialize-error';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DriftStatusSevereIcon } from '../images';
import Deployments from '../api/deployments';
import DeploymentSettings from './Settings';
import DeploymentModels from './DeploymentModels';
import ServiceMetrics from './ServiceMetrics';
import Performance from './Performance';
import AuditLog from './AuditLog';
import Integration from './Integration';
import LoadingWrapper from './LoadingWrapper';
import ValidationDialog from './ValidationDialog';
import { Scopes, requireScope } from '../utils/scopes';
import { Statuses } from './ModelRepository';
import { formatLocalDateTime, toLocalizedString } from '../utils/locales';
import { ErrorsContext, FlagsContext, ScopeContext, SettingsContext } from '../utils/context';
import { SessionContext, SessionStatus } from './SessionWrapper';
import { DefaultSettings, getDriftStatusIcon, getOverallDriftTooltip } from '../utils/model-utils';
import Settings, { getDefaultUserDeploymentSettings } from '../api/settings';

import './Dashboard.scss';
import { METRICS } from './StabilityReport';
import { debounce } from '../api/api-utils';

const Tabs = {
  None          : 'none',
  Models        : 'models',
  Performance   : 'performance',
  Settings      : 'settings',
  AuditLog      : 'auditlog',
  ServiceMetrics: 'serviceMetrics',
  Integration   : 'integration'
};

const DEFAULT_METRIC_PERIOD = 'sevenDays';
const DEFAULT_METRIC_AGGREGATE = 'average';
const NUM_MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

const BUSY_STATUSES = [
  Statuses.DeployQueued,
  Statuses.DestroyQueued,
  Statuses.Pending,
  Statuses.Creating,
  Statuses.Destroying
];

const getHeaderBusyText = (t, deployment, pendingDeploy, pendingPause) => {
  // UI "pending" state takes precedence over deployment.active in case we're also in the middle of a replay.
  if (pendingPause) {
    return t('pausingDeploymentAction');
  } else if (pendingDeploy) {
    return t('activatingDeploymentAction');
  }
  return t(deployment?.active === 'true' ? 'pausingDeploymentAction' : 'activatingDeploymentAction');
};

const DeploymentHeaderButton = ({ deployment, showButton, onClick }) => {
  const [t] = useTranslation();
  const [statusMessage, setStatusMessage] = useState({ text: '' });
  const [buttonText, setButtonText] = useState('');
  const settings = useContext(SettingsContext);

  useEffect(() => {
    switch (deployment?.status) {
      case Statuses.Published:
        setStatusMessage({ text: 'deploymentOffline' });
        setButtonText('activateDeployment');
        break;
      case Statuses.Failed:
        setStatusMessage({ text: 'anErrorHasOccurred' });
        setButtonText('reactivateDeployment');
        break;
      default: // Statuses.Deployed
        const days = (new Date().getTime() - new Date(deployment?.lastdeployed)) / NUM_MILLISECONDS_IN_DAY;
        setStatusMessage({ text  : 'activeDays', params: {
          days: toLocalizedString(days.toFixed(2), settings.locale?.regionCode) } });
        setButtonText('pause');
    }
  }, [deployment, settings]);

  return (
    <>
      {deployment.status === Statuses.Failed && <DriftStatusSevereIcon className='warning-icon' />}
      <div className='deployment-status-message'>
        {t(statusMessage.text, statusMessage.params ?? {})}
      </div>
      {showButton &&
        <button
          className={classNames('deployment-control', 'clickable', {
            'pause'     : deployment.status === Statuses.Deployed,
            'activate'  : deployment.status === Statuses.Published,
            'reactivate': deployment.status === Statuses.Failed
          })}
          onClick={onClick}>
          {t(buttonText)}
        </button>
      }
    </>
  );
};

const DeploymentDashboard = ({ busy, setBusy, deploymentId }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { onError } = useContext(ErrorsContext);
  const scope = useContext(ScopeContext);
  const flags = useContext(FlagsContext);
  const session = useContext(SessionContext);

  const deploymentTitleRef = useRef(null);
  const [deployment, setDeployment] = useState();
  const [deploymentName, setDeploymentName] = useState('');
  const [editDeploymentName, setEditDeploymentName] = useState(false);
  const [champion, setChampion] = useState();
  const [challengers, setChallengers] = useState([]);
  const [metricPeriod, setMetricPeriod] = useState(DEFAULT_METRIC_PERIOD);
  const [metricAggregate, setMetricAggregate] = useState(DEFAULT_METRIC_AGGREGATE);
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [validationMessage, setValidationMessage] = useState({ header: '', description: '' });
  const [pendingDeploy, setPendingDeploy] = useState(false);
  const [pendingPause, setPendingPause] = useState(false);
  const [userDeploymentSettings, setUserDeploymentSettings] = useState({});
  const [inputWidth, setInputWidth] = useState();
  const inputSpan = useRef();

  // To insert custom code before tab switch, pass confirm ref to child Tab.
  // Overwrite confirm.current on component render to be a function that takes next as a parameter.
  // Implement custom code in function and then call next to navigate to next tab if desired
  // On component unmount reset confirm.current to default function.
  // If confirm is not set by the tab, it will default to just calling next.
  const confirm = useRef((next) => next());

  const { refetch: updateDeployment } = useQuery(deploymentId, async () => await Deployments.get(deploymentId), {
    onSuccess: async currentDeployment => {
      const deploymentModels = await Deployments.getDeploymentModels(deploymentId);
      const currentChampion = deploymentModels.find(model => model.deploymentrole === 'champion');
      if (!_isEqual(currentChampion, champion)) {
        setChampion(currentChampion);
      }
      const currentChallengers = deploymentModels.filter(model => model.deploymentrole !== 'champion');
      if (!_isEqual(currentChallengers, challengers)) {
        setChallengers(currentChallengers);
      }
      if (!deployment) {
        // Initial fetch. Set pendingDeploy if necessary so the "Deployment is active" dialog will appear.
        if (currentDeployment.active !== 'true' && BUSY_STATUSES.includes(currentDeployment.status)) {
          setPendingDeploy(true);
        }
      }
      if (!_isEqual(currentDeployment, deployment)) {
        setDeployment(currentDeployment);
        setDeploymentName(currentDeployment.name);
        setMetricPeriod(currentDeployment.service_metrics_period || DEFAULT_METRIC_PERIOD);
        setMetricAggregate(currentDeployment.service_metrics_aggregate || DEFAULT_METRIC_AGGREGATE);
      }
    },
    onError: error => {
      console.error(error);
      history.push('/deployments');
    },
    refetchInterval            : 5000, // 5 seconds
    refetchIntervalInBackground: true,
    retry                      : (failureCount, error) => error.status !== 404 && failureCount <= 3,
    enabled                    : session?.sessionStatus !== SessionStatus.Expired
  });

  useEffect(() => {
    if (inputSpan.current) {
      setInputWidth(inputSpan.current.offsetWidth);
    }
  }, [deploymentName]);

  useEffect(() => {
    const newStatus = deployment?.status;
    if (pendingDeploy && newStatus === Statuses.Deployed) {
      setPendingDeploy(false);
      setValidationMessage({ header: t('deploymentActive'), description: t('theDeploymentIsActive') });
      setShowValidationDialog(true);
    } else if (pendingDeploy && newStatus === Statuses.Failed) {
      setPendingDeploy(false);
    } else if (pendingPause && newStatus === Statuses.Published) {
      setPendingPause(false);
    }
  }, [t, pendingDeploy, pendingPause, deployment]);

  const handleResumeDeployment = useCallback(async () => {
    try {
      setBusy(true);
      setPendingDeploy(true);
      setDeployment(deployment => {
        return { ...deployment, status: Statuses.DeployQueued };
      });
      await Deployments.resume(deploymentId);
      await updateDeployment();
    } catch (e) {
      const error = serializeError(e);
      if (error.status === 400 && error.body.errorCode === 'MaxDeploymentExceeded') {
        setValidationMessage({ header: t('maximumNumberOfDeployments'), description: t('toActivateMoreDeployments') });
        setShowValidationDialog(true);
      } else {
        onError(error);
      }
      setPendingDeploy(false);
    } finally {
      setBusy(false);
    }
  }, [t, setBusy, deploymentId, updateDeployment, onError]);

  const handlePauseDeployment = useCallback(async () => {
    try {
      setBusy(true);
      setPendingPause(true);
      setDeployment(deployment => {
        return { ...deployment, status: Statuses.DestroyQueued };
      });
      await Deployments.pause(deploymentId);
      await updateDeployment();
    } catch (e) {
      onError(serializeError(e));
      setPendingPause(false);
    } finally {
      setBusy(false);
    }
  }, [setBusy, deploymentId, updateDeployment, onError]);

  const handleRenameDeployment = useCallback(async () => {
    if (deploymentName === deployment.name) {
      setEditDeploymentName(false);
      return;
    }
    try {
      setBusy(true);
      await Deployments.patchDeployment(deploymentId, { name: deploymentName });
      await updateDeployment();
    } catch (e) {
      const error = serializeError(e);
      if (error.status === 400 && error.body.errorCode === 'InvalidMetadata') {
        setValidationMessage(!deploymentName.trim().length ?
          { header: t('renameDeployment'), description: t('deploymentNamesMustBeEntered') } :
          { header: t('duplicateDeploymentName'), description: t('deploymentNamesMustBeUniqueEnterNewName') });
        setShowValidationDialog(true);
        setDeploymentName(deployment.name);
      } else {
        onError(error);
      }
    } finally {
      setEditDeploymentName(false);
      setBusy(false);
    }
  }, [deploymentId, deploymentName, deployment?.name, setBusy, onError, updateDeployment, t]);

  const handleEditDeploymentName = () => {
    setEditDeploymentName(true);
    setTimeout(() => {
      deploymentTitleRef.current.select();
    }, 0);
  };

  const getDateLabels = (date) => {
    if (!date) {
      return {};
    }

    return {
      datetime: formatLocalDateTime(date, deployment.timezone ?? DefaultSettings.Timezone)
    };
  };

  const fetchUserDeploymentSettings = useCallback(async () => {
    const settings = flags.isr ?
      await Settings.getUserDeploymentSettings(deployment.id) :
      getDefaultUserDeploymentSettings();
    const { schema: { response: { type, classLabels } } } = await Deployments.get(deployment.id, 'schema');
    const stabilityMetric = type === 'categorical' ? METRICS.AvgPredictedProbabilityEvent : METRICS.AvgPredictedResponse;
    setUserDeploymentSettings({
      stabilityMetric,
      ...(type === 'categorical' && { stabilityResponseLevel: classLabels[0] }),
      ...settings
    });
  }, [flags.isr, deployment?.id]);

  useEffect(() => {
    if (deployment?.id) {
      fetchUserDeploymentSettings();
    }
  }, [deployment?.id, fetchUserDeploymentSettings]);

  const debouncePatch = useMemo(() => debounce(async (id, settings) => {
    await Settings.patchUserDeploymentSettings(id, settings);
  }, 400), []);

  const patchUserDeploymentSettings = settings => {
    setUserDeploymentSettings(prev => ({ ...prev, ...settings }));
    flags.isr && debouncePatch(deployment.id, settings);
  };

  return (
    !deployment || !champion || Object.keys(userDeploymentSettings).length === 0 ?
      <LoadingWrapper caption={t('loading')} /> :
      <>
        <div className='dashboard-header'>
          {deployment &&
          <>
            {flags.modelenhancements && <span className='dashboard-label'>{t('deployment')}</span>}
            <div className='overview'>
              <div className='title-container'>
                {editDeploymentName
                  ? <div style={{ position: 'relative' }}>
                    <input
                      ref={deploymentTitleRef}
                      className='title editing'
                      maxLength={50}
                      style={{ width: inputWidth }}
                      value={deploymentName}
                      onBlur={handleRenameDeployment}
                      onChange={(e) => setDeploymentName(e.target.value)} />
                    <div className='title hidden'>
                      <span
                        ref={inputSpan}
                        style={{ whiteSpace: 'pre' }}>{deploymentName}</span>
                    </div>
                  </div>
                  : <>
                    <div
                      className='title'
                      onDoubleClick={requireScope(scope, Scopes.Deployments)
                        ? () => handleEditDeploymentName()
                        : undefined
                      }>
                      <span ref={inputSpan}>{deploymentName}</span>
                    </div>
                    {requireScope(scope, Scopes.Deployments) &&
                      <div
                        className='edit-button clickable'
                        onClick={() => handleEditDeploymentName()}>
                        {t('edit')}
                      </div>
                    }
                  </>
                }
              </div>
              <div className='deployment-status'>
                {pendingDeploy || pendingPause || BUSY_STATUSES.includes(deployment.status) ?
                  <>
                    <LoadingWrapper
                      className='deployment-loading-icon'
                      inline />
                    <i className='deployment-loading-message'>
                      {getHeaderBusyText(t, deployment, pendingDeploy, pendingPause)}
                    </i>
                  </> :
                  <DeploymentHeaderButton
                    deployment={deployment}
                    showButton={requireScope(scope, Scopes.Deploy)}
                    onClick={deployment.status === Statuses.Deployed ?
                      handlePauseDeployment : handleResumeDeployment} />
                }
              </div>
            </div>
            <div className='deployment-information'>
              <div className='info'>
                {t('createdByFormattedLabel', { name: deployment?.createdbyname })}
              </div>
              <div
                className='info'
                data-testid='createdon'>
                {t('createdFormattedLabel', getDateLabels(deployment?.createdon))}
              </div>
              <div
                className='info'
                data-testid='lastmodified'>
                {t('deploymentOverviewLastUpdated', getDateLabels(deployment?.lastmodified))}
              </div>
              <div className='info'>
                {t('championModelLabel')}
                <b className='model-name'>{champion?.name}</b>
              </div>
              <div className='info'>
                <Tooltip
                  title={getOverallDriftTooltip(t, deployment, false)}>
                  <div className='overall-drift'>
                    {getDriftStatusIcon(deployment.severity)}
                    {deployment.severity ?
                      t(`${deployment.severity}DriftStatusOverall`) :
                      t('noDriftStatusOverall')}
                  </div>
                </Tooltip>
              </div>
            </div>
          </>}
        </div>
        <div className='tab-navigation'>
          {deployment && (
            <ul className='nav'>
              {Object.values(Tabs).map(tab => {
                return tab !== Tabs.None &&
              (tab !== Tabs.Settings || requireScope(scope, Scopes.Deployments)) &&
              (tab !== Tabs.AuditLog || requireScope(scope, Scopes.LogsRead)) &&
              tab !== Tabs.ServiceMetrics &&
            <li
              key={tab}
              className={classNames({ 'active': userDeploymentSettings.activeTab === tab })}
              id={`${tab}-tab`}
              onClick={() => {
                confirm.current(() => {
                  patchUserDeploymentSettings({ activeTab: tab });
                });
              }}>
              {t(tab)}
            </li>;
              })}
            </ul>
          )}
          <div className='tab-contents'>
            {userDeploymentSettings.activeTab === Tabs.Models && (
              <DeploymentModels
                challengers={challengers}
                champion={champion}
                deployment={deployment}
                pendingDeploy={pendingDeploy}
                pendingPause={pendingPause}
                setBusy={setBusy}
                updateDeployment={updateDeployment}
                updateUserDeploymentSettings={patchUserDeploymentSettings}
                userDeploymentSettings={userDeploymentSettings} />
            )}
            {userDeploymentSettings.activeTab === Tabs.Performance && (
              <Performance
                deployment={deployment}
                id={deploymentId}
                t={t}
                updateUserDeploymentSettings={patchUserDeploymentSettings}
                userDeploymentSettings={userDeploymentSettings} />
            )}
            {userDeploymentSettings.activeTab === Tabs.Settings && (
              <DeploymentSettings
                confirm={confirm}
                id={deploymentId}
                metadata={deployment}
                setMetadata={setDeployment} />
            )}
            {userDeploymentSettings.activeTab === Tabs.AuditLog && (
              <AuditLog
                allowRefresh={true}
                busy={busy}
                getAuditLog={Deployments.getAuditLog}
                id={deploymentId}
                setBusy={setBusy}
                updateSettings={patchUserDeploymentSettings}
                userSettings={userDeploymentSettings} />
            )}
            {userDeploymentSettings.activeTab === Tabs.ServiceMetrics && (
              <ServiceMetrics
                deployment={deployment}
                metricAggregate={metricAggregate}
                metricPeriod={metricPeriod}
                model={champion}
                setBusy={setBusy}
                setMetricAggregate={setMetricAggregate}
                setMetricPeriod={setMetricPeriod}
                t={t} />
            )}
            {userDeploymentSettings.activeTab === Tabs.Integration && (
              <Integration
                deploymentId={deploymentId}
                patchUserDeploymentSettings={patchUserDeploymentSettings}
                score={async data => await Deployments.score(deploymentId, data)}
                stability={async data => await Deployments.stability(deploymentId, data)}
                t={t}
                uploadScoreData={async data => await Deployments.uploadScoreData(deploymentId, data)}
                uploadStabilityData={async data => await Deployments.uploadStabilityData(deploymentId, data)}
                userDeploymentSettings={userDeploymentSettings} />
            )}
          </div>
        </div>
        {showValidationDialog &&
        <ValidationDialog
          description={validationMessage.description}
          header={validationMessage.header}
          onSubmit={() => setShowValidationDialog(false)} />
        }
      </>
  );
};

export default DeploymentDashboard;