import { apiFetch, apiDownload } from './api-utils';

/*

Defines API method calls for Models.

*/
const Models = {
  // Publishes a model using a FormsData payload containing the model file.
  publish: async (data, includeTrainingData) => {
    const params = includeTrainingData ? `?${new URLSearchParams({ includeTrainingData })}` : '';
    return await apiFetch(`/api/models${params}`, { method: 'POST', body: data });
  },

  // Removes a published model.
  delete: async id => await apiFetch(`/api/models/${id}`, { method: 'DELETE' }),

  // Upload baseline data for a model.
  // Browsers autofill Accept-Language, blank it out to allow API to decide.
  uploadBaselineData: async (id, data) => {
    return await apiFetch(`/api/models/${id}/baseline`, {
      method : 'POST',
      body   : data,
      headers: {
        'Accept-Language': ''
      }
    });
  },

  // Sends a message to the download worker to prepare baseline data for downloading.
  // Browsers autofill Accept-Language, blank it out to allow API to decide.
  prepareBaselineDataDownload: async (id, downloadExtension) => {
    return await apiFetch(`/api/models/${id}/baseline/download`, {
      method : 'POST',
      headers: {
        'Accept': {
          'csv': 'text/csv',
          'mwx': 'application/mwx'
        }[downloadExtension],
        'Accept-Language': ''
      }
    });
  },

  // Sends a message to the download worker to prepare baseline data for downloading.
  // Browsers autofill Accept-Language, blank it out to allow API to decide.
  downloadBaselineData: async (id, downloadExtension) => {
    return await apiDownload(`/api/models/${id}/baseline/download`, {
      method : 'GET',
      headers: {
        'Accept': {
          'csv': 'text/csv',
          'mwx': 'application/mwx'
        }[downloadExtension],
        'Accept-Language': ''
      }
    });
  },

  // Returns all known models
  getAll: async () => await apiFetch('/api/models'),

  // Returns a single model
  get: async (id, filter) => await apiFetch(`/api/models/${id}${filter ? `?${new URLSearchParams({ filter })}` : ''}`),

  // Updates model metadata
  patchModel: async (id, model) => await apiFetch(`/api/models/${id}`, {
    method : 'PATCH',
    body   : JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  // Gets the audit log entries for a given model
  getAuditLog: async (id, page, type) => {
    return await apiFetch(`/api/models/${id}/auditLog?page=${page}&type=${type}`);
  },

  // Gets schema for model
  schema: async (id) => {
    return await apiFetch(`/api/models/${id}/schema`);
  }
};

export default Models;
