import { useTranslation } from 'react-i18next';
import ValidationDialog from './ValidationDialog';
import { releasedFeaturesMap, REL_KEY } from '../utils/software-update-utils';

const SoftwareUpdateDialog = ({ setShowDialog, releaseDate }) => {
  const [t] = useTranslation(['softwareUpdates']);
  const features = releasedFeaturesMap[releaseDate] ?? [];

  const handleClose = () => {
    // Set local storage value to 'YYYY-MM-DD' date format of last released feature
    localStorage.setItem(REL_KEY, releaseDate);
    setShowDialog(false);
  };

  return (
    <ValidationDialog
      description={features.length > 1 ? t('softwareUpdateMultiple') : t('softwareUpdateSingle', { feature: t(features[0]) })}
      header={t('softwareUpdate')}
      onSubmit={handleClose}>
      {features.length > 1 &&
        <ul>
          {features.map(feature => (
            <li key={feature}>
              <span>{t(feature)}</span>
            </li>
          ))}
        </ul>}
    </ValidationDialog>
  );
};

export default SoftwareUpdateDialog;