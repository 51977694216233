import React from 'react';
import moment from 'moment';
import { MenuItem, Select } from '@mtb/ui';
import { PeriodSettings } from '../utils/model-utils';

const DATE_FORMAT = 'MMMM D, YYYY';

export const createDateRange = (dateMoment, period) => {
  const dateRange = { start: dateMoment.startOf(period).format(DATE_FORMAT) };
  if (period !== PeriodSettings.Daily) {
    dateRange.end = dateMoment.endOf(period).format(DATE_FORMAT);
  }
  return dateRange;
};

export const getShiftedDateRange = (dateRange, period, direction) => {
  const startMoment = moment.utc(dateRange.start, DATE_FORMAT);
  const newStartMoment = direction === 'back' ?
    startMoment.subtract(1, `${period}s`) :
    startMoment.add(1, `${period}s`);
  return createDateRange(newStartMoment, period);
};

export const isDateRangeCurrent = dateRange => {
  const currentDay = moment.utc().format(DATE_FORMAT);
  if (dateRange.end) {
    return moment(currentDay).isBetween(dateRange.start, dateRange.end, undefined, '[]');
  }
  return moment(currentDay).isSame(dateRange.start);
};

const DataPeriod = ({ t, dataPeriod, excludedOptions, onChange, labelKey = 'productionDataPeriod' }) => {
  return (
    <div className="period">
      <label className="period-label">{t(labelKey)}</label>
      <Select
        className="modeler-select-field period-select"
        name="period-select"
        value={dataPeriod ?? ''}
        onChange={e => onChange?.(e.target.value)}>
        {Object.values(PeriodSettings).filter(val => !excludedOptions?.includes(val)).map(option => (
          <MenuItem
            key={option}
            data-testId={`period-${option}`}
            value={option}>
            {t(option)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DataPeriod;