export const Scopes = {
  Deploy         : 'deploy',
  Deployments    : 'deployments',
  DeploymentsRead: 'deployments.read',
  Endpoints      : 'endpoints',
  EndpointsRead  : 'endpoints.read',
  Keys           : 'keys',
  KeysRead       : 'keys.read',
  Logs           : 'logs',
  LogsRead       : 'logs.read',
  Metrics        : 'metrics',
  MetricsRead    : 'metrics.read',
  Models         : 'models',
  ModelsRead     : 'models.read',
  Reports        : 'reports',
  ReportsRead    : 'reports.read',
  Score          : 'score',
  ScoreRead      : 'score.read',
  ScoreWrite     : 'score.write',
  Stability      : 'stability',
  StabilityRead  : 'stability.read',
  StabilityWrite : 'stability.write',
};

const authenticateScope = (userScopes, allowedScope) => {
  // If part of the scope is in the allowed scope, it is valid.
  // Example:
  // userScopes = [models]
  // allowed = [models.read]
  // returns 'true' because 'models' is part of 'models.read'
  const sections = [];
  for (const section of allowedScope.split('.')) {
    sections.push(section);
    if (userScopes?.includes(sections.join('.'))) {
      return true;
    }
  }
  return false;
};

export const requireScope = (userScopes, allowedScope) => {
  if (Array.isArray(allowedScope)) {
    return allowedScope.reduce((previous, allowed) => previous || authenticateScope(userScopes, allowed), false);
  } 

  return authenticateScope(userScopes, allowedScope);
};