import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingWrapper from './LoadingWrapper';
import './dialogs.scss';

const ModelBusyDialog = ({ title, onCancel }) => {
  const [t] = useTranslation();

  return (
    <div className="dialogContainer">
      <div className="modalDialog modelBusy">
        <LoadingWrapper inline />
        <h3>{title}</h3>
        <p>{t('thisMayTakeAFewMinutes')}</p>
        {onCancel &&
          <div className='buttons'>
            <button
              className='cancelButton'
              onClick={onCancel}>
              {t('cancel')}
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default ModelBusyDialog;