import React from 'react';
import classNames from 'classnames';
import './dialogs.scss';

const Confirm = ({ t, title, confirmationText, confirm, cancel, primaryButtonText, wide }) => {
  const primaryButton = primaryButtonText ? t(primaryButtonText) : t(cancel ? 'proceed' : 'ok');
  return (
    <div className="dialogContainer">
      <div className={classNames('modalDialog', { 'confirmation': !wide })}>
        <h3>{t(title)}</h3>
        <p>{t(confirmationText)}</p>
        <div className="buttons">
          {cancel && (
            <button
              className="cancelButton"
              onClick={() => cancel()}>
              {t('cancel')}
            </button>
          )}
          <button
            className="confirmButton"
            onClick={() => confirm()}>
            {primaryButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
