import classNames from 'classnames';
import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { debounce } from '../api/api-utils';
import ReportHeader, { ReportPeriodSetting, getDefaultReportDates, getLastReportSetting } from './ReportHeader';
import DriftReport from './DriftReport';
import StabilityReport from './StabilityReport';
import './Performance.scss';
import { FlagsContext } from '../utils/context';
import Deployments from '../api/deployments';
import Settings, { getDefaultUserReportSettings } from '../api/settings';

const REPORT_TABS = {
  Drift    : 'drift',
  Stability: 'stability'
};

const Performance = ({ t, id, deployment, userDeploymentSettings, updateUserDeploymentSettings }) => {
  const flags = useContext(FlagsContext);
  const [refreshDrift, setRefreshDrift] = useState(false);
  const [refreshStability, setRefreshStability] = useState(false);
  const [showRefresh, setShowRefresh] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const [hasReport, setHasReport] = useState(false);
  const [userReportSettings, setUserReportSettings] = useState({});

  const {
    performanceReportTab: activeReportTab,
    stabilityMetric,
    stabilityResponseLevel,
    showPromotions } = userDeploymentSettings;
  const { start, end, period } = getDefaultReportDates(flags, deployment, userDeploymentSettings);
  const [reportSettings, setReportSettings] = useState({ start, end, period });

  const refreshReport = async () => {
    // Get most current reportsettings from deployment before refresh
    if (flags.isr) {
      const { reportStart, reportEnd } = await Settings.getUserDeploymentSettings(deployment.id);
      updateUserDeploymentSettings({ reportStart, reportEnd });
      setReportSettings(({ start, end, period }) => ({ start: reportStart || start, end: reportEnd || end, period }));
    } else {
      const { reportsettings } = await Deployments.get(deployment.id, 'reportsettings');
      setReportSettings(getLastReportSetting(reportsettings));
    }
    if (activeReportTab === REPORT_TABS.Drift) {
      setRefreshDrift(true);
    } else {
      setRefreshStability(true);
    }
    setHasReport(false);
  };

  const setTab = tab => {
    if (activeReportTab !== tab) {
      updateUserDeploymentSettings({ performanceReportTab: tab });
      setHasReport(false);
    }
  };

  const fetchUserReportSettings = useCallback(async type => {
    let settings;
    if (flags.isr) {
      const query = { ...reportSettings, period: ReportPeriodSetting[reportSettings.period] };
      settings = await Settings.getUserReportSettings(deployment.id, type, query, flags);
    } else {
      settings = getDefaultUserReportSettings(type);
    }
    setUserReportSettings(settings);
  }, [flags, deployment.id, reportSettings]);

  useEffect(() => {
    // Update user settings upon fetching a different report
    fetchUserReportSettings(activeReportTab);
  }, [activeReportTab, reportSettings, fetchUserReportSettings]);

  const debouncePatch = useMemo(() =>
    debounce(async (id, type, query, settings) =>
      await Settings.patchUserReportSettings(id, type, query, settings), 400), []);

  const patchUserReportSettings = settings => {
    setUserReportSettings(prev => ({ ...prev, ...settings }));
    if (flags.isr) {
      const allowed = Object.keys(getDefaultUserReportSettings(activeReportTab));
      const mergedSettings = { ...userReportSettings, ...settings };
      const newSettings = allowed.reduce((acc, key) =>
        (mergedSettings[key] !== undefined ? { ...acc, [key]: mergedSettings[key] } : acc), {});
      const query = { ...reportSettings, period: ReportPeriodSetting[reportSettings.period] };
      debouncePatch(deployment.id, activeReportTab, query, newSettings);
    }
  };

  const changeReportSettings = (start, end, period) => {
    activeReportTab === REPORT_TABS.Drift ? setRefreshDrift(true) : setRefreshStability(true);
    if (flags.isr) {
      updateUserDeploymentSettings({ reportStart: start, reportEnd: end, reportPeriod: ReportPeriodSetting[period] });
    }
    setReportSettings({ start, end, period });
  };

  return (
    <div className='performance-container'>
      <ReportHeader
        deployment={deployment}
        hasReport={hasReport}
        lastUpdated={lastUpdated}
        refreshReport={refreshReport}
        reportSettings={reportSettings}
        setData={changeReportSettings}
        showRefresh={showRefresh} />
      <div className="tab-navigation">
        <ul className="nav">
          {deployment && Object.values(REPORT_TABS).map(tab =>
            <li
              key={tab}
              className={classNames({ 'active': activeReportTab === tab })}
              data-testid={`tab-${tab}`}
              onClick={() => setTab(tab)}>
              {t(tab)}
            </li>
          )}
        </ul>
        <div className="tab-contents">
          {activeReportTab === REPORT_TABS.Drift && (
            <DriftReport
              deployment={deployment}
              id={id}
              refresh={refreshDrift}
              reportSettings={reportSettings}
              setHasReport={setHasReport}
              setLastUpdated={setLastUpdated}
              setRefresh={setRefreshDrift}
              setShowRefresh={setShowRefresh}
              updateUserSettings={patchUserReportSettings}
              userSettings={userReportSettings} />
          )}
          {activeReportTab === REPORT_TABS.Stability && (
            <StabilityReport
              deployment={deployment}
              id={id}
              refresh={refreshStability}
              reportSettings={reportSettings}
              setHasReport={setHasReport}
              setLastUpdated={setLastUpdated}
              setRefresh={setRefreshStability}
              setShowRefresh={setShowRefresh}
              updateUserDeploymentSettings={settings => {
                updateUserDeploymentSettings(settings);
                patchUserReportSettings({ xMin: 0, xMax: 0 });
              }}
              updateUserReportSettings={patchUserReportSettings}
              userSettings={{ ...userReportSettings, stabilityMetric, stabilityResponseLevel, showPromotions }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Performance;
