import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { DefaultSettings } from './model-utils';
import { InputTypes } from '../components/Inputs';

const locales = {
  'en-US': {
    code            : 'en',
    region          : 'United States',
    value           : 'en-US',
    currency        : 'USD',
    language        : 'English',
    decimalSeparator: '.',
    listSeparator   : ',',
    groupSeparator  : ',',
    timezone        : 'America/New_York'
  },
  'en-GB': {
    code            : 'en-GB',
    region          : 'United Kingdom',
    value           : 'en-GB',
    currency        : 'GBP',
    decimalSeparator: '.',
    listSeparator   : ',',
    groupSeparator  : ',',
    timezone        : 'Europe/London'
  },
  'fr-FR': {
    code            : 'fr',
    region          : 'France',
    value           : 'fr-FR',
    currency        : 'EUR',
    language        : 'Français',
    decimalSeparator: ',',
    listSeparator   : ';',
    groupSeparator  : ' ',
    timezone        : 'Europe/Paris'
  },
  'de-DE': {
    code            : 'de',
    region          : 'Germany',
    value           : 'de-DE',
    currency        : 'EUR',
    language        : 'Deutsch',
    decimalSeparator: ',',
    listSeparator   : ';',
    groupSeparator  : '.',
    timezone        : 'Europe/Berlin'
  },
  'pt-BR': {
    code            : 'pt-BR',
    region          : 'Brazil',
    value           : 'pt-BR',
    currency        : 'BRL',
    language        : 'Português',
    decimalSeparator: ',',
    listSeparator   : ';',
    groupSeparator  : '.',
    timezone        : 'America/Sao_Paulo'
  },
  'es-MX': {
    code            : 'es',
    region          : 'Mexico',
    value           : 'es-MX',
    currency        : 'MXN',
    language        : 'Español',
    decimalSeparator: '.',
    listSeparator   : ',',
    groupSeparator  : ',',
    timezone        : 'America/Mexico_City'
  },
  'ja-JP': {
    code            : 'ja',
    region          : 'Japan',
    value           : 'ja-JP',
    currency        : 'JPY',
    language        : '日本語',
    decimalSeparator: '.',
    listSeparator   : ',',
    groupSeparator  : ',',
    timezone        : 'Asia/Tokyo'
  },
  'ko-KR': {
    code            : 'ko',
    region          : 'Korea',
    value           : 'ko-KR',
    currency        : 'KRW',
    language        : '한국어',
    decimalSeparator: '.',
    listSeparator   : ',',
    groupSeparator  : ',',
    timezone        : 'Asia/Seoul'
  },
  'zh-CN': {
    code            : 'zh-CN',
    region          : 'China',
    value           : 'zh-CN',
    currency        : 'CNY',
    language        : '中文(简体)',
    decimalSeparator: '.',
    listSeparator   : ',',
    groupSeparator  : ',',
    timezone        : 'Asia/Shanghai'
  },
};

export const availableLocales = Object.values(locales);
export const supportedLanguages = process.env.REACT_APP_AVAILABLE_LANGUAGES?.split(',') ?? [];

export const defaultLocale = locales['en-US'];
export const invariantDecimalSeparator = defaultLocale.decimalSeparator;
export const invariantListSeparator = defaultLocale.listSeparator;

const findAllInvariantDecimalSeparators = new RegExp(`[${invariantDecimalSeparator}]`, 'g');
const findAllInvariantListSeparators = new RegExp(`[${invariantListSeparator}]`, 'g');

export const toLocalizedString = (number, regionCode) => {
  const decimalSeparator = locales[regionCode]?.decimalSeparator || defaultLocale.decimalSeparator;
  if (decimalSeparator === invariantDecimalSeparator) {
    return number;
  }
  return number?.toString().replace(findAllInvariantDecimalSeparators, decimalSeparator);
};

export const toLocalizedArray = (values, regionCode) => {
  const listSeparator = locales[regionCode]?.listSeparator || defaultLocale.listSeparator;
  if (Array.isArray(values)) {
    return values.join(`${listSeparator} `);
  }
  if (listSeparator === invariantListSeparator) {
    return values;
  }
  return values?.replace(findAllInvariantListSeparators, listSeparator);
};

export const toInvariantString = (stringOrNumber, regionCode) => {
  const decimalSeparator = locales[regionCode]?.decimalSeparator || defaultLocale.decimalSeparator;
  if (decimalSeparator === invariantDecimalSeparator) {
    return { value: stringOrNumber, type: isNaN(stringOrNumber) ? InputTypes.Text : InputTypes.Numeric };
  }

  // First replace to removes all non-numeric, decimal separator, and letter e values
  // We allow the letter e for scientific notion
  // Replace the regional decimal separator with invariant form
  const invariantValue = stringOrNumber
    ?.toString()
    .replace(new RegExp(`[^-${decimalSeparator}\\de]`, 'g'), '')
    .replace(decimalSeparator, invariantDecimalSeparator);

  // Check if invariantValue is the same length as original is is a number
  // e.g. stringOrNumber = '123a' iinvariantValue = '123', check fails on length
  // stringOrNumber = '12,2' invariantValue = '12.2', both checks passes
  if (invariantValue.length === stringOrNumber.length && !isNaN(invariantValue)) {
    return { value: invariantValue, type: InputTypes.Numeric };
  }
  return { value: stringOrNumber, type: InputTypes.Text };
};

// TODO: use timezone to adjust the UTC date and remove 'Z' from the format.
export const formatTime = (date, _timezone) => `${moment(date).format('YYYY-MM-DD HH:mm:ss')}Z`;
export const formatDate = (date) => {
  try {
    // Using new Date().toISOString() here to create a YYYY-MM-DD date object rather than moment.format() ensures
    // the date is always passed in UTC format and isn't shifted to the user's local timezone. (See TT bug 186646)
    return new Date(date).toISOString().split('T')[0];
  } catch {
    return date;
  }
};

export const formatLocalDateTime = (date, timezone) => moment.tz(date, timezone).format('YYYY-MM-DD HH:mm:ss');
export const formatShortLocalDateTime = (date, timezone) => moment.tz(date, timezone).format('YYYY-MM-DD HH:mm');
export const formatDayLocalDateTime = (date, timezone) => moment.tz(date, timezone).format('YYYY-MM-DD');

export const getUtcOffset = timezone => momentTimezone.tz(momentTimezone.utc(), timezone).utcOffset();

export const getTimeOfDay = (timezone, offset) => {
  let newTime = offset + (getUtcOffset(timezone) / 60);
  if (newTime < 0) {
    newTime += 24;
  } else if (newTime >= 24) {
    newTime -= 24;
  }
  return newTime;
};

export const getSelectedOffset = timeOfDay => timeOfDay >= 12 ? timeOfDay - 24 : timeOfDay;

export const getOffset = (timezone, timeOfDay) => {
  const timezoneOffset = moment.tz(timezone ?? DefaultSettings.Timezone).utcOffset() / 60;
  return getSelectedOffset(timeOfDay) - timezoneOffset;
};

export const isDateRangeCurrent = (start, end) => {
  const currentMoment = moment.utc();
  return moment(currentMoment).isBetween(start, end, undefined, '[]');
};

const filterTimezones = timezones => {
  return timezones.reduce((timezones, current) => {
    // Timezones with an irregular offset are unsupported
    const offsets = [
      moment.tz({ month: 0, day: 1 }, current.name).utcOffset(),
      moment.tz({ month: 5, day: 1 }, current.name).utcOffset()];
    if (offsets.every(offset => offset % 60 === 0)) {
      timezones.push(current);
    }
    return timezones;
  }, []);
};

export const TimezoneOptions = filterTimezones(
  momentTimezone.tz.names().map(name => {
    const offset = getUtcOffset(name);
    return {
      name,
      offset,
      label: name.replace(/_/g, ' '),
      sign : offset < 0 ? '-' : '+'
    };
  }));