import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  Select,
  MenuItem,
} from '@mtb/ui';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { FlagsContext, ScopeContext } from '../utils/context';
import { DefaultSettings, getPromotionsForModel } from '../utils/model-utils';
import { requireScope, Scopes } from '../utils/scopes';
import { OvalIcon, ContextMenuHorizontalIcon } from '../images';
import Deployments from '../api/deployments';
import Keys from '../api/keys';
import Confirm from './confirm';
import CodePanel, { MethodTypes } from './IntegrationCodePanel';
import ContextMenu from './ContextMenu';
import PredictionForm from './IntegrationPredictionForm';
import StabilityForm from './IntegrationStabilityForm';
import { KeyStatuses, StatusLabels, applyKeyProperties } from './apikeys';
import ApiKeyCreation from './apikeycreate';
import LoadingWrapper from './LoadingWrapper';
import Filter, { applyFilter } from './Filter';
import { InputTypes } from './Inputs';

import './Integration.scss';
import './context-menu.scss';

export const RequestTypes = {
  Prediction: 'prediction',
  Stability : 'stability'
};

export const DataTypes = {
  SingleRow   : 'singleRow',
  MultipleRows: 'multipleRows',
  DataFile    : 'file'
};

const STATUS = {
  'ErrorDataType'            : 'ERROR_DATA_TYPE',
  'ErrorLimitReached'        : 'ERROR_LIMIT_REACHED',
  'ErrorMissingObservationId': 'ERROR_MISSING_OBS_ID',
  'ErrorInvalidObservationId': 'ERROR_INVALID_OBS_ID',
  'Success'                  : 'SUCCESS',
  'WarningChampionMismatch'  : 'WARNING_CHAMPION_MISMATCH',
  'WarningStabilityLimit'    : 'WARNING_STABILITY_LIMIT',
  'WarningObservationId'     : 'WARNING_OBS_ID',
  'WarningScoreLimit'        : 'WARNING_SCORE_LIMIT',
  'WarningTimeRange'         : 'WARNING_TIME_RANGE',
  'WarningTimeValue'         : 'WARNING_TIME_VALUE',
  'WarningUnknownResponse'   : 'WARNING_UNKNOWN_RESPONSE'
};

export const SCORING_BACKDATE_LIMIT = 3;

const arrayToObject = data => {
  const payload = {};
  data.forEach(row => {
    Object.entries(row).forEach(([key, value]) => {
      if (payload[key]) {
        payload[key].push(value);
      } else {
        payload[key] = [value];
      }
    });
  });
  return payload;
};

const isMissing = value => value === '*' || !value.toString().trim().length;
const getNumericValue = value => isMissing(value) ? value : Number(value);
const getDate = timestamp => timestamp.split('T')[0];

const getFormattedTimestamp = date => {
  const month = (date.getUTCMonth() + 1);
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const mins = date.getUTCMinutes();
  const secs = date.getUTCSeconds();
  const addZero = value => value < 10 ? `0${value}` : value;
  return `${date.getUTCFullYear()}-${addZero(month)}-${addZero(day)}T${addZero(hours)}:${addZero(mins)}:${addZero(secs)}Z`;
};

// Rows with invalid timestamps will be logged as errors and ids won't be returned
const getMockPredictionResponse = async (ids, timestamps, requireId, deploymentId, flags) => {
  // If all of ids values blank, UI removes id from request automatically
  const varIdInRequest = ids.some(id => id !== '');
  const responseCodes = [];
  const backdateLimit = moment().utc().startOf('day').subtract(SCORING_BACKDATE_LIMIT, 'months');
  const earliest = backdateLimit;

  const promotions = await Deployments.getPromotions(deploymentId);
  const championPromotions = promotions.length > 0 ?
    getPromotionsForModel(promotions.at(-1)[0], promotions) : [{ start: earliest, end: new Date() }];
  timestamps.forEach((timestamp, index) => {
    const errors = [];
    let date = new Date(`${timestamp}Z`);
    date = isNaN(date) ? new Date(timestamp) : date;
    if (isNaN(date)) {
      errors.push(STATUS.WarningTimeValue);
    } else {
      if (date > moment().utc() || date < earliest) {
        errors.push(STATUS.WarningTimeRange);
      } else if (!championPromotions.some(({ start, end }) => (date >= start && date < end))) {
        errors.push(flags.isr ? STATUS.WarningChampionMismatch : STATUS.WarningTimeRange);
      } else {
        // Coerce to a full date/time value, e.g. if only '2023-03-01' was entered
        timestamps[index] = getFormattedTimestamp(date);
      }
    }

    if (!ids[index].trim()) {
      if (requireId) {
        const error = { status: 400, message: STATUS.ErrorMissingObservationId };
        throw error;
      }
      (varIdInRequest && errors.push(STATUS.WarningObservationId));
    }

    if (['\r', '\n', '\\n', '\\r'].some(char => ids[index]?.toString().includes(char))) {
      const error = { status: 400, message: STATUS.ErrorInvalidObservationId };
      throw error;
    }

    responseCodes.push(errors.length ? errors.join(' ') : STATUS.Success);
  });

  return { ids: varIdInRequest ? ids : undefined, timestamps, responseCodes };
};

const getMockStabilityResponse = (ids, actuals, response) => {
  const today = getDate(new Date().toISOString());
  const numericResponse = response.type === 'continuous';
  const rowResults = { ids, actuals, dates: [], responseCodes: [] };
  ids.forEach((id, index) => {
    const errors = [];
    if (!id.value.trim().length) {
      const error = { status: 400, message: STATUS.ErrorMissingObservationId };
      throw error;
    }

    if (['\r', '\n', '\\n', '\\r'].some(char => id.value.toString().includes(char))) {
      const error = { status: 400, message: STATUS.ErrorInvalidObservationId };
      throw error;
    }

    const actual = actuals[index];
    if (numericResponse && actual.type !== InputTypes.Numeric && !isMissing(actual.value)) {
      const error = { status: 400, message: STATUS.ErrorDataType };
      throw error;
    }
    if (numericResponse && isMissing(actual.value)) {
      errors.push(STATUS.WarningUnknownResponse);
    }
    rowResults.actuals[index] = actual.value;
    rowResults.dates.push(errors.length ? '' : today);
    rowResults.responseCodes.push(errors.length ? errors.join(' ') : STATUS.Success);
  });
  if (numericResponse) {
    rowResults.actuals = rowResults.actuals.map(value => getNumericValue(value));
  }
  return rowResults;
};

const revokeKey = async (t, tokenId, setDialog, reloadKeys) => {
  setDialog(<Confirm
    cancel={() => setDialog()}
    confirm={async () => {
      setDialog();
      await Keys.patch(tokenId, { status: KeyStatuses.Revoked });
      await reloadKeys();
    }}
    confirmationText={'confirmRevokeKey'}
    primaryButtonText={'revokeKey'}
    t={t}
    title={'revokeKey'} />);
};

const Integration = props => {
  const {
    t,
    deploymentId,
    score,
    stability,
    uploadScoreData,
    uploadStabilityData,
    userDeploymentSettings,
    patchUserDeploymentSettings
  } = props;
  const scope = useContext(ScopeContext);
  const flags = useContext(FlagsContext);

  const [predictionValues, setPredictionValues] = useState([]);
  const [stabilityValues, setStabilityValues] = useState([]);
  const [validationMessage, setValidationMessage] = useState([]);
  const [contextMenuId, setContextMenuId] = useState();
  const [dataFile, setDataFile] = useState();
  const [predictors, setPredictors] = useState({});
  const [response, setResponse] = useState({});
  const [statusCode, setStatusCode] = useState();
  const [results, setResults] = useState();
  const [dialog, setDialog] = useState();
  const [allKeys, setAllKeys] = useState();
  const [keys, setKeys] = useState();
  const [timestampId, setTimestampId] = useState();
  const [variableId, setVariableId] = useState();
  const [requireVarId, setRequireVarId] = useState();
  const [timezone, setTimezone] = useState(DefaultSettings.Timezone);

  const uploadRef = useRef();

  const {
    integrationApiKeyFilter: apiKeyFilter,
    integrationRequestType: requestType,
    integrationRequestData: requestDataType,
    integrationRequestMethod: requestMethod } = userDeploymentSettings;

  useEffect(() => {
    setStatusCode();
    setResults();
  }, [requestDataType, requestType]);

  const validateNumericPredictors = (values) => {
    Object.entries(predictors).forEach(([predictor, info]) => {
      if (info.type === 'continuous') {
        values[predictor].forEach(({ value, type }) => {
          if (type !== InputTypes.Numeric && !isMissing(value)) {
            const error = { status: 400, message: STATUS.ErrorDataType };
            throw error;
          }
        });
      }
    });
  };

  const postScore = async () => {
    const values = arrayToObject(requestDataType === DataTypes.SingleRow ? [predictionValues[0]] : predictionValues);
    validateNumericPredictors(values);
    const mappedValues = Object.fromEntries(Object.entries(values)
      .map(([key, objs]) => [key, objs.map(({ value }) => value)]));
    const inputTimestamps = values[timestampId].some(({ value: timestamp }) => timestamp !== '') ?
      mappedValues[timestampId] : Array(mappedValues[timestampId].length).fill(new Date().toISOString());
    const { ids, responseCodes, timestamps } =
      await getMockPredictionResponse(mappedValues[variableId], inputTimestamps, requireVarId, deploymentId, flags);
    const payload = {
      responseCode : responseCodes,
      [variableId] : ids,
      [timestampId]: timestamps,
      ...await score(mappedValues) };
    if (Object.keys(payload.probabilities ?? {}).length === 0) {
      delete payload.probabilities;
    }
    setResults(JSON.stringify(payload));
  };

  const postStability = async () => {
    const values = arrayToObject(requestDataType === DataTypes.SingleRow ? [stabilityValues[0]] : stabilityValues);
    const mappedValues = Object.fromEntries(
      Object.entries(values).map(([ key, objs ]) => [key, objs.map(({ value }) => value)]));
    const { ids, actuals, dates, responseCodes } =
      getMockStabilityResponse(values[variableId], values[response.name], response);
    const payload = {
      responseCode   : responseCodes,
      [variableId]   : ids.map(({ value }) => value),
      date           : dates,
      [response.name]: actuals,
      ...await stability(mappedValues) };
    setResults(JSON.stringify(payload));
  };

  const batchFile = async () => {
    setResults(t('processing'));
    const { payload, successful } = await uploadRef.current.upload();
    setResults(JSON.stringify(payload));
    if (successful) {
      setStatusCode(t('statusCode', { statusCode: 200 }));
    }
  };

  const submitForm = async () => {
    setStatusCode();
    setResults();
    try {
      if (requestDataType === DataTypes.DataFile) {
        await batchFile();
      } else {
        await (requestType === RequestTypes.Prediction ? postScore() : postStability());
        setStatusCode(t('statusCode', { statusCode: 200 }));
      }
    } catch (err) {
      const options = { statusCode: err.status };
      if (err.status === 404) {
        options.message = t('deploymentNotActive');
        setStatusCode(t('statusCodeWithMessage', options));
        setResults();
      } else {
        options.message = err.message;
        setStatusCode(t('statusCodeWithMessage', options));
      }
    }
  };

  const showContextMenu = (e, id) => {
    e?.stopPropagation();
    setContextMenuId(id);
  };

  const reloadKeys = useCallback(async () => {
    let { apiKeys } = await Keys.getAll(deploymentId);
    apiKeys = applyKeyProperties(t, apiKeys);
    setAllKeys(apiKeys);
    apiKeys = applyFilter(apiKeys, apiKeyFilter);
    apiKeys.sort((a, b) => {
      const date_sort = new Date(a.expireson) - new Date(b.expireson);
      if (date_sort !== 0) {
        return date_sort;
      }
      const name_compare = a.keyname.localeCompare(b.keyname);
      if (name_compare !== 0) {
        return name_compare;
      }
      return new Date(a.createdon) - new Date(b.createdon);
    });
    setKeys(apiKeys);
  }, [deploymentId, t, apiKeyFilter]);

  // Set Schema and Endpoint info
  useEffect(() => {
    let current = true;
    (async () => {
      const metadata = await Deployments.get(deploymentId);
      const { id_variable_name, timestamp_variable_name, require_id_var_to_score, timezone } = metadata;
      const predictors = metadata.schema.predictors;
      const response = metadata.schema.response;
      if (!current) {
        return;
      }
      setPredictors(predictors);
      setResponse(response);
      setTimestampId(timestamp_variable_name ?? DefaultSettings.TimestampVariableName);
      setVariableId(id_variable_name ?? DefaultSettings.IdVariableName);
      setRequireVarId((require_id_var_to_score ?? DefaultSettings.RequireIdVariable) === 'true');
      setTimezone(timezone ?? DefaultSettings.Timezone);
      await reloadKeys();
    })();
    return () => current = false;
  }, [t, reloadKeys, deploymentId]);

  return (
    <div className='integration-content'>
      <div className='access-control'>
        <h4>{t('apiKeys')}</h4>
        <div className='description'>
          {t('apiKeysDescription', { integrationType: t('modelsInDeployment') })}
        </div>
        <Button
          className='colored'
          id='createApiKey'
          {...(!requireScope(scope, Scopes.Keys) && {
            disabled: true,
            title   : t('createApiKeyDisabled')
          })}
          variant='contained'
          onClick={() => {
            setDialog(<ApiKeyCreation
              defaultKeyName={`Key_${(allKeys?.length || 0) + 1}`}
              reloadKeys={reloadKeys}
              scopes={[Scopes.ModelsRead, Scopes.DeploymentsRead, Scopes.ScoreWrite, Scopes.StabilityWrite]}
              setDialog={setDialog}
              showWarning={true}
              t={t}
              target={deploymentId}
              timezone={timezone}
              onCancel={() => setDialog()} />);
          }}>
          {t('createApiKey')}
        </Button>
        {keys && (
          <Filter
            filterStatus={apiKeyFilter}
            t={t}
            onFilterStatusChanged={status => patchUserDeploymentSettings({ integrationApiKeyFilter: status })} />
        )}
        <div className="table-container">
          <LoadingWrapper
            caption={t('loadingCurrentKeys')}
            className='centered'
            isLoading={!keys} />
          {(keys && keys.length === 0) && <p>{t('noKeysExist')}</p>}
          {keys?.map((key, id) => {
            const expiration = `${t('expiration')} ${moment.tz(key.expireson, timezone)}`;
            return (
              <div key={id}>
                <div className={classNames('flex-table', 'row', { 'top-border': id === 0 })}>
                  <div className="flex-cell name">
                    <div className='relative'>
                      <div
                        className='key-name'
                        title={key.keyname}>{key.keyname}</div>
                      <div
                        className='sub-text'
                        title={key.createdbyemail}>{key.createdbyemail}</div>
                    </div>
                  </div>
                  <div className="flex-cell">
                    <div className='relative'>
                      <div
                        className="sub-text"
                        title={expiration}>{expiration}</div>
                    </div>
                  </div>
                  <div className="flex-cell">
                    <div
                      className='status'
                      title={t(StatusLabels[key.status])}>
                      <OvalIcon className={classNames(key.status)} />
                      <div>{t(StatusLabels[key.status])}</div>
                    </div>
                    {requireScope(scope, Scopes.Keys) && key.status === KeyStatuses.Active &&
                      <div
                        className='context-menu-icon'
                        title={t('revokeKey')}
                        onClick={(e) => showContextMenu(e, key.tokenid)}>
                        <ContextMenuHorizontalIcon className="icon" />
                      </div>
                    }
                  </div>
                  {requireScope(scope, Scopes.Keys) && contextMenuId === key.tokenid && (
                    <ContextMenu onClose={showContextMenu}>
                      <div onClick={() => revokeKey(t, key.tokenid, setDialog, reloadKeys)}>
                        {t('revokeKey')}
                      </div>
                    </ContextMenu>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='sample-code'>
        <h4>{t('sampleCode')}</h4>
        <div className='description'>{t('sampleCodeDescription')}</div>
        <div className='request'>
          <div className='request-content'>
            <div className='form'>
              <FormControl className='options'>
                <RadioGroup row>
                  {Object.values(RequestTypes).map(type =>
                    <FormControlLabel
                      key={type}
                      control={(
                        <Radio
                          checked={requestType === type}
                          id ={`codepanel-${type}`}
                          size='small'
                          value={type}
                          onChange={({ target: { value } }) => {
                            const payload = { integrationRequestType: value };
                            if (requestMethod === MethodTypes.CURLGet && value !== RequestTypes.Prediction) {
                              payload.integrationRequestMethod = MethodTypes.CURLPost;
                            }
                            patchUserDeploymentSettings(payload);
                          } } />
                      )}
                      label={t(type)} />)}
                </RadioGroup>
                <FormControl className='select'>
                  <FormLabel>{t('data')}</FormLabel>
                  <Select
                    className='modeler-select-field'
                    value={requestDataType}
                    onChange={({ target: { value } }) => {
                      const payload = { integrationRequestData: value };
                      if (requestMethod === MethodTypes.CURLGet && value !== DataTypes.SingleRow) {
                        payload.integrationRequestMethod = MethodTypes.CURLPost;
                      }
                      patchUserDeploymentSettings(payload);
                    }}>
                    {Object.values(DataTypes).map(type =>
                      <MenuItem
                        key={type}
                        id={`codepanel-${type}`}
                        value={type}>
                        {t(type)}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </FormControl>
              <div className='values'>
                {requestType === RequestTypes.Prediction &&
                  <PredictionForm
                    dataFile={dataFile}
                    formType={requestDataType}
                    predictors={predictors}
                    setDataFile={setDataFile}
                    setResults={setResults}
                    setValidationMessage={setValidationMessage}
                    setValues={setPredictionValues}
                    t={t}
                    timestampId={timestampId}
                    uploadData={uploadScoreData}
                    uploadRef={uploadRef}
                    values={predictionValues}
                    variableId={variableId} />}
                {requestType === RequestTypes.Stability &&
                  <StabilityForm
                    dataFile={dataFile}
                    formType={requestDataType}
                    response={response}
                    setDataFile={setDataFile}
                    setResults={setResults}
                    setValidationMessage={setValidationMessage}
                    setValues={setStabilityValues}
                    t={t}
                    uploadData={uploadStabilityData}
                    uploadRef={uploadRef}
                    values={stabilityValues}
                    variableId={variableId} />}
              </div>
            </div>
            <CodePanel
              dataFile={dataFile}
              dataType={requestDataType}
              methodType={requestMethod}
              requestType={requestType}
              t={t}
              updateMethodType={(method) => patchUserDeploymentSettings({ integrationRequestMethod: method })}
              values={requestType === RequestTypes.Prediction ? predictionValues : stabilityValues} />
          </div>
          <div className='submit'>
            <Button
              className='colored'
              disabled={(requestDataType === DataTypes.DataFile && !dataFile) || !!validationMessage}
              variant='contained'
              onClick={submitForm}>
              {t('submitRequest')}
            </Button>
            {!!validationMessage && <div className="errorDetails">{validationMessage}</div>}
            {statusCode && <span>{statusCode}</span>}
            {results && <>
              <span>{t('responseJSON')}</span>
              <textarea
                className='results'
                readOnly
                value={results} />
            </>}
          </div>
        </div>
      </div>
      {dialog}
    </div>
  );
};

export default Integration;