const DISPLAY_UPDATE_THRESHOLD = 31;
export const REL_KEY = 'updateRead';
// Only used for testing purposes
export const CUSTOM_TODAY = 'testToday';
export const CUSTOM_REL_DATE = 'testReleaseDate';

// Map of release dates to translated feature descriptions
export const releasedFeaturesMap = {
  // Test only strings
  '2024-06-01': ['dummySingleFeature'],
  '2024-06-19': ['dummySingleFeature', 'dummyMultiFeature'],
  '2024-07-25': ['dailyRequestLimitUpdate', 'baselineDataDownloadFeature'],
  '2024-10-02': ['backdatingWarnings', 'userSessionSettings'],
  '2024-11-21': ['variableImportance', 'modelRepositoryEnhancements', 'driftReportNavigation']
};

export const displayNewFeature = (customToday, customLastRelDate) => {
  const lastReleaseDate = customLastRelDate ?? process.env.REACT_APP_FEATURE_LAST_RELEASE;
  const thresholdDate = new Date(lastReleaseDate);
  thresholdDate.setDate(thresholdDate.getDate() + DISPLAY_UPDATE_THRESHOLD);

  return (customToday ? Date.parse(customToday) : new Date()) < thresholdDate &&
    lastReleaseDate > (localStorage.getItem(REL_KEY) || '0') &&
    releasedFeaturesMap[lastReleaseDate];
};