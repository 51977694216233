import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Select, MenuItem } from '@mtb/ui';
import { TimezoneOptions, getSelectedOffset } from '../utils/locales';

const TimezoneSelector = props => {
  const {
    timezone,
    setTimezone,
    timeOfDay,
    setTimeOfDay
  } = props;

  const [t] = useTranslation();

  return (
    <div className='start-of-day'>
      <FormControl className='select'>
        <FormLabel>{t('timezone')}</FormLabel>
        <Select
          className='timezone'
          fullWidth
          id='timezone'
          value={timezone ?? ''}
          onChange={({ target: { value } }) => setTimezone(value)}>
          {TimezoneOptions.map(({ name, offset, sign, label }) =>
            <MenuItem
              key={name}
              value={name}>
              {label} {t('utcOffset', { sign, offset: Math.abs(offset / 60) })}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl className='select'>
        <FormLabel>{t('startOfDay')}</FormLabel>
        <Select
          id='time-of-day'
          value={isNaN(timeOfDay) ? '' : timeOfDay}
          onChange={({ target: { value } }) => setTimeOfDay(value)}>
          {[...Array(24).keys()].map(time =>
            <MenuItem
              key={time}
              value={time}>
              {`${time}:00`}
              {getSelectedOffset(time) < 0 && ` (${getSelectedOffset(time)}:00)`}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default TimezoneSelector;