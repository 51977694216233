import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mtb/ui';
import './dialogs.scss';
import { FlagsContext } from '../utils/context';

const BaselineDataNotCurrentDialog = ({ onSubmit }) => {
  const flags = useContext(FlagsContext);
  const [t] = useTranslation();
  const helpLink = `${flags.helpURL}/monitor-deployed-models/upload-baseline-and-production-data`;
  return (
    <div className="dialogContainer">
      <div className="modalDialog challengerInput">
        <h3>{t('baselineDataNotCurrent')}</h3>
        <div className="formControl challenger">
          <p>
            {t('baselineDataNotCurrentDescription')}
            <a
              href={helpLink}
              rel="noreferrer"
              target="_blank">
              {t('uploadDriftData')}
            </a>
          </p>
        </div>
        <div className="buttons">
          <Button
            className='confirmButton'
            variant='contained'
            onClick={onSubmit}>
            {t('ok')}
          </Button>
        </div>
      </div>
    </div>);
};

export default BaselineDataNotCurrentDialog;