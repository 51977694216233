import { apiFetch } from './api-utils';
/*

Defines API method calls for Sessions

*/
const Sessions = {
  get        : async () => await apiFetch('/account/user'),
  getScope   : async () => await apiFetch('/api/scope'),
  getFlags   : async () => await apiFetch('/api/flags'),
  signout    : async () => await fetch('/api/signout', { method: 'POST' }),
  updateFlags: async (flags) => await apiFetch('/api/flags', { 
    method : 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(flags) 
  }),
};
export default Sessions;
