export class ApiError extends Error {
  constructor(status, statusText, body) {
    super(`${status} ${statusText} ${body ? JSON.stringify(body) : ''}`);
    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }
}

export const apiFetch = async (resource, init) => {
  let response;
  try {
    response = await fetch(resource, init);
  } catch (e) {
    console.error(e);
  }
  if (!response) {
    if (window.location.pathname !== '/embed/upload') {
      window.location = '/';
    }
    return;
  }
  if (!response.ok && response.status === 401) {
    window.location = `/account/login?wctx=${encodeURIComponent(window.location.pathname)}`;
    return;
  }
  const contentType = response.headers.get('content-type');
  const json = contentType && contentType.indexOf('application/json') !== -1;
  if (!response.ok) {
    throw new ApiError(response.status, response.statusText, json ? await response.json() : await response.text());
  }
  return json ? await response.json() : await response.text();
};

export const apiDownload = async (resource, init) => {
  let response;
  try {
    response = await fetch(resource, init);
  } catch (e) {
    console.error(e);
  }
  if (!response) {
    window.location = '/';
    return;
  }
  if (!response.ok && response.status === 401) {
    window.location = `/account/login?wctx=${encodeURIComponent(window.location.pathname)}`;
    return;
  }
  const contentType = response.headers.get('content-type');
  const json = contentType && contentType.indexOf('application/json') !== -1;
  if (!response.ok) {
    throw new ApiError(response.status, response.statusText, json ? await response.json() : await response.text());
  }
  return response.blob();
};

/**
 * Debounce function that allows for a func arg to be aggregated
 * Used to batch data for a single call
 * @param {function} func - funtion called after wait period
 * @param {Number} wait - time to wait before calling func (ms)
 * @param {Boolean} aggLastArg - aggregates the last arg of the function
 *
 * @returns {function} - debounced function
 */
export const debounce = (func, wait = 400, aggLastArg = true) => {
  let timeout;
  let lastThis;
  let lastArgs;
  let result;
  let agg = {};

  const invokeFunc = () => {
    const args = lastArgs;
    const thisArg = lastThis;
    if (aggLastArg) {
      args[args.length - 1] = agg;
    }

    lastArgs = lastThis = undefined;
    agg = {};
    result = func.apply(thisArg, args);
    return result;
  };

  return (...args) => {
    lastArgs = args;
    if (aggLastArg) {
      agg = { ...agg, ...args[args.length - 1 ] };
    }
    lastThis = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = undefined;
      invokeFunc();
    }, wait);
    return result;
  };
};