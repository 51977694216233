import { Tooltip } from '@mtb/ui';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  ChallengerInvalid,
  ChallengerValid,
  ChampionInvalid,
  ChampionValid,
  ContextMenuHorizontalIcon
} from '../images';
import ContextMenu from './ContextMenu';
import { useTranslation } from 'react-i18next';
import { DeploymentRoles } from '../utils/model-file-utils';
import './ModelCard.scss';
import Deployments from '../api/deployments';
import { Statuses } from './Deployments';
import { FileStatuses, FileInfo } from './FileUpload';
import { serializeError } from 'serialize-error';
import LoadingWrapper from './LoadingWrapper';
import ModelBusy from './ModelBusy';
import ModelInformationDialog from './ModelInformationDialog';
import RemoveModelDialog from './RemoveModelDialog';
import RenameModelDialog from './RenameModelDialog';
import ImportBaselineDataDialog from './ImportBaselineDataDialog';
import DownloadDataDialog from './DownloadDataDialog';
import ValidationDialog from './ValidationDialog';
import { Scopes, requireScope } from '../utils/scopes';
import Models from '../api/models';
import { getErrorMessage } from '../utils/errors/download-errors';
import { ErrorsContext, ScopeContext } from '../utils/context';
import { DefaultSettings } from '../utils/model-utils';

const ModelCard = ({
  deployment,
  model,
  pendingDeploy,
  pendingPause,
  setBusy,
  updateDeployment
}) => {
  const [t] = useTranslation();
  const { onError } = useContext(ErrorsContext);
  const scope = useContext(ScopeContext);

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showImportBaselineDataDialog, setShowImportBaselineDataDialog] = useState(false);
  const [showDownloadBaselineDataDialog, setShowDownloadBaselineDataDialog] = useState(false);
  const [showModelInformationDialog, setShowModelInformationDialog] = useState(false);
  const [showRenameModelDialog, setShowRenameModelDialog] = useState(false);
  const [showRemoveModelDialog, setShowRemoveModelDialog] = useState(false);
  const [validationData, setValidationData] = useState({ header: '', description: '' });
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [isUploadingBaseline, setIsUploadingBaseline] = useState(false);
  const [isPromoting, setIsPromoting] = useState(model?.promoting === 'true');

  const isChampion = model.deploymentrole === DeploymentRoles.Champion;
  const baselineLoading = model.baselinestatus === FileStatuses.Pending;
  const baselineValid = model.baselinestatus === FileStatuses.Valid;
  const baselineInvalid = model.baselinestatus === FileStatuses.Invalid;
  const baselinePartial = model.baselinefileinfo === FileInfo.PartialPredictors;

  const toggleContextMenu = useCallback((e) => {
    e?.stopPropagation();
    setShowContextMenu(prev => !prev);
  }, []);

  const handlePromoteModel = useCallback(async (e) => {
    toggleContextMenu(e);
    if (deployment.status === Statuses.Failed) {
      setValidationData({ header: t('actionCantBeCompleted'), description: t('unknownDeploymentError') });
      setShowValidationDialog(true);
      return;
    } else if (deployment.replaystatus === 'active' || pendingDeploy || pendingPause || ![Statuses.Deployed, Statuses.Published].includes(deployment.status)) {
      setValidationData({ header: t('actionCantBeCompleted'), description: t('busyDeploymentError') });
      setShowValidationDialog(true);
      return;
    }
    try {
      setBusy(true);
      setIsPromoting(true);
      model.promoting = 'true';
      await Deployments.promoteChallenger(deployment.id, model.id);
      await updateDeployment();
    } catch (e) {
      setIsPromoting(false);
      onError(serializeError(e));
    } finally {
      setBusy(false);
    }
  }, [model, deployment, toggleContextMenu, onError, setBusy, updateDeployment, t, pendingDeploy, pendingPause]);

  useEffect(() => {
    setIsPromoting(model?.promoting === 'true');
  }, [model]);

  const handleRemoveModel = useCallback((e) => {
    toggleContextMenu(e);
    if (deployment.replaystatus === 'active') {
      setValidationData({ header: t('actionCantBeCompleted'), description: t('busyDeploymentError') });
      setShowValidationDialog(true);
      return;
    }
    setShowRemoveModelDialog(true);
  }, [deployment.replaystatus, toggleContextMenu, t]);

  const onDownloadError = (errorCode) => {
    const description = getErrorMessage(t, errorCode);
    setValidationData({ header: t('actionCantBeCompleted'), description });
    setShowValidationDialog(true);
  };

  return (
    <div className='model-card-outer'>
      {baselineLoading
        ? <div className='model-card-loading'>
          <div className='icon-container'>
            <LoadingWrapper inline />
          </div>
          <div className='model-card-loading-text'>
            <label
              className='name'
              title={t('uploadingBaselineDataFor', { name: model.name })}>
              {t('uploadingBaselineDataFor', { name: model.name })}
            </label>
            <label
              className='note'
              title={t('thisMayTakeAFewMinutes')}>
              {t('thisMayTakeAFewMinutes')}
            </label>
          </div>
        </div>
        : <div className='model-card-container'>
          <div className='model-card'>
            <div className='icon-container'>
              {isChampion
                ? <>
                  {baselineValid && !baselinePartial ?
                    <Tooltip title={t('championWithBaselineData')}>
                      <ChampionValid className='role-and-status-icon' />
                    </Tooltip> :
                    <Tooltip title={t(baselinePartial || baselineInvalid ? 'championPartialBaselineData' : 'championNoBaselineData')}>
                      <ChampionInvalid className='role-and-status-icon' />
                    </Tooltip>}
                </>
                : <>
                  {baselineValid && !baselinePartial ?
                    <Tooltip title={t('challengerWithBaselineData')}>
                      <ChallengerValid className='role-and-status-icon' />
                    </Tooltip> :
                    <Tooltip title={t(baselinePartial || baselineInvalid ? 'challengerPartialBaselineData' : 'challengerNoBaselineData')}>
                      <ChallengerInvalid className='role-and-status-icon' />
                    </Tooltip>}
                </>}
            </div>
            <div className='model-text'>
              <label
                className='type'
                title={t(model.type)}>
                {t(model.type)}
              </label>
              <label
                className='name'
                title={model.name}>
                {model.name}
              </label>
            </div>
            <div className='icon-container'>
              <ContextMenuHorizontalIcon
                className='context-icon clickable'
                onClick={(e) => toggleContextMenu(e)} />
            </div>
            {showContextMenu &&
              <ContextMenu onClose={toggleContextMenu}>
                {requireScope(scope, [Scopes.Deployments]) &&
                  <div
                    disabled={isChampion}
                    onClick={isChampion
                      ? undefined
                      : async (e) => await handlePromoteModel(e) }>
                    {t('promoteToChampion')}
                  </div>}
                {requireScope(scope, [Scopes.Models]) &&
                  <div
                    data-testid='upload-baseline'
                    disabled={baselineLoading}
                    onClick={baselineLoading
                      ? undefined
                      : (e) => {
                        toggleContextMenu(e);
                        setShowImportBaselineDataDialog(true);
                      }}>
                    {t(baselineValid ? 'replaceBaselineDataAction' : 'uploadBaselineDataAction')}
                  </div>}
                {requireScope(scope, [Scopes.ModelsRead]) &&
                  <>
                    <div
                      disabled={!baselineValid}
                      onClick={!baselineValid
                        ? undefined
                        : (e) => {
                          toggleContextMenu(e);
                          setShowDownloadBaselineDataDialog(true);
                        }}>
                      {t('downloadBaselineDataAction')}
                    </div>
                    <div
                      data-testid="modelInformation"
                      onClick={(e) => {
                        toggleContextMenu(e);
                        setShowModelInformationDialog(true);
                      }}>
                      {t('modelInformationAction')}
                    </div>
                  </>}
                {requireScope(scope, [Scopes.Models]) &&
                  <div
                    onClick={(e) => {
                      toggleContextMenu(e);
                      setShowRenameModelDialog(true);
                    }}>
                    {t('renameModelAction')}
                  </div>}
                {requireScope(scope, [Scopes.Deployments]) &&
                  <div
                    disabled={isChampion}
                    onClick={isChampion
                      ? undefined
                      : (e) => handleRemoveModel(e) }>
                    {t('remove')}
                  </div>}
              </ContextMenu>}
          </div>
        </div>}
      {showImportBaselineDataDialog &&
        <ImportBaselineDataDialog
          closeDialog={() => setShowImportBaselineDataDialog(false)}
          modelId={model.id}
          modelName={model.name}
          schema={model.schema}
          setBusy={setBusy}
          setIsUploadingBaseline={setIsUploadingBaseline}
          update={updateDeployment} />}
      {showDownloadBaselineDataDialog &&
        <DownloadDataDialog
          busyHeader={t('downloadingBaselineData')}
          closeDialog={() => setShowDownloadBaselineDataDialog(false)}
          download={Models.downloadBaselineData}
          header={t('downloadBaselineData')}
          id={model.id}
          name={`${model.name} ${t('baselineData')}`}
          prepareDownload={Models.prepareBaselineDataDownload}
          onDownloadError={onDownloadError} />}
      {showModelInformationDialog &&
        <ModelInformationDialog
          model={model}
          setShowModelInformationDialog={setShowModelInformationDialog}
          timezone={deployment.timezone ?? DefaultSettings.Timezone} />}
      {showRenameModelDialog &&
        <RenameModelDialog
          modelId={model.id}
          modelName={model.name}
          setBusy={setBusy}
          setShowRenameModelDialog={setShowRenameModelDialog}
          updateDeployment={updateDeployment} />}
      {showRemoveModelDialog &&
        <RemoveModelDialog
          deploymentId={deployment.id}
          modelId={model.id}
          setBusy={setBusy}
          setShowRemoveModelDialog={setShowRemoveModelDialog}
          updateDeployment={updateDeployment} />}
      {isPromoting &&
        <ModelBusy title={t('promotingModel')} />}
      {isUploadingBaseline &&
        <ModelBusy title={t('uploadingBaselineDataFor', { name: model.name })} />}
      {showValidationDialog &&
        <ValidationDialog
          description={validationData.description}
          header={validationData.header}
          onSubmit={() => setShowValidationDialog(false)} />}
    </div>
  );
};

export default ModelCard;