import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BatchScore from '../api/batch-score';
import { WarningIcon } from '../images';

const BatchScoreDownload = ({ data, resultsId }) => {
  const [exists, setExists] = useState();
  const [t] = useTranslation();

  useEffect(() => {
    let current = true;
    let exists;
    (async () => {
      try {
        const { size } = await (data === 'score' ?
          BatchScore.getScoreResultsInfo(resultsId) :
          BatchScore.getStabilityResultsInfo(resultsId));
        exists = size > 0;
      } catch (err) {
        console.error(err);
        exists = false;
      }
      if (current) {
        setExists(exists);
      }
    })();
    return () => current = false;
  }, [data, resultsId]);

  return (
    <div>
      {exists !== undefined && (exists ?
        <a href={`/api/${data}/${resultsId}/download`}>{t('clickHereToDownload')}</a> :
        <div className="upload-banner">
          <div className="banner-text">
            <label>
              <div><WarningIcon className="icon" /></div>
              {t('issueWithDownload')}
            </label>
            <p>{t('batchScoringFileError', { resultsId })}</p>
          </div>
        </div>)}
    </div>
  );
};

export default BatchScoreDownload;