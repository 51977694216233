import React, { useEffect, useState } from 'react';
import { TableContainer } from '@mtb/ui';
import { RequestTypes, DataTypes } from './Integration';
import {
  INITIAL_NUM_MULTI_ROWS,
  MAX_VARIABLE_ID_LENGTH,
  MAX_TEXT_INPUT_LENGTH,
  getValidationMessage,
  IntegrationFormSingle,
  IntegrationFormMulti,
  IntegrationFormFile,
} from './IntegrationForm';
import './Integration.scss';
import { InputTypes } from './Inputs';

const getColumnInfo = (variableId, response) => {
  if (response.type === 'categorical') {
    response.classes = response.classes.sort();
  }
  return [
    {
      name     : variableId,
      type     : 'variableid',
      required : true,
      maxLength: MAX_VARIABLE_ID_LENGTH
    },
    {
      ...response,
      maxLength: MAX_TEXT_INPUT_LENGTH
    }
  ];
};

const generateInitialValues = (variableId, response) => {
  const initialValues = [];
  for (let i = 0; i < INITIAL_NUM_MULTI_ROWS; i++) {
    initialValues.push({
      [variableId]   : { value: `UniqueID_${(i + 1).toString().padStart(3, '0')}`, type: InputTypes.Text },
      [response.name]: response.type === 'continuous' ? { value: 1.23, type: InputTypes.Numeric } : { value: response.classes[0], type: InputTypes.Text }
    });
  }
  return initialValues;
};

const StabilityForm = props => {
  const {
    t,
    variableId,
    response,
    values,
    setValues,
    formType,
    dataFile,
    setDataFile,
    uploadData,
    uploadRef,
    setValidationMessage,
    setResults,
  } = props;

  const [columnInfo, setColumnInfo] = useState([]);

  useEffect(() => {
    if (variableId !== undefined && Object.keys(response ?? {}).length && !Object.keys(values?.[0] ?? {}).length) {
      setColumnInfo(getColumnInfo(variableId, response));
      setValues(generateInitialValues(variableId, response));
    }
  }, [variableId, response, formType, values, setValues]);

  useEffect(() => {
    setValues([]);
  }, [setValues, formType]);

  useEffect(() => {
    setValidationMessage(getValidationMessage(t, values, { [response.name]: response }));
  }, [t, values, setValidationMessage, response, variableId]);

  const setRowValue = (property, value, row = 0) => {
    setValues(values.map((v, i) => {
      if (i === row) {
        return { ...v, [property]: value };
      }
      return v;
    }));
  };

  return (
    <TableContainer>
      {formType === DataTypes.SingleRow &&
        <IntegrationFormSingle
          columnInfo={columnInfo}
          response={response}
          setValue={setRowValue}
          type={RequestTypes.Stability}
          values={values}
          variableId={variableId} />}
      {formType === DataTypes.MultipleRows &&
        <IntegrationFormMulti
          columnInfo={columnInfo}
          response={response}
          setValue={setRowValue}
          type={RequestTypes.Stability}
          values={values} />}
      {formType === DataTypes.DataFile &&
        <IntegrationFormFile
          ref={uploadRef}
          dataFile={dataFile}
          setDataFile={setDataFile}
          setResults={setResults}
          setValidationMessage={setValidationMessage}
          t={t}
          type={RequestTypes.Stability}
          uploadData={uploadData} />}
    </TableContainer>);
};

export default StabilityForm;