import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { serializeError } from 'serialize-error';
import AppResources from '../resources/app';
import Confirm from './confirm';
import Deployments from '../api/deployments';
import Keys from '../api/keys';
import { OvalIcon, UndoIcon } from '../images';
import { FlexTableHeader } from './FlexTable';
import LoadingWrapper from './LoadingWrapper';
import { convertToUTC, sortHelper } from '../utils/model-utils';
import { requireScope, Scopes } from '../utils/scopes';
import Filter, { FilterStatuses } from './Filter';

import './apikeys.scss';
import './grids.scss';
import './flex-table.scss';
import './Filter.scss';
import { ErrorsContext, FlagsContext, ScopeContext } from '../utils/context';
import Settings, { getDefaultUserAppSettings } from '../api/settings';
import { debounce } from '../api/api-utils';

export const KeyStatuses = {
  Active : 'active',
  Revoked: 'revoked',
  Expired: 'expired'
};

export const StatusLabels = {
  [KeyStatuses.Active] : 'active',
  [KeyStatuses.Revoked]: 'revoked',
  [KeyStatuses.Expired]: 'expired'
};

const getStatus = (key) => {
  if (key.status) {
    return key.status;
  }
  const now = new Date();
  const expires = new Date(key.expireson);
  return (expires < now) ? KeyStatuses.Expired : KeyStatuses.Active;
};

const getTargetName = (t, targets, targetId) => {
  if (!targetId) {
    return t('all');
  }
  const target = targets?.data.find(target => target.id === targetId);
  return target?.name ?? t('unknown');
};

export const applyKeyProperties = (t, keys, targets) => {
  keys.forEach(key => {
    key.status = getStatus(key);
    if (targets) {
      key.targetname = getTargetName(t, targets, key.targetid);
    }
  });
  return keys;
};

const ApiKeys = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const { onError } = useContext(ErrorsContext);
  const flags = useContext(FlagsContext);
  const scope = useContext(ScopeContext);

  const [dialog, setDialog] = useState();
  const [keys, setKeys] = useState();
  const [targets, setTargets] = useState();
  const [filterByStatus, setFilterByStatus] = useState(flags.isr ? '' : getDefaultUserAppSettings('apiKeysFilter'));
  const [totalRows, setTotalRows] = useState(0);
  const [sortAscending, setSortAscending] = useState(getDefaultUserAppSettings('apiKeysSortAscending'));
  const [sortProperty, setSortProperty] = useState(getDefaultUserAppSettings('apiKeysSortProperty'));

  const filteredKeys = useMemo(() =>
    keys?.filter(key => filterByStatus === FilterStatuses.AllKeys ? true : key.status === filterByStatus)
  , [filterByStatus, keys]);

  useEffect(() => {
    if (scope && !requireScope(scope, Scopes.KeysRead)) {
      // Redirect, user does not have permission and likely manually entered the link.
      history.replace('/models');
    }
  }, [scope, history]);

  const getSortFunc = useCallback(() => {
    if (keys?.length) {
      if (['createdon', 'expireson', 'lastusedon'].includes(sortProperty)) {
        return (a, b) => sortHelper(new Date(a[sortProperty]), new Date(b[sortProperty]), sortAscending);
      }
      return (a, b) => sortHelper(a[sortProperty], b[sortProperty], sortAscending);
    }
    return () => 0;
  }, [keys, sortProperty, sortAscending]);

  const reloadKeys = useCallback(async () => {
    try {
      let { apiKeys } = await Keys.getAll();
      apiKeys = applyKeyProperties(t, apiKeys, targets);
      setTotalRows(apiKeys.length);
      setKeys(apiKeys);
    } catch (err) {
      onError(serializeError(err));
    }
  }, [t, targets, onError]);

  useEffect(() => {
    if (targets) {
      reloadKeys();
    }
  }, [targets, reloadKeys]);

  // Load keys on first load
  useEffect(() => {
    if (keys) {
      return;
    }
    (async () => {
      if (flags.isr) {
        const settings = await Settings.getUserApplicationSettings();
        setSortAscending(settings.apiKeysSortAscending);
        setSortProperty(settings.apiKeysSortProperty);
        setFilterByStatus(settings.apiKeysFilter);
      }

      const [targets, { apiKeys }] = await Promise.all([
        Deployments.getAll(),
        Keys.getAll()
      ]);
      setTargets(targets);
      const keys = applyKeyProperties(t, apiKeys, targets);
      setKeys(keys);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys]);

  useEffect(() => {
    document.title = `${AppResources.ApplicationName} - ${t('apiKeys')}`;
  });

  const debouncePatch = useMemo(() =>
    debounce(async (settings) => await Settings.patchUserApplicationSettings(settings), 400),
  []);

  const toggleSort = useCallback(async property => {
    const newOrder = sortProperty === property ? !sortAscending : true;
    setSortAscending(newOrder);
    setSortProperty(property);
    flags.isr && debouncePatch({ apiKeysSortProperty: property, apiKeysSortAscending: newOrder });
  }, [sortAscending, sortProperty, debouncePatch, flags]);

  const revokeKey = async (tokenId) => {
    setDialog(<Confirm
      cancel={() => setDialog()}
      confirm={async () => {
        setDialog();
        await Keys.patch(tokenId, { status: KeyStatuses.Revoked });
        await reloadKeys();
      }}
      confirmationText={'confirmRevokeKey'}
      primaryButtonText={'revokeKey'}
      t={t}
      title={'revokeKey'} />);
  };

  return (
    <div className='api-keys-content'>
      <h3>{t('apiKeys')}</h3>
      <div className='toolbar'>
        <Filter
          filterStatus={filterByStatus}
          t={t}
          onFilterStatusChanged={ (value) => {
            flags.isr && Settings.patchUserApplicationSettings({ apiKeysFilter: value });
            setFilterByStatus(value);
          }} />
        <div className="gridStats noClick">
          {t('apiKeyCounts', { total: totalRows })}
        </div>
      </div>
      <div className="table-container">
        <LoadingWrapper
          caption={t('loadingCurrentKeys')}
          isLoading={!keys} />
        {!filteredKeys?.length ?
          <p>{t('noKeysExist')}</p> :
          <FlexTableHeader
            columns={{
              keyname       : 'keyNameUppercase',
              targetname    : 'deployment',
              status        : 'status',
              expireson     : 'expirationDate',
              lastusedon    : 'lastUsed',
              createdon     : 'created',
              createdbyemail: 'createdBy',
            }}
            sortAscending={sortAscending}
            sortProperty={sortProperty}
            t={t}
            toggleSort={toggleSort} />
        }
        {filteredKeys
          ?.toSorted(getSortFunc())
          ?.map((key, id) =>
            <div
              key={id}
              className="flex-table row">
              <div
                className="flex-cell"
                title={key.keyname}>
                <span>{key.keyname}</span>
              </div>
              <div
                className="flex-cell"
                data-testId="targetname-cell"><span>{key.targetname}</span></div>
              <div className="flex-cell">
                <div
                  className='status'
                  title={t(StatusLabels[key.status])}>
                  <OvalIcon className={classNames(key.status)} />
                  <div>{t(StatusLabels[key.status])}</div>
                </div>
              </div>
              <div
                className="flex-cell"
                data-testId="expireson-cell"><span>{convertToUTC(key.expireson, true)}</span></div>
              <div className="flex-cell"><span>{convertToUTC(key.lastusedon, true)}</span></div>
              <div className="flex-cell"><span>{convertToUTC(key.createdon, true)}</span></div>
              <div
                className="flex-cell"
                title={key.createdbyemail}>
                <span>{key.createdbyemail}</span>
              </div>
              <div className="flex-cell icons">
                <div className="actionLink">
                  {(key.status === 'active' && requireScope(scope, Scopes.Keys)) &&
                    <div
                      className="actionLinkItem"
                      title={t('revokeKey')}
                      onClick={() => revokeKey(key.tokenid)}>
                      <UndoIcon className="gridIcon" />
                    </div>}
                </div>
              </div>
            </div>
          )
        }
      </div>
      {dialog}
    </div>
  );
};

export default ApiKeys;
