import { Button, Checkbox, FormControlLabel, Select, MenuItem, ListItemText } from '@mtb/ui';
import React, { useEffect, useRef, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Models from '../api/models';
import { isFileSafe } from '../utils/file-utils';
import { extractMSSProjectModels, extractGroveModels } from '../utils/model-file-utils';
import { getErrorMessage } from '../utils/errors/model-errors';
import { Statuses } from './ModelRepository';
import LoadingWrapper from './LoadingWrapper';
import './dialogs.scss';
import { LocalizedInput, InputTypes } from './Inputs';

const MAX_MODEL_NAME_LENGTH = 50;

const isGzlm = type => type === 'binary_logistic_regression';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const waitForModelPublished = async id => {
  let retries = 0;
  while (retries < 120) {
    const { status } = await Models.get(id, 'status');
    if (status === Statuses.UploadFailed) {
      return false;
    } else if (status === Statuses.Published) {
      return true;
    }
    retries++;
    await sleep(1000);
  }
  throw new Error('Upload timed out');
};

// Send API call to publish model with given metadata
export const publishModel = async (model, metadata, includeTrainingData) => {
  const data = new FormData();
  data.append('model', model);
  data.append('metadata', JSON.stringify(metadata));
  const { id } = await Models.publish(data, includeTrainingData);
  const success = await waitForModelPublished(id);
  return { success, id };
};

// Helper fn. to await publishModel with error handling
export const importModel = async props => {
  const {
    t,
    project,
    model,
    modelName,
    binaryThreshold,
    eventName,
    nonEventName,
    defaultModelName,
    setDefaultModelName,
    includeTrainingData,
    setValidationMessage,
    reloadModels,
    onPublish
  } = props;

  try {
    setValidationMessage('');
    const { isEventTrial, type, commandOutOfDate } = model || {};
    const metadata = {
      name   : modelName,
      source : model?.source,
      type   : model?.type,
      modelid: model?.id,
      treeid : model?.treeId,
      ...((isEventTrial || isGzlm(type)) && {
        binarythreshold: binaryThreshold.value.toString()
      }),
      ...(isEventTrial && {
        eventname   : eventName,
        noneventname: nonEventName
      })
    };
    if (metadata.binarythreshold && binaryThreshold.type !== InputTypes.Numeric) {
      const err = { message: 'InvalidBinaryThreshold' };
      throw err;
    }
    const showBaselineDataNotCurrent = includeTrainingData && commandOutOfDate;
    const { success, id } = await onPublish(project, metadata, includeTrainingData, showBaselineDataNotCurrent);
    if (!success && id) {
      // Model was uploaded but failed to publish.
      await reloadModels?.();
      if (defaultModelName && modelName === defaultModelName) {
        setDefaultModelName();
      }
      const newModel = await Models.get(id);
      throw new Error(newModel?.errorcode);
    }
    return { success, id };
  } catch (e) {
    if ((e.status === 400 && e.body?.errorCode) || e.message) {
      const errorCode = e.body?.errorCode || e.message;
      setValidationMessage(getErrorMessage(t, errorCode));
    } else {
      console.error(e);
    }
    return { success: false };
  }
};

const ModelNameField = props => {
  const {
    currentModels,
    modelName,
    setModelName,
    defaultModelName,
    setDefaultModelName,
    embedded
  } = props;

  const [t] = useTranslation();

  const validateName = () => {
    if (!modelName.trim().length) {
      setModelName(defaultModelName);
    }
  };

  useEffect(() => {
    if (currentModels && !defaultModelName) {
      let modelNum = currentModels.length + 1;
      const currentNames = currentModels.map(({ name }) => name.toLowerCase());
      while (currentNames.includes(t('untitledModel', { num: modelNum }).toLowerCase())) {
        modelNum++;
      }
      const availableName = t('untitledModel', { num: modelNum });
      setDefaultModelName(availableName);
      setModelName(availableName);
    }
  }, [t, currentModels, setModelName, defaultModelName, setDefaultModelName]);

  return <>
    <div className={classNames('formControl', { 'challenger': !embedded, 'row': embedded })}>
      <label htmlFor="modelName">{t(embedded ? 'modelNameLabel' : 'modelName')}</label>
      <div className="inputWithText">
        <input
          autoFocus={embedded}
          id="modelName"
          maxLength={MAX_MODEL_NAME_LENGTH}
          tabIndex="0"
          type="text"
          value={modelName}
          onBlur={validateName}
          onChange={({ target: { value } }) => setModelName(value)} />
      </div>
    </div>
  </>;
};

const ProjectFile = props => {
  const {
    modelInput,
    acceptedFileTypes,
    setFileError,
    setModel,
    setModels,
    setProject,
    setBinaryThreshold,
    setValidationMessage,
    setBusy
  } = props;

  const [t] = useTranslation();
  const [fileName, setFileName] = useState(t('selectAFile'));

  const updateSelectedFile = async () => {
    try {
      setBusy(true);
      setModels([]);
      setModel();
      setFileError();
      setValidationMessage();
      const file = modelInput.current.files[0];
      const { name } = file || { name: t('selectAFile') };
      setFileName(name);
      const fileError = isFileSafe(file);
      if (fileError) {
        throw fileError;
      }
      let foundModels = [];
      const extension = name.split('.').pop().toUpperCase();
      if (extension === 'MPX') {
        foundModels = await extractMSSProjectModels(file);
      } else if (extension === 'GRV' && acceptedFileTypes.includes('.GRV')) {
        foundModels = ((await extractGroveModels(file)) || []).map(x => ({ ...x, source: 'spm-grove-model' }));
      }
      setModels(foundModels || []);
      if (foundModels.length > 0) {
        const model = foundModels[0];
        setProject(file);
        setModel(model);
        if (isGzlm(model.type) && !model.isEventTrial) {
          setBinaryThreshold({ value: model.originalThreshold, type: InputTypes.Numeric });
        }
      } else {
        setModel();
      }
    } catch (err) {
      setFileError(t(err.key, err.hints));
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className="formControl challenger modelUpload">
      <label htmlFor="modelFile">{t('projectFile')}</label>
      <div className="relative">
        <div
          className="overlay"
          onClick={() => modelInput.current.click()}>
          <div className="file">
            <span>{fileName}</span>
            <input
              ref={modelInput}
              accept={acceptedFileTypes}
              id="modelFile"
              type="file"
              onChange={updateSelectedFile} />
          </div>
          <Button>{t('browse')}</Button>
        </div>
      </div>
    </div>
  );
};

const ModelSelect = props => {
  const {
    model,
    models,
    source,
    trees,
    treeId,
    setModel,
    binaryThreshold,
    setBinaryThreshold,
    eventName,
    setEventName,
    nonEventName,
    setNonEventName,
    includeTrainingData,
    setIncludeTrainingData,
    embedded
  } = props;

  const [t] = useTranslation();
  const { id, isEventTrial, type } = model || {};
  const uniqueTrees = useMemo(() => {
    const t = trees?.reduce((acc, tree) =>
      acc.some((t) => tree.id === t.id, acc) ? acc : [...acc, tree], []);
    return t;
  }, [trees]);

  return <>
    {!embedded && models && (
      <div className={classNames('formControl select challenger')}>
        <label htmlFor="modelId">{t('model')}</label>
        <Select
          name="models"
          value={`${type}/${id}`}
          onChange={({ target: { value } }) => {
            const [selectedType, selectedId] = value.split('/');
            const newModel = models.find(({ id, type }) => selectedType === type && selectedId === id);
            setModel(newModel);
            setBinaryThreshold({ value: newModel.type === 'binary_logistic_regression' && !newModel.isEventTrial
              ? newModel.originalThreshold
              : 0.5, type: InputTypes.Numeric });
          }}>
          {models.map(({ id, name, type }) => (
            <MenuItem
              key={`${type}/${id}`}
              value={`${type}/${id}`}>
              <ListItemText
                className='listItemText'
                primary={name}
                title={name} />
            </MenuItem>
          ))}
        </Select>
      </div>
    )}
    {!embedded && (
      <div className='formControl'>
        <FormControlLabel
          control={<Checkbox
            checked={includeTrainingData}
            size='small' />}
          label={t('useTrainingData')}
          onChange={e => setIncludeTrainingData(e.target.checked)} />
      </div>
    )}
    {source === 'mss-project-model' &&
      ['classification_tree', 'regression_tree'].includes(type) &&
      uniqueTrees?.length > 1 && (
      <div className={classNames('formControl', embedded ? 'row' : 'challenger')}>
        <label htmlFor="treeId">{t(embedded ? 'treeLabel' : 'tree')}</label>
        <Select
          name="models"
          style={{ maxWidth: 'fit-content' }}
          value={treeId}
          onChange={({ target: { value } }) => setModel({ ...model, treeId: value })}>
          {uniqueTrees.map(({ id, name }) => (
            <MenuItem
              key={id}
              value={id}>
              <ListItemText
                className='listItemText'
                primary={`${name} - ${id}`}
                title={`${name} - ${id}`} />
            </MenuItem>
          ))}
        </Select>
      </div>
    )}
    {(isEventTrial || isGzlm(type)) && <>
      <div className={classNames('formControl', embedded ? 'row' : 'challenger')}>
        <label>{t('binaryEventProbabilityThresholdLabel')}</label>
        <LocalizedInput
          className='fit'
          id='binaryThreshold'
          initialValue={binaryThreshold.value}
          inputProps={{
            maxLength: 100
          }}
          onChange={value => setBinaryThreshold(value)} />
      </div>
      {isEventTrial && <>
        <div className="formControl row">
          <label>{t('eventNameLabel')}</label>
          <input
            id="eventName"
            maxLength={100}
            type="text"
            value={eventName}
            onChange={({ target: { value } }) => setEventName(value)} />
          <br />
        </div>
        <div className="formControl row">
          <label>{t('nonEventNameLabel')}</label>
          <input
            id="nonEventName"
            maxLength={100}
            type="text"
            value={nonEventName}
            onChange={({ target: { value } }) => setNonEventName(value)} />
        </div>
      </>}
    </>}
  </>;
};

export const ModelImport = props => {
  const {
    project,
    setProject,
    model,
    setModel,
    modelName,
    setModelName,
    defaultModelName,
    setDefaultModelName,
    binaryThreshold,
    setBinaryThreshold,
    eventName,
    setEventName,
    nonEventName,
    setNonEventName,
    validationMessage,
    setBusy,
    setValidationMessage,
    includeTrainingData,
    setIncludeTrainingData,
    acceptedFileTypes,
    currentModels,
    embedded = false
  } = props;

  const [t] = useTranslation();
  const modelInput = useRef();
  const [models, setModels] = useState(props.initialModels || []);
  const [fileError, setFileError] = useState();

  useEffect(() => setModels(props.initialModels), [props.initialModels]);

  const { trees, source, treeId, type } = model || {};

  return <>
    {!embedded && <>
      <ProjectFile
        acceptedFileTypes={acceptedFileTypes}
        modelInput={modelInput}
        setBinaryThreshold={setBinaryThreshold}
        setBusy={setBusy}
        setFileError={setFileError}
        setModel={setModel}
        setModels={setModels}
        setProject={setProject}
        setValidationMessage={setValidationMessage} />
      {project && model && !fileError && <>
        <ModelSelect
          binaryThreshold={binaryThreshold}
          eventName={eventName}
          includeTrainingData={includeTrainingData}
          model={model}
          models={models}
          nonEventName={nonEventName}
          setBinaryThreshold={setBinaryThreshold}
          setEventName={setEventName}
          setIncludeTrainingData={setIncludeTrainingData}
          setModel={setModel}
          setNonEventName={setNonEventName}
          source={source}
          treeId={treeId}
          trees={trees} />
        <ModelNameField
          currentModels={currentModels}
          defaultModelName={defaultModelName}
          modelName={modelName}
          setDefaultModelName={setDefaultModelName}
          setModelName={setModelName} />
      </>}
    </>}
    {embedded && <>
      <span>{t('uploadModel')}</span>
      <ModelNameField
        currentModels={currentModels}
        defaultModelName={defaultModelName}
        embedded
        modelName={modelName}
        setDefaultModelName={setDefaultModelName}
        setModelName={setModelName} />
      {type &&
        <div className="formControl row">
          <label>{t('modelTypeLabel')}</label>
          <label>{t(type)}</label>
        </div>}
      {project &&
        <ModelSelect
          binaryThreshold={binaryThreshold}
          embedded
          eventName={eventName}
          model={model}
          models={models}
          nonEventName={nonEventName}
          setBinaryThreshold={setBinaryThreshold}
          setEventName={setEventName}
          setModel={setModel}
          setNonEventName={setNonEventName}
          source={source}
          treeId={treeId}
          trees={trees} />}
    </>}
    {fileError && <div className='validationMessage'>{fileError}</div>}
    {validationMessage && !fileError && (
      <div className='validationMessage'>
        {modelInput?.current?.files?.length > 0 && models && !models.length
          ? t('validateModelRequired')
          : validationMessage}
      </div>
    )}
  </>;
};

const ModelImportDialog = props => {
  const {
    acceptedFileTypes,
    currentModels,
    initialModel,
    initialProject,
    reloadModels,
    onCancel,
    onPublish,
    embedded = false
  } = props;

  const [t] = useTranslation();
  const [project, setProject] = useState(initialProject);
  const [model, setModel] = useState(initialModel);
  const [modelName, setModelName] = useState('');
  const [defaultModelName, setDefaultModelName] = useState('');
  const [binaryThreshold, setBinaryThreshold] = useState({ value: 0.5, type: InputTypes.Numeric });
  const [eventName, setEventName] = useState('Event');
  const [nonEventName, setNonEventName] = useState('Non-event');
  const [includeTrainingData, setIncludeTrainingData] = useState(!embedded);
  const [uploading, setUploading] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    setProject(initialProject);
    const fileError = isFileSafe(initialProject);
    if (fileError) {
      setValidationMessage(t(fileError.key, fileError.hints));
    }
  }, [t, initialProject, setModel, setProject, setValidationMessage]);

  useEffect(() => setModel(initialModel), [initialModel, setModel]);

  const closeDialog = () => onCancel();

  const submitDialog = async () => {
    setUploading(true);
    await importModel({
      t,
      project,
      model,
      modelName: modelName || defaultModelName,
      binaryThreshold,
      eventName,
      nonEventName,
      includeTrainingData,
      defaultModelName,
      setDefaultModelName,
      setValidationMessage,
      reloadModels,
      onPublish
    });
    setUploading(false);
  };

  return (
    <div className="dialogContainer">
      <div
        className="modalDialog challengerInput"
        role="dialog">
        <h3>{t('importModel')}</h3>
        <LoadingWrapper
          caption={t('loading')}
          className='centered'
          isLoading={!currentModels || (embedded && !model)} />
        <div className={classNames({ 'import-native': !embedded })}>
          <ModelImport
            acceptedFileTypes={acceptedFileTypes}
            binaryThreshold={binaryThreshold}
            currentModels={currentModels}
            defaultModelName={defaultModelName}
            embedded={embedded}
            eventName={eventName}
            includeTrainingData={includeTrainingData}
            model={model}
            modelName={modelName}
            nonEventName={nonEventName}
            project={project}
            setBinaryThreshold={setBinaryThreshold}
            setBusy={setUploading}
            setDefaultModelName={setDefaultModelName}
            setEventName={setEventName}
            setIncludeTrainingData={setIncludeTrainingData}
            setModel={setModel}
            setModelName={setModelName}
            setNonEventName={setNonEventName}
            setProject={setProject}
            setValidationMessage={setValidationMessage}
            validationMessage={validationMessage}
            {...props} />
          <div className="buttons">
            <button
              className="cancelButton"
              disabled={uploading}
              type="button"
              onClick={closeDialog}>
              {t('cancel')}
            </button>
            <button
              className="confirmButton"
              disabled={uploading}
              type="button"
              onClick={submitDialog}>
              {t(uploading ? 'uploadingAction' : embedded ? 'import' : 'importModel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelImportDialog;