/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ChevronLeftIcon, ChevronRightIcon } from '@mtb/ui';
import { ContextMenuHorizontalIcon } from '../images';
import { formatDayLocalDateTime, formatShortLocalDateTime, isDateRangeCurrent } from '../utils/locales';

const getVisibleChipIndexes = (totalWidth, binDates, currentIndex, chipWidths) => {
  const totalChips = binDates.length;

  // Always show the first two and last two chips
  let visibleIndexes = [0, 1, totalChips - 2, totalChips - 1];

  // Calculate the total width of the first two and last two chips
  let fixedWidth = chipWidths[0] + chipWidths[1] + chipWidths[totalChips - 2] + chipWidths[totalChips - 1];
  if (!visibleIndexes.includes(currentIndex)) {
    fixedWidth += chipWidths[currentIndex];
  }

  // Calculate the available width for the centered chips
  const availableWidth = totalWidth - fixedWidth;

  // Calculate the number of chips to show on each side of the current chip
  let currentWidth = chipWidths[currentIndex];
  let leftIndex = currentIndex - 1;
  let rightIndex = currentIndex + 1;

  while (currentWidth < availableWidth && (leftIndex >= 2 || rightIndex <= totalChips - 3)) {
    if (leftIndex >= 2) {
      currentWidth += chipWidths[leftIndex];
      visibleIndexes.push(leftIndex);
      leftIndex--;
    }
    if (currentWidth < availableWidth && rightIndex <= totalChips - 3) {
      currentWidth += chipWidths[rightIndex];
      visibleIndexes.push(rightIndex);
      rightIndex++;
    }
  }

  visibleIndexes.push(currentIndex);
  visibleIndexes = Array.from(visibleIndexes).sort((a, b) => a - b);

  // Insert gaps (nulls) between disparate chip indexes
  const filledArray = [];
  let lastWasNull = false;
  for (let i = 0; i <= totalChips - 1; i++) {
    if (visibleIndexes.includes(i)) {
      filledArray.push(i);
      lastWasNull = false;
    } else if (!lastWasNull) {
      filledArray.push(null);
      lastWasNull = true;
    }
  }

  return filledArray;
};

const DriftBinNavigation = (props) => {
  const {
    additionalInfo,
    binLabels,
    binDateRange,
    totalBins,
    binIndex,
    setBinIndex,
    setIsCurrentDate,
    setShowReport,
    timezone
  } = props;

  const [t] = useTranslation();
  const activeBinRef = useRef();

  const [binDates, setBinDates] = useState([]);
  const [chipWidths, setChipWidths] = useState([]);
  const [visibleBinIndexes, setVisibleBinIndexes] = useState([]);

  const formattedDateRange = useMemo(() => {
    return {
      start: formatShortLocalDateTime(binDateRange.start, timezone),
      end  : formatShortLocalDateTime(binDateRange.end, timezone)
    };
  }, [timezone, binDateRange.end, binDateRange.start]);

  useEffect(() => {
    const dayLabels = binLabels?.map(label => formatDayLocalDateTime(label.start, timezone));
    const timeLabels = binLabels?.map(label => formatShortLocalDateTime(label.start, timezone));
    // Include timestamp if there are duplicate YYYY-MM-DD labels
    const finalLabels = dayLabels?.map((dayLabel, i) => dayLabels.filter(l => l === dayLabel).length > 1 ? timeLabels[i] : dayLabel);
    setBinDates(finalLabels);
    setChipWidths(finalLabels.map(label => 50 + label.length * 6));
  }, [binLabels, timezone]);

  useEffect(() => {
    const { start, end } = binDateRange;
    setIsCurrentDate(isDateRangeCurrent(start, end));
  }, [binIndex, totalBins, binDateRange, setIsCurrentDate]);

  const handleResize = useCallback(() => {
    setVisibleBinIndexes(getVisibleChipIndexes(window.innerWidth - 100, binDates, binIndex, chipWidths));
  }, [binDates, binIndex, chipWidths]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => handleResize(), [handleResize]);

  const removeFocus = useCallback(() => {
    if (document.activeElement.id.startsWith('nav-pill')) {
      document.activeElement.blur();
    }
  }, []);

  const navigateLeft = useCallback(e => {
    e.preventDefault();
    if (binIndex > 0) {
      setShowReport(false);
      setBinIndex(binIndex - 1);
    }
    removeFocus();
  }, [binIndex, setBinIndex, removeFocus, setShowReport]);

  const navigateRight = useCallback(e => {
    e.preventDefault();
    if (binIndex < totalBins - 1) {
      setShowReport(false);
      setBinIndex(binIndex + 1);
    }
    removeFocus();
  }, [totalBins, binIndex, setBinIndex, removeFocus, setShowReport]);

  useEffect(() => {
    const handleKeyDown = e => {
      e.key === 'ArrowLeft' && navigateLeft(e);
      e.key === 'ArrowRight' && navigateRight(e);
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [navigateLeft, navigateRight]);

  const binPills = useMemo(() => visibleBinIndexes.map((bin, i) => (
    bin === null ?
      <ContextMenuHorizontalIcon
        key={i}
        className='icon' /> :
      <Button
        key={i}
        ref={binIndex === i ? activeBinRef : null}
        className={classNames({ 'active': binIndex === bin })}
        id={`nav-pill-${bin}`}
        tabIndex={binIndex === bin ? 0 : -1}
        onClick={() => setBinIndex(bin)}>
        {binDates[bin]}
      </Button>
  )), [visibleBinIndexes, binDates, binIndex, setBinIndex]);

  return (
    <div className="period-selector">
      <div className="nav-pills-container">
        {binLabels.length > 1 && (
          <div className='nav-button'>
            <ChevronLeftIcon
              className={classNames(binIndex === 0 ? 'icon-disabled' : 'clickable')}
              onClick={navigateLeft} />
          </div>
        )}
        <div className="nav-pills">{binPills}</div>
        {binLabels.length > 1 && (
          <div className='nav-button'>
            <ChevronRightIcon
              className={classNames(binIndex === totalBins - 1 ? 'icon-disabled' : 'clickable')}
              onClick={navigateRight} />
          </div>
        )}
      </div>
      <div className="period-label-and-toggle-buttons">
        <label>{t('dateRange', formattedDateRange)}</label>
        {additionalInfo}
      </div>
    </div>
  );
};

export default DriftBinNavigation;