import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
} from '@mtb/ui';
import DateRangeSelector from './DateRangeSelector';
import { FlagsContext } from '../utils/context';
import { PeriodSettings } from '../utils/model-utils';
import { TimezoneOptions, getOffset, formatDate } from '../utils/locales';

import './Prototype.scss';
import './header.scss';

const Prototype = () => {
  const [selectedTimeZone, setSelectedTimeZone] = useState('America/New_York');
  const [timeOfDay, setTimeOfDay] = useState(0);
  const [offset, setOffset] = useState(0);
  const [adjustedOffset, setAdjustedOffset] = useState(0);
  const defaultStartDate = formatDate(Date.now());
  const defaultEndDate = formatDate(Date.now());
  const defaultPeriod = PeriodSettings.Weekly;
  const [currentDates, setCurrentDates] = useState({ defaultStartDate, defaultEndDate, defaultPeriod });

  const flags = useContext(FlagsContext);
  const [t] = useTranslation();

  useEffect(() => {
    const timeZone = TimezoneOptions.find(tz => tz.name === selectedTimeZone);
    const utcOffset = getOffset(timeZone.name, timeOfDay);
    setOffset(utcOffset + (timeZone.offset / 60));
    setAdjustedOffset(utcOffset);
  }, [flags, selectedTimeZone, timeOfDay]);

  return (
    <div className='prototype'>
      <div className='start-of-day'>
        <FormControl className='select'>
          <FormLabel>Time Zone</FormLabel>
          <Select
            className='timezone'
            fullWidth
            value={selectedTimeZone}
            onChange={({ target: { value } }) => setSelectedTimeZone(value)}>
            {TimezoneOptions.map(tz =>
              <MenuItem
                key={tz.name}
                value={tz.name}>
                {tz.label} {t('utcOffset', { sign: tz.sign, offset: Math.abs(tz.offset / 60) })}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl className='select'>
          <FormLabel>Time of Day</FormLabel>
          <Select
            value={timeOfDay}
            onChange={({ target: { value } }) => setTimeOfDay(value)}>
            {[...Array(24).keys()].map(time =>
              <MenuItem
                key={time}
                value={time}>
                {`${time < 10 ? `0${time}` : time}:00`}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div className="report-header">
        <DateRangeSelector
          current={currentDates}
          resetValidation={() => {}}
          setCurrent={setCurrentDates} />
      </div>
      <div className='stats'>
        <div>Calculated offset {offset}</div>
        <div>Adjusted offset {adjustedOffset}</div>
      </div>
    </div>
  );
};

export default Prototype;