import { serializeError } from 'serialize-error';
import Sessions from '../api/sessions';

// This is a 1 hour timeout to check for user inactivity.
const ACTIVITY_SESSION_TIMEOUT = 60 * 60 * 1000;

// This is a 10 min timeout if the user doesn't respond, we end the session.
const EXPIRED_SESSION_TIMEOUT = 10 * 60 * 1000;

// This is the timeout that we need to call the lp to verify the user's session is
// still valid.
const LP_HEARTBEAT_TIMEOUT = 7.5 * 60 * 1000;

// Only retry twice otherwise redirect back to the lp site.
const LP_HEARTBEAT_ATTEMPT_LIMIT = 1;

// This sets how often the client checks for an updated server version
const VERSION_CHECK_INTERVAL = 1.0 * 60 * 1000;

class SessionManager {
  _warningCallback = null;
  _expiredCallback = null;
  _versionMismatchCallback = null;
  _activityTimeout = null;
  _expireTimeout = null;
  _heartBeatTimeout = null;
  _checkVersion = null;
  _isUserActivityDialogVisible = false;

  constructor(
    activitySessionTimeout = ACTIVITY_SESSION_TIMEOUT,
    expiredSessionTimeout = EXPIRED_SESSION_TIMEOUT,
    lpHeartBeatSessionTimeout = LP_HEARTBEAT_TIMEOUT,
  ) {
    this._activitySessionTimeout = activitySessionTimeout;
    this._expiredSessionTimeout = expiredSessionTimeout;
    this._lpHeartBeatSessionTimeout = lpHeartBeatSessionTimeout;
  }

  set warningCallback(callback) {
    this._warningCallback = callback;
  }

  set expiredCallback(callback) {
    this._expiredCallback = callback;
  }

  set versionMismatchCallback(callback) {
    this._versionMismatchCallback = callback;
  }

  set activitySessionTimeout(time) {
    this._activitySessionTimeout = time ? Number(time) * 1000 : ACTIVITY_SESSION_TIMEOUT;
  }

  set expiredSessionTimeout(time) {
    this._expiredSessionTimeout = time ? Number(time) * 1000 : EXPIRED_SESSION_TIMEOUT;
  }

  async setHeartBeat(user, retry = 0) {
    const lpStatusUri = `${user.identifyingPartyUri}api/v1/seats/status?${new URLSearchParams({
      subscriptionId: user.subscriptionId,
      productId     : user.productId,
      seatId        : user.seat
    })}`;

    this._heartBeatTimeout && clearTimeout(this._heartBeatTimeout);

    // Only retry twice otherwise redirect back to the lp site.
    if (retry > LP_HEARTBEAT_ATTEMPT_LIMIT) {
      await Sessions.signout();
      return;
    }
    try {
      const response = await fetch(lpStatusUri, {
        credentials: 'include',
        headers    : {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok || (response.status >= 400 && response.status < 500)) {
        await Sessions.signout();
        return;
      }
    } catch (error) {
      const status = error?.response?.status;
      // If the error is 400-499 this is a failed heartbeat so try and re-authorize the session.
      // If it fails, redirect to the lp site.  Else, any other status, try again.
      if (status >= 400 && status < 500) {
        // Failed a heartbeat - sign out of app and attempt to get a new seat
        // through LP flow. If this fails user will land in LP.
        await Sessions.signout();
        return;
      }
      await this.setHeartBeat(user, ++retry);
    }

    // If it was successful reset set the timer with the potential new claims if the seat
    // was updated.
    this._heartBeatTimeout = setTimeout(() => {
      this.setHeartBeat(user, 0);
    }, this._lpHeartBeatSessionTimeout);
    return true;
  }

  setSessionTimeout() {
    if (this._isUserActivityDialogVisible) {
      return;
    }

    // First, clear the current timeouts so that they don't continue to popup.
    this._resetSessionTimeout();

    // This timeout shows up if the user has left the session in active for an hour.
    this._activityTimeout = setTimeout(async () => {
      this._isUserActivityDialogVisible = true;

      // This timeout should occur after 10 mins informing the user their session has expired.
      this._expireTimeout = setTimeout(() => {
        this._sessionAlive = false;
        this._expiredCallback?.();
      }, this._expiredSessionTimeout);

      // The dialog that asks the user if they are still present.
      this._warningCallback?.();
    }, this._activitySessionTimeout);
  }

  setVersionCheck(currentVersion) {
    this._checkVersion && clearInterval(this._checkVersion);

    const version_check = (async () => {
      if (this._isUserActivityDialogVisible) {
        return;
      }
      try {
        const { version } = await Sessions.getFlags();
        if (version !== currentVersion) {
          this._versionMismatchCallback?.();
        }
      } catch (error) {
        console.error(serializeError(error));
      }
    });

    // start version check timer (every minute)
    this._checkVersion = setInterval(version_check, VERSION_CHECK_INTERVAL);
  }

  restoreSession() {
    this._isUserActivityDialogVisible = false;
    this._resetSessionTimeout();
    this.setSessionTimeout();
  }

  _resetSessionTimeout() {
    this._activityTimeout && clearTimeout(this._activityTimeout);
    this._expireTimeout && clearTimeout(this._expireTimeout);
  }
}

export const sessionService = new SessionManager();