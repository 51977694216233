export const MAX_FILE_SIZE_10_MB = 1024 * 1024 * 10;
export const MAX_FILE_SIZE_50_MB = 1024 * 1024 * 50;

export const isFileSafe = (file, file_size_limit = MAX_FILE_SIZE_50_MB) => {
  if (file) {
    // Going with the windows 10 MAX_PATH 260 value
    const file_path_limit = 260;
    if (file.name?.length > file_path_limit) {
      return { key: 'validateFileName', hints: { limit: file_path_limit } };
    }
    // Default 50 MB file
    if (file.size > file_size_limit) {
      return { key: 'validateFileSize', hints: { limit: file_size_limit } };
    }
  }
};