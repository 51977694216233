import React from 'react';
import classNames from 'classnames';
import { ArrowDownIcon, ArrowUpIcon } from '../images';
import './flex-table.scss';

export const getSortIcon = sortAscending => {
  return sortAscending ?
    <ArrowUpIcon className="sortIcon" /> :
    <ArrowDownIcon className="sortIcon" />;
};

export const FlexTableHeader = ({ t, columns, toggleSort, sortProperty, sortAscending }) => {
  const columnProperties = Object.keys(columns);
  const columnLabels = Object.values(columns);
  return (
    <div className="flex-table flex-header">
      {columnProperties.map((property, index) =>
        <div
          key={index}
          className={classNames('flex-row', { 'sortable': toggleSort })}
          data-testid={`${property}-header`}
          onClick={() => toggleSort?.(property)}>
          {sortProperty === property && getSortIcon(sortAscending)}
          <span>{t(columnLabels[index])}</span>
        </div>
      )}
      <div className="flex-row icons" />
    </div>
  );
};