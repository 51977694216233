import React from 'react';
import { useTranslation } from 'react-i18next';
import './dialogs.scss';
import { Button } from '@mtb/ui';

const ValidationDialog = ({
  header,
  description,
  submitButtonText,
  children,
  onSubmit,
  onCancel,
  onContinueWithoutSave }) => {
  const [t] = useTranslation();

  return (
    <div className="dialogContainer">
      <div className="modalDialog">
        <h3>{header}</h3>
        <div className="dialogContent">
          <p>{description}</p>
          {children}
        </div>
        <div className="buttons">
          <Button
            className='confirmButton'
            variant='contained'
            onClick={() => onSubmit() }>
            {submitButtonText ?? t('ok')}
          </Button>
          {onContinueWithoutSave &&
            <Button
              className='cancel'
              variant='contained'
              onClick={onContinueWithoutSave}>{t('continueWithoutSave')}</Button>}
          {onCancel &&
            <Button
              className='cancel'
              variant='contained'
              onClick={onCancel}>{t('cancel')}</Button> }
        </div>
      </div>
    </div>
  );
};

export default ValidationDialog;