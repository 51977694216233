/* eslint-disable max-len */
import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@mtb/ui';
import { DownloadIcon, RefreshIcon, CautionIcon, InfoIcon } from '../images';
import moment from 'moment-timezone';
import Deployments from '../api/deployments';
import { MILLISECONDS_ONE_HOUR } from '../utils/chart-utils';
import DateRangeSelector, { toUtc } from './DateRangeSelector';
import { formatDate, formatLocalDateTime } from '../utils/locales';
import { FlagsContext } from '../utils/context';
import { DefaultSettings, PeriodSettings } from '../utils/model-utils';
import { getErrorMessage } from '../utils/errors/download-errors';
import DownloadDataDialog from './DownloadDataDialog';
import ValidationDialog from './ValidationDialog';
import './header.scss';
import classNames from 'classnames';

const MAX_REPORT_RANGE_MONTHS = 6;

export const ReportPeriodSetting = {
  [PeriodSettings.Daily]  : 'daily',
  [PeriodSettings.Weekly] : 'weekly',
  [PeriodSettings.Monthly]: 'monthly'
};

export const getIntervalSize = period => {
  switch (period) {
    case PeriodSettings.Monthly:
      return MILLISECONDS_ONE_HOUR * 24 * 31;
    case PeriodSettings.Weekly:
      return MILLISECONDS_ONE_HOUR * 24 * 7;
    case PeriodSettings.Daily:
      return MILLISECONDS_ONE_HOUR * 24;
    default:
      return MILLISECONDS_ONE_HOUR * 24;
  }
};

const getDateValidationMessage = (t, flags, start, end, min, timezone = DefaultSettings.Timezone, adjOffset) => {
  if (!start || !end || !moment(start).isValid() || !moment(end).isValid()) {
    return t('validateValidReportDate');
  }

  if (start > end) {
    return t('validateMaxStartReportDate');
  }

  const today = moment.utc().subtract(Number(adjOffset), 'hours').format('YYYY-MM-DD');

  if (start > today || end > today) {
    return end <= moment.tz(timezone).format('YYYY-MM-DD') ? t('validateAvailableDates') : t('validateFutureReportDate');
  }

  const minDate = moment.utc(min).subtract(Number(adjOffset), 'hours').format('YYYY-MM-DD');
  if (start < minDate) {
    const helpLink = `${flags.helpURL}/monitor-deployed-models/report-periods-and-dates/`;
    const formattedDate = formatDate(toUtc(moment(minDate)));
    return <>
      {t('validateMinStartReportDate', { minDate: formattedDate })}
      <a
        href={helpLink}
        rel="noreferrer"
        target="_blank">
        {t('reportDatesAndProductionPeriods')}
      </a>
    </>;
  }

  const maxEnd = moment.tz(start, timezone).add(MAX_REPORT_RANGE_MONTHS, 'months').format('YYYY-MM-DD');
  if (end > maxEnd) {
    return t('validateMaxReportRange', { max: MAX_REPORT_RANGE_MONTHS });
  }
};

const mapPeriodSetting = period =>
  Object.keys(ReportPeriodSetting).find(key => ReportPeriodSetting[key] === period);

export const getLastReportSetting = key => {
  if (key && !key.includes('undefined')) {
    const dateIndex = 'YYYY-MM-DD'.length;
    return {
      start : key.slice(0, dateIndex),
      end   : key.slice(dateIndex + 1, dateIndex * 2 + 1),
      period: mapPeriodSetting(key.split('-').slice(-1)[0])
    };
  }
};

export const getDefaultReportDates = (flags, deployment, userDeploymentSettings) => {
  let start, end, period;

  if (flags.isr) {
    ({ reportStart: start, reportEnd: end, reportPeriod: period } = userDeploymentSettings);
  } else if (deployment.reportsettings) {
    ({ start, end, period } = getLastReportSetting(deployment.reportsettings));
  }

  if (!start || !end || !period) {
    // No settings found - use deployment creation date through today's date
    const adjOffset = deployment.adjusted_offset ?? DefaultSettings.AdjustedOffset;
    // Adjust today's date to account for SOD offset
    start = moment.utc(deployment.createdon).subtract(Number(adjOffset), 'hours').format('YYYY-MM-DD');
    end = moment.utc().subtract(Number(adjOffset), 'hours').format('YYYY-MM-DD');
    const earliestStartDate = moment.utc()
      .subtract(MAX_REPORT_RANGE_MONTHS, 'months')
      .subtract(Number(adjOffset), 'hours')
      .format('YYYY-MM-DD');
    if (start < earliestStartDate) {
      start = earliestStartDate;
    }
    if (!period) {
      period = PeriodSettings.Weekly;
    }
  }

  return { start, end, period: mapPeriodSetting(period) ?? period };
};

const ReportHeader = ({
  deployment,
  refreshReport,
  lastUpdated,
  reportSettings,
  hasReport,
  setData,
  showRefresh
}) => {
  const [t] = useTranslation();
  const flags = useContext(FlagsContext);
  const [errorMessage, setErrorMessage] = useState('');

  const [showDownloadReportDataDialog, setShowDownloadReportDataDialog] = useState(false);
  const [validationData, setValidationData] = useState({ header: '', description: '' });
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [timezone, setTimezone] = useState(deployment?.timezone ?? DefaultSettings.Timezone);

  useEffect(() => {
    setTimezone(deployment?.timezone ?? DefaultSettings.Timezone);
  }, [deployment.timezone]);

  const validateReportQuery = async (start, end) => {
    const { earliestlogged, createdon, timezone, adjusted_offset: adjOffset } = await Deployments.get(deployment.id);
    // Accept start dates prior to deployment creation date if there is backfilled data
    const minDate = earliestlogged && earliestlogged < createdon ? earliestlogged : createdon;
    const message = getDateValidationMessage(t, flags, formatDate(start), formatDate(end), minDate, timezone, adjOffset) ?? '';
    setErrorMessage(message);
    return !message;
  };

  const prepareDownloadReportData = async (id, downloadExtension) => {
    return await Deployments.prepareProductionDataDownload(id, downloadExtension,
      reportSettings.start, reportSettings.end);
  };

  const downloadReportData = async (id, downloadExtension) => {
    return await Deployments.downloadProductionData(id, downloadExtension,
      reportSettings.start, reportSettings.end);
  };

  const onDownloadError = (errorCode) => {
    const description = getErrorMessage(t, errorCode);
    setValidationData({ header: t('actionCantBeCompleted'), description });
    setShowValidationDialog(true);
  };

  const updatedDate = useMemo(() => {
    return t('updatedDate', { date: formatLocalDateTime(lastUpdated, timezone) });
  }, [t, lastUpdated, timezone]);

  const startOfDayLabel = useMemo(() => {
    const timezoneOffset = moment.tz(deployment?.timezone ?? DefaultSettings.Timezone).utcOffset() / 60;
    const offset = timezoneOffset + Number(deployment?.adjusted_offset || -timezoneOffset);
    const time = `${(24 + offset) % 24}:00`;
    const label = t(offset < 0 ? 'startOfDayLabelWithOffset' : 'startOfDayLabel', { time, offset: `${offset}:00` });
    return <div
      className='info'
      data-testid='sod'>
      {label}
      <Tooltip title={t(offset < 0 ? 'startOfDayInfoNegative' : 'startOfDayInfoPositive', { time })}>
        <InfoIcon className='icon' />
      </Tooltip>
    </div>;
  }, [deployment?.adjusted_offset, deployment?.timezone, t]);

  return (
    <div className="report-header">
      <div className="flex-header">
        <div>
          <div className='timezone-labels'>
            <div
              className='info'
              data-testid='timezone'>
              {t('timezoneLabel', { timezone: deployment.timezone || DefaultSettings.Timezone })}
            </div>
            {startOfDayLabel}
          </div>
          <DateRangeSelector
            customValidationCallback={validateReportQuery}
            reportSettings={reportSettings}
            resetValidation={() => setErrorMessage('')}
            setData={setData} />
        </div>
        <div className='button-container'>
          <div className='refresh-button-container'>
            { showRefresh !== undefined && hasReport &&
              <div
                className={classNames('report-header-status', showRefresh ? 'not-current' : 'current')}
                title={`${t(showRefresh ? 'reportIsNotCurrent' : 'reportIsCurrent')} ${lastUpdated ? updatedDate : ''}`}>
                {showRefresh && <CautionIcon />}
                <span>{t(showRefresh ? 'reportIsNotCurrent' : 'reportIsCurrent')}</span>
                {lastUpdated && updatedDate}
              </div>}
          </div>
          <div className='report-button-container'>
            <Button
              color='default'
              data-testid='refreshData'
              icon
              size='small'
              title={t('refreshData')}
              onClick={refreshReport}>
              <RefreshIcon />
            </Button>
            <Button
              color='default'
              icon
              size='small'
              title={t('downloadData')}
              onClick={() => setShowDownloadReportDataDialog(true)}>
              <DownloadIcon />
            </Button>
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className='validationMessage'>
          {errorMessage}
        </div>
      )}
      {showDownloadReportDataDialog &&
        <DownloadDataDialog
          busyHeader={t('downloadingProductionData')}
          closeDialog={() => setShowDownloadReportDataDialog(false)}
          download={downloadReportData}
          header={t('downloadData')}
          id={deployment.id}
          name={`${deployment.name} ${t('data')}`}
          prepareDownload={prepareDownloadReportData}
          onDownloadError={onDownloadError} />}
      {showValidationDialog &&
        <ValidationDialog
          description={validationData.description}
          header={validationData.header}
          onSubmit={() => setShowValidationDialog(false)} />}
    </div>
  );
};

export default ReportHeader;