import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './dialogs.scss';
import { serializeError } from 'serialize-error';
import Deployments from '../api/deployments';
import { ErrorsContext } from '../utils/context';

const RemoveModelDialog = ({ modelId, deploymentId, setShowRemoveModelDialog, setBusy, updateDeployment }) => {
  const [t] = useTranslation();
  const { onError } = useContext(ErrorsContext);

  const removeModel = async () => {
    try {
      setBusy(true);
      await Deployments.removeChallenger(deploymentId, modelId);
      await updateDeployment();
    } catch (e) {
      onError(serializeError(e));
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className="dialogContainer">
      <div className="modalDialog">
        <h3>{t('removeModel')}</h3>
        <p>{t('doYouWantToRemoveModel')}</p>
        <div className="buttons">
          <button
            className="cancelButton"
            onClick={() => {
              setShowRemoveModelDialog(false);
            }}>
            {t('cancel')}
          </button>
          <button
            className="confirmButton"
            onClick={async () => {
              await removeModel();
              setShowRemoveModelDialog(false);
            }}>
            {t('ok')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveModelDialog;