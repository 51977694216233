import { InputTypes } from '../components/Inputs';
import {
  getDatetimeTooltipFormat,
  getDateTimeLabelFormats,
  calculateBinInterval,
  calculateTimeSeriesYMax,
  getOverlaidCategoricalData,
  getOverlaidStabilityData,
  getHistogramSeries,
  getDriftTimeSeriesData,
  getTimeSeriesBinTooltip,
  getOffsetTickPositions,
  mssScrollbarOptions,
  onLegendItemClick,
  onResize,
  getChallengerTooltip,
  hidePromotionHighlights,
  getAllPromotionsByModelSeries,
  getPromotionPlotLines,
  forceShowXTickLabels,
  setXAxisExtremes
} from './chart-utils';
import { round } from './model-utils';
import Highcharts from 'highcharts';
import moment from 'moment-timezone';

const COLLAPSED_GRAPH_HEIGHT = 55;
const CATEGORICAL_BIN_INTERVAL = 1;
const MIN_TICK_INTERVAL = 3600 * 24 * 1000;

export const progressBarConfig = (t, storageConsumed, storageRemaining) => {
  return {
    chart: {
      type  : 'bar',
      height: 150,
      width : 400
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled : true,
      align   : 'left',
      reversed: true
    },
    tooltip: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        borderWidth : 0,
        borderRadius: 5
      },
      series: {
        stacking: 'normal'
      }
    },
    xAxis: {
      visible   : false,
      categories: ['']
    },
    yAxis: {
      visible: true,
      min    : 0,
      max    : 100,
      title  : {
        text: null
      },
      gridLineWidth: 0,
      labels       : {
        y: 0
      }
    },
    series: [
      {
        animation: false,
        name     : storageRemaining.name,
        data     : [storageRemaining.data],
        color    : 'grey',
        tooltip  : {
          valueSuffix: storageRemaining.suffix
        }
      },
      {
        animation: false,
        name     : storageConsumed.name,
        data     : [storageConsumed.data],
        color    : '#CC0E49', // cranberry
        tooltip  : {
          valueSuffix: storageConsumed.suffix
        },
        dataLabels: {
          enabled  : true,
          inside   : true,
          align    : 'right',
          formatter: function () {
            const storageAmount = `${Highcharts.numberFormat(this.y, -1)}${storageConsumed.suffix}`;
            return t('serviceMetricsStorage', { value: storageAmount });
          },
          style: {
            color      : 'white',
            textOutline: false,
          }
        }
      }
    ]
  };
};

export const timeSeriesExpandedConfig = (t, series, psiThresholds, promotions, tzInfo, navigateOnClick) => {
  const allPromotions = getPromotionPlotLines(t, promotions, series.data.time_values?.[0]?.start);
  const displayDateRange = typeof series.data.time_values?.[0] === 'object';
  let navigatorXMin, navigatorXMax;
  let needsResize;

  return {
    chart: {
      animation : false,
      zoomType  : 'x',
      height    : 315,
      alignTicks: false,
      events    : {
        load() {
          needsResize = true;
          this.container.onmouseleave = () => hidePromotionHighlights(this);
          ({ min: navigatorXMin, max: navigatorXMax } = this.xAxis[0].getExtremes());
        },
        redraw() {
          // On first rerender with new data, update navigator min/max
          if (needsResize) {
            needsResize = false;
            this.xAxis[0].setExtremes(navigatorXMin, navigatorXMax);
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
          states : {
            hover: {
              fillColor: '#fff',
              lineColor: '#7ba4d7',
              lineWidth: 2
            }
          }
        },
        events: {
          click: event => navigateOnClick?.(event.point.index)
        },
        enableMouseTracking: true,
      },
      series: {
        stickyTracking: false,
      }
    },
    navigator: {
      enabled: true,
      xAxis  : {
        dateTimeLabelFormats: getDateTimeLabelFormats(),
        tickPositioner      : function () {
          return getOffsetTickPositions(this.tickPositions, tzInfo);
        }
      },
    },
    xAxis: {
      type     : 'datetime',
      plotLines: [...allPromotions],
      title    : {
        text: t('date')
      },
      dateTimeLabelFormats: getDateTimeLabelFormats(),
      tickPositioner      : function () {
        return getOffsetTickPositions(this.tickPositions, tzInfo);
      },
      minTickInterval: MIN_TICK_INTERVAL
    },
    yAxis: {
      max          : calculateTimeSeriesYMax(series.data.psi_values, psiThresholds),
      min          : 0,
      gridLineWidth: 0,
      title        : {
        text: series.name
      },
      labels: {
        formatter: function () {
          return Highcharts.numberFormat(round(this.value, 3), -1);
        }
      },
      plotLines: [
        {
          color: '#ffcc80',
          width: 2,
          value: psiThresholds.moderate
        },
        {
          color: '#f4483c',
          width: 2,
          value: psiThresholds.severe
        },
      ]
    },
    tooltip: {
      enabled      : true,
      valueDecimals: 3,
      xDateFormat  : getDatetimeTooltipFormat(),
      ...(displayDateRange && {
        formatter: function() {
          return getTimeSeriesBinTooltip(this, series.data.time_values, tzInfo.timezone);
        }
      }),
      style: {
        fontSize: '10px',
        width   : 'auto'
      },
    },
    series: [
      {
        animation: false,
        type     : 'line',
        color    : '#6497c9',
        name     : series.name,
        data     : getDriftTimeSeriesData(series.data)
      }
    ],
    time: {
      getTimezoneOffset: function(ts) {
        return -moment.tz(ts, tzInfo.timezone).utcOffset();
      }
    }
  };
};

export const timeSeriesCollapsedConfig = (t, series, psiThresholds, promotions, timezone, navigateOnClick) => {
  const displayDateRange = typeof series.data.time_values?.[0] === 'object';
  const allPromotions = getPromotionPlotLines(t, promotions, series.data.time_values?.[0]?.start);
  return {
    chart: {
      animation      : false,
      height         : COLLAPSED_GRAPH_HEIGHT - 5,
      alignTicks     : false,
      backgroundColor: null,
      events         : {
        load() {
          this.container.onmouseleave = () => hidePromotionHighlights(this);
        }
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
          states : {
            hover: {
              fillColor: '#fff',
              lineColor: '#7ba4d7',
              lineWidth: 2
            }
          }
        },
        events: {
          click: event => navigateOnClick?.(event.point.index)
        },
        enableMouseTracking: true,
      },
      series: {
        lineWidth     : 2,
        stickyTracking: false,
      }
    },
    xAxis: {
      type  : 'datetime',
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      tickWidth: 0,
      plotLines: [...allPromotions]
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        enabled: false
      },
      max      : calculateTimeSeriesYMax(series.data.psi_values, psiThresholds),
      min      : 0,
      plotLines: [
        {
          color: '#ffcc80',
          width: 1,
          value: psiThresholds.moderate
        },
        {
          color: '#f4483c',
          width: 1,
          value: psiThresholds.severe
        },
      ]
    },
    tooltip: {
      enabled      : true,
      valueDecimals: 3,
      outside      : true,
      shape        : 'square',
      xDateFormat  : getDatetimeTooltipFormat(),
      ...(displayDateRange && {
        formatter: function() {
          return getTimeSeriesBinTooltip(this, series.data.time_values, timezone);
        }
      }),
      style: {
        fontSize: '10px',
        width   : 'auto'
      }
    },
    series: [
      {
        animation: false,
        type     : 'line',
        color    : '#6497c9',
        name     : series.name,
        data     : getDriftTimeSeriesData(series.data)
      }
    ],
    time: {
      ...(timezone && { getTimezoneOffset: function(ts) {
        return -moment.tz(ts, timezone).utcOffset();
      } })
    }
  };
};

export const histogramExpandedConfig = (t, series1, series2, xLabel) => {
  const series1BinInterval = calculateBinInterval(series1.data.bin_locations, series1.data.values.length);
  const series2BinInterval = calculateBinInterval(series2.data.bin_locations, series2.data.values.length);
  const blankSeries = series1.data.values.length + series2.data.values.length === 0;
  return {
    chart: {
      animation: false,
      type     : 'column',
      zoomType : 'x',
      height   : 300
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled     : true,
      layout      : 'horizontal',
      align       : 'left',
      symbolRadius: 0,
      margin      : 0,
      title       : {
        ...(!blankSeries && { text: t('data') }),
        style: {
          fontWeight: 'normal'
        }
      },
      itemStyle: {
        fontWeight: 'normal'
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        groupPadding: 0,
        grouping    : false,
      },
      series: {
        inactive: {
          enabled: true,
          opacity: 0.5,
        },
        pointPlacement: 'between',
        minPointLength: 1
      }
    },
    yAxis: {
      lineWidth : 1,
      min       : 0,
      alignTicks: false,
      title     : {
        text: t('percent')
      },
      labels: {
        formatter: function () {
          return t('percentSymbol', { value: Highcharts.numberFormat(this.value, -1) });
        }
      }
    },
    xAxis: {
      title: {
        text: xLabel
      },
    },
    tooltip: {
      enabled  : true,
      useHTML  : true,
      formatter: function() {
        const interval = this.series.name === series1.name ? series1BinInterval : series2BinInterval;
        const binValues = [this.x, this.x + interval].map(x => Highcharts.numberFormat(round(x, 1), -1));
        const bins = `[${binValues.join(`${Highcharts.defaultOptions.lang.listSeparator} `)}]`;
        let tooltip = `<span style="font-size: 11px">${t('equal', { value1: `<b>${this.series.name}</b>`, value2: bins })}</span>`;
        tooltip += '<br>';
        tooltip += `<span style="font-size: 18px; color: ${this.series.color}">● </span>`;
        tooltip += t('percentAmount', { amount: Highcharts.numberFormat(round(this.y, 3), -1) });
        return tooltip;
      }
    },
    series: getHistogramSeries(series1, series2, series1BinInterval, series2BinInterval)
  };
};

const histogramCollapsedConfig = (t, series) => {
  const seriesData = series.data.bin_locations.map((bin, i) => [bin, series.data.values[i] * 100]);
  const binInterval = calculateBinInterval(series.data.bin_locations, series.data.values.length);

  let needsResize = true;
  const resize = chart => {
    needsResize = false;
    chart.xAxis[0].setExtremes(series.xMin, series.xMax, true, false);
    chart.yAxis[0].setExtremes(0, series.yMax, true, false);
  };

  return {
    chart: {
      animation      : false,
      type           : 'column',
      height         : COLLAPSED_GRAPH_HEIGHT,
      backgroundColor: null,
      events         : {
        load: function() {
          resize(this);
        },
        redraw: function() {
          needsResize && resize(this);
        }
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        groupPadding: 0,
        grouping    : false,
      },
      series: {
        inactive: {
          enabled: true,
          opacity: 0.5,
        },
      }
    },
    yAxis: {
      lineWidth: 0,
      title    : {
        text: ''
      },
      visible: false
    },
    xAxis: {
      title: {
        text: ''
      },
      visible: false
    },
    tooltip: {
      valueDecimals: 3,
      outside      : true,
      formatter    : function() {
        const binValues = [this.x, this.x + binInterval].map(x => Highcharts.numberFormat(round(x, 1), -1));
        const bins = `[${binValues.join(`${Highcharts.defaultOptions.lang.listSeparator} `)}]`;
        let tooltip = `${t('equal', { value1: `<b>${this.series.name}</b>`, value2: bins })}<br>`;
        tooltip += t('percentSymbol', { value: Highcharts.numberFormat(round(this.y, 3), -1) });
        return tooltip;
      },
      style: {
        fontSize: '10px',
      }
    },
    series: [
      {
        animation : false,
        type      : 'column',
        name      : series.name,
        data      : seriesData,
        pointRange: binInterval
      }
    ]
  };
};

export const histogramBaselineConfig = (t, series) => {
  const collapsedConfig = histogramCollapsedConfig(t, series);
  collapsedConfig.series[0].color = '#7ba4d7';
  return collapsedConfig;
};

export const histogramProductionConfig = (t, series) => {
  const collapsedConfig = histogramCollapsedConfig(t, series);
  collapsedConfig.series[0].color = '#c43d39';
  return collapsedConfig;
};

export const barchartExpandedConfig = (t, series1, series2, variableName, dataType) => {
  const { levels, series1Data, series2Data } = getOverlaidCategoricalData(series1.data, series2.data);
  const blankSeries = series1.data.values.length + series2.data.values.length === 0;
  return {
    chart: {
      animation: false,
      type     : 'column',
      zoomType : 'x',
      height   : 300,
      events   : {
        endResize: function () {
          onResize(this, levels.length);
        },
        load: function() {
          onResize(this, levels.length);
        }
      }
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled     : !blankSeries,
      layout      : 'horizontal',
      align       : 'left',
      symbolRadius: 0,
      margin      : 0,
      title       : {
        text : t('data'),
        style: {
          fontWeight: 'normal'
        }
      },
      itemStyle: {
        fontWeight: 'normal'
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0,
      },
      series: {
        events: {
          legendItemClick: function (e) {
            onLegendItemClick(e, this);
          }
        }
      }
    },
    yAxis: {
      lineWidth: !blankSeries && 1,
      max      : Math.max(...series1Data, ...series2Data),
      title    : {
        text: !blankSeries && t('percent')
      },
      labels: {
        formatter: function () {
          return t('percentSymbol', { value: Highcharts.numberFormat(this.value, -1) });
        }
      }
    },
    xAxis: {
      lineWidth: !blankSeries && 1,
      title    : {
        text: !blankSeries && variableName,
      },
      labels: {
        style: {
          fontWeight: 'bold'
        },
        formatter: function () {
          return dataType === InputTypes.Numeric ? `${Highcharts.numberFormat(this.value, -1)}` : this.value;
        }
      },
      scrollbar : mssScrollbarOptions,
      categories: levels
    },
    tooltip: {
      enabled  : true,
      useHTML  : true,
      formatter: function() {
        let tooltip = '<span style="font-size: 11px">';
        tooltip += `${t('equal', { value1: `<b>${t('data')}</b>`, value2: this.series.name })}<br>`;
        tooltip += `${t('equal', { value1: `<b>${variableName}</b>`, value2: (dataType === InputTypes.Numeric ? Highcharts.numberFormat(this.x, -1) : this.x) })}</span><br>`;
        tooltip += `<span style="font-size: 18px; color: ${this.series.color}">● </span>`;
        tooltip += t('equal', { value1: this.series.name, value2: t('percentSymbol', { value: Highcharts.numberFormat(round(this.y, 3), -1) }) });
        return tooltip;
      }
    },
    series: [
      {
        animation   : false,
        type        : 'column',
        color       : '#7da7d9',
        name        : series1.name,
        data        : series1Data,
        showInLegend: series1.data.values.length
      },
      {
        animation   : false,
        type        : 'column',
        color       : '#c74542',
        name        : series2.name,
        data        : series2Data,
        showInLegend: series2.data.values.length
      }
    ]
  };
};

const barchartCollapsedConfig = (t, series, variableName, dataType) => {
  const seriesData = series.categories?.map(name => {
    const binIndex = series.data?.bin_locations?.indexOf(name);
    const value = binIndex === -1 ? 0 : series.data.values[binIndex];
    return { y: value * 100, name };
  });

  let needsResize = true;
  const resize = chart => {
    needsResize = false;
    chart.yAxis[0].setExtremes(0, series.yMax, true, false);
  };

  return {
    chart: {
      animation      : false,
      type           : 'column',
      height         : COLLAPSED_GRAPH_HEIGHT,
      backgroundColor: null,
      events         : {
        load: function() {
          resize(this);
        },
        redraw: function() {
          needsResize && resize(this);
        }
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        groupPadding: 0
      },
    },
    yAxis: {
      lineWidth: 0,
      title    : {
        text: ''
      },
      visible: false
    },
    xAxis: {
      title: {
        text: ''
      },
      visible   : false,
      categories: series.categories,
      max       : series.categories.length
    },
    tooltip: {
      valueDecimals: 3,
      outside      : true,
      formatter    : function() {
        let tooltip = `${t('equal', { value1: `<b>${variableName}</b>`, value2: (dataType === InputTypes.Numeric ? Highcharts.numberFormat(this.x, -1) : this.x) })}<br>`;
        tooltip += `<span style="color: ${this.series.color}">● </span>`;
        tooltip += t('equal', { value1: this.series.name, value2: t('percentSymbol', { value: Highcharts.numberFormat(round(this.y, 3), -1) }) });
        return tooltip;
      },
      style: {
        fontSize: '10px',
      }
    },
    series: [
      {
        animation : false,
        type      : 'column',
        name      : series.name,
        data      : seriesData,
        pointRange: CATEGORICAL_BIN_INTERVAL
      }
    ]
  };
};

export const barchartBaselineConfig = (t, series, variableName, dataType) => {
  const collapsedConfig = barchartCollapsedConfig(t, series, variableName, dataType);
  collapsedConfig.series[0].color = '#7da7d9';
  return collapsedConfig;
};

export const barchartProductionConfig = (t, series, variableName, dataType) => {
  const collapsedConfig = barchartCollapsedConfig(t, series, variableName, dataType);
  collapsedConfig.series[0].color = '#c74542';
  return collapsedConfig;
};

export const serviceHealthConfig = (t, series) => {
  return {
    chart: {
      animation: false,
      type     : 'column',
      height   : 225
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: t('count')
      }
    },
    xAxis: {
      title: {
        text: series.name
      },
      categories: series.data.map(i => i.name),
      visible   : true
    },
    tooltip: {
      enabled  : true,
      formatter: function() {
        return `${t('serviceMetricsStatus', { status: this.x })}<br/>${t('serviceMetricsCount', { count: this.y })}`;
      },
    },
    series: [{
      animation: false,
      data     : series.data.map(i => i.data)
    }]
  };
};

export const responseTimeConfig = (t, series) => {
  return {
    chart: {
      animation: false,
      type     : 'line',
      zoomType : 'x',
      height   : 225
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    navigator: {
      enabled: true,
      xAxis  : {
        dateTimeLabelFormats: getDateTimeLabelFormats()
      }
    },
    xAxis: {
      type     : 'datetime',
      crosshair: {
        color    : '#cccccc',
        dashStyle: 'Solid',
        snap     : false
      },
      title: {
        text: t('date')
      },
      dateTimeLabelFormats: getDateTimeLabelFormats()
    },
    yAxis: {
      title: {
        text: t('timeMs')
      },
      labels: {
        formatter: function () {
          return Highcharts.numberFormat(round(this.value, 2), -1);
        }
      }
    },
    tooltip: {
      xDateFormat: getDatetimeTooltipFormat(),
    },
    plotOptions: {
      series: {
        inactive: {
          enabled: true,
          opacity: 0.5,
        },
        showInNavigator: true
      }
    },
    series: [
      {
        animation   : false,
        type        : 'spline',
        color       : '#f59611',
        connectNulls: true,
        name        : series.name,
        data        : series.data.map(s => [Date.parse(s.time), s.value])
      }
    ]
  };
};

export const timeSeriesOverlaidStabilityConfig = (
  t, seriesData, gapSize, promotions, categorical, tzInfo, xMin, xMax, updateXAxisRange) => {

  const NAVIGATOR_HEIGHT = 32;

  const series = seriesData.map(series => {
    return {
      animation      : false,
      type           : 'line',
      color          : series.color,
      name           : series.name,
      legendIndex    : series.legendIndex,
      data           : getOverlaidStabilityData(series.data, series.color, series.promotionRanges, gapSize),
      showInNavigator: true,
      gapSize,
      gapUnit        : 'value',
      bins           : series.data.time_ranges,
    };
  });

  const allPromotions = getAllPromotionsByModelSeries(t, promotions, series);
  const disableTurboThreshold = series.reduce((size, series) => series.data.length + size, 0) >= 1000;
  let navigatorXMin, navigatorXMax;
  let needsResize = true;

  return {
    chart: {
      animation      : false,
      alignTicks     : false,
      backgroundColor: null,
      events         : {
        load: function() {
          const chart = this;
          chart.container.onmouseleave = () => hidePromotionHighlights(chart);
          setXAxisExtremes(chart, series);
          forceShowXTickLabels(chart, series);
          ({ min: navigatorXMin, max: navigatorXMax } = chart.xAxis[0].getExtremes());
          chart.isDirtyBox = true;
          chart.redraw();
        },
        redraw: function() {
          // On first rerender with new data, update navigator min/max.
          // If available, use user report settings, else use full navigator range.
          if (needsResize) {
            needsResize = false;
            this.xAxis[0].setExtremes(xMin || navigatorXMin, xMax || navigatorXMax);
          } else {
            // Save user report settings for navigator min/max on change
            const { min, max } = this.xAxis[0].getExtremes();
            if (min !== navigatorXMin || max !== navigatorXMax) {
              updateXAxisRange(Math.ceil(min), Math.floor(max));
              navigatorXMin = min;
              navigatorXMax = max;
            }
          }
        }
      }
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    navigator: {
      enabled: true,
      height : NAVIGATOR_HEIGHT,
      series : {
        fillOpacity: 0,
        marker     : {
          enabled: false,
          radius : 0
        },
      },
      xAxis: {
        dateTimeLabelFormats: getDateTimeLabelFormats(),
        tickPositioner      : function() {
          if (this.tickPositions.length === 1) {
            return this.tickPositions;
          }
          return getOffsetTickPositions(this.tickPositions, tzInfo);
        },
        minTickInterval: MIN_TICK_INTERVAL
      }
    },
    legend: {
      enabled     : true,
      layout      : 'horizontal',
      symbolHeight: 6,
      symbolWidth : 6
    },
    plotOptions: {
      line: {
        enableMouseTracking: true,
        threshold          : null,
        marker             : {
          enabled: true,
          symbol : 'circle',
          radius : 4
        },
      },
      series: {
        lineWidth     : 1,
        stickyTracking: false,
        ...(disableTurboThreshold && { turboThreshold: 0 })
      }
    },
    xAxis: {
      type                : 'datetime',
      plotLines           : allPromotions,
      startOnTick         : false,
      dateTimeLabelFormats: getDateTimeLabelFormats(),
      tickPositioner      : function() {
        if (this.tickPositions.length === 1) {
          return this.tickPositions;
        }
        return getOffsetTickPositions(this.tickPositions, tzInfo);
      },
      minTickInterval: MIN_TICK_INTERVAL,
    },
    yAxis: {
      title: {
        text: ''
      },
      lineWidth    : 1,
      gridLineWidth: 0,
      startOnTick  : false,
      ...(categorical && { ceiling: 1 })
    },
    tooltip: {
      enabled      : true,
      valueDecimals: 3,
      outside      : true,
      formatter    : function() {
        if (this.series.data.indexOf(this.point) === -1) {
          return this.key; // Promotion tooltip
        }
        return getChallengerTooltip(this.series.userOptions.bins, this, tzInfo);
      },
      style: {
        fontSize: '10px'
      }
    },
    time: {
      getTimezoneOffset: ts => {
        return -moment.tz(ts, tzInfo.timezone).utcOffset();
      }
    },
    series
  };
};