import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { Tooltip } from '@mtb/ui';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { serializeError } from 'serialize-error';
import CreateDeployment from './CreateDeployment';
import { FlexTableHeader } from './FlexTable';
import LoadingWrapper from './LoadingWrapper';
import Deployments from '../api/deployments';
import BaselineDataNotCurrentDialog from './BaselineDataNotCurrentDialog';
import Confirm from './confirm';
import AppResources from '../resources/app';
import { Scopes, requireScope } from '../utils/scopes';
import './grids.scss';
import './flex-table.scss';
import {
  AddEndpointIcon,
  DeployIcon,
  OvalIcon,
  PauseIcon,
  RemoveIcon,
} from '../images';
import { toLocalizedArray } from '../utils/locales';
import { modelTypeMap } from '../utils/model-file-utils';
import { convertToUTC, sortHelper, getDriftStatusIcon, getOverallDriftTooltip } from '../utils/model-utils';
import classNames from 'classnames';
import { ErrorsContext, FlagsContext, ScopeContext, SettingsContext } from '../utils/context';
import { SessionContext, SessionStatus } from './SessionWrapper';
import { debounce } from '../api/api-utils';
import Settings, { getDefaultUserAppSettings } from '../api/settings';
import Models from '../api/models';

export const Statuses = {
  Creating     : 'creating',
  Deleting     : 'deleting',
  Deployed     : 'deployed',
  Destroying   : 'destroying',
  Failed       : 'failed',
  Pending      : 'pending',
  Published    : 'published',
  DeployQueued : 'deployQueued',
  DestroyQueued: 'destroyQueued'
};

export const StatusText = {
  [Statuses.Creating]     : 'deploymentStatusPending',
  [Statuses.Deleting]     : 'deploymentStatusDeleting',
  [Statuses.Deployed]     : 'deploymentStatusActive',
  [Statuses.Destroying]   : 'deploymentStatusPending',
  [Statuses.Failed]       : 'deploymentStatusFailed',
  [Statuses.Pending]      : 'deploymentStatusPending',
  [Statuses.Published]    : 'deploymentStatusOffline',
  [Statuses.DeployQueued] : 'deploymentStatusPending',
  [Statuses.DestroyQueued]: 'deploymentStatusPending',
};

const IconClasses = {
  [Statuses.Creating]     : <OvalIcon className="creating" />,
  [Statuses.Deleting]     : <OvalIcon className="deleting" />,
  [Statuses.Deployed]     : <OvalIcon className="deployed" />,
  [Statuses.Destroying]   : <OvalIcon className="destroying" />,
  [Statuses.Failed]       : <OvalIcon className="failed" />,
  [Statuses.Pending]      : <OvalIcon className="pending" />,
  [Statuses.Published]    : <OvalIcon className="published" />,
  [Statuses.DeployQueued] : <OvalIcon className="queued" />,
  [Statuses.DestroyQueued]: <OvalIcon className="queued" />,
};
const mapStatusToIcon = status => IconClasses[status] ?? IconClasses[Statuses.Failed];

const isDeploymentActivating = deployment =>
  [Statuses.DeployQueued, Statuses.Pending, Statuses.Creating].includes(deployment.status) &&
  deployment.active !== 'true';

const TextWithTooltip = ({ text }) => {
  return (
    <Tooltip
      disableFocusListener={!text}
      disableHoverListener={!text}
      title={text ?? ''}>
      <span className='ellipses'>{text}</span>
    </Tooltip>
  );
};

const DeploymentsPage = ({ setBusy }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { onError } = useContext(ErrorsContext);
  const flags = useContext(FlagsContext);
  const scope = useContext(ScopeContext);
  const settings = useContext(SettingsContext);
  const session = useContext(SessionContext);

  const [dialog, setDialog] = useState();
  const [creatingDeployment, setCreatingDeployment] = useState(false);
  const [sortAscending, setSortAscending] = useState();
  const [sortProperty, setSortProperty] = useState();
  const [disableNewDeployments, setDisableNewDeployments] = useState();

  const getSeverity = useCallback(deployment => t(deployment.severity ?? 'noStatus'), [t]);

  const { data: deployments, refetch: reloadDeployments } = useQuery(['deployments'], async () => (await Deployments.getAll()).data, {
    onError: error => {
      onError(serializeError(error));
    },
    refetchInterval            : 5000, // 5 seconds
    refetchIntervalInBackground: true,
    enabled                    : session?.sessionStatus !== SessionStatus.Expired
  });

  const { totalRows, totalDeployed, totalInactive, totalFailed, totalPending } = useMemo(() => {
    if (deployments) {
      return {
        totalRows    : deployments.length,
        totalDeployed: (deployments.filter(d => d.status === Statuses.Deployed).length),
        totalInactive: deployments.filter(d => d.status === Statuses.Published).length,
        totalFailed  : deployments.filter(d => d.status === Statuses.Failed).length,
        totalPending : deployments.filter(
          d => (d.status === Statuses.Pending ||
            d.status === Statuses.DeployQueued ||
            d.status === Statuses.DestroyQueued)).length
      };
    }
    return { totalRows: 0, totalDeployed: 0, totalInactive: 0, totalFailed: 0, totalPending: 0 };
  }, [deployments]);

  const { data: models, refetch: reloadModels } = useQuery(['models'], async () => (await Models.getAll()).data, {
    onError                    : error => onError(serializeError(error)),
    refetchInterval            : 5000, // 5 seconds
    refetchIntervalInBackground: true,
    enabled                    : session?.sessionStatus !== SessionStatus.Expired
  });

  useEffect(() => {
    document.title = `${AppResources.ApplicationName} - ${t('deployments')}`;
  });

  useEffect(() => {
    const max_deployed_models = settings?.max_deployed_models;
    setDisableNewDeployments(totalDeployed + totalPending >= parseInt(max_deployed_models));
  }, [totalDeployed, totalPending, settings]);

  useEffect(() => {
    if (flags.isr) {
      let current = true;
      (async() => {
        const userAppSettings = await Settings.getUserApplicationSettings();
        if (current) {
          setSortAscending(userAppSettings.deploymentsSortAscending);
          setSortProperty(userAppSettings.deploymentsSortProperty);
        }
      })();

      return () => current = false;
    }
    setSortAscending(getDefaultUserAppSettings('deploymentsSortAscending'));
    setSortProperty(getDefaultUserAppSettings('deploymentsSortProperty'));
  }, [flags]);

  const getModelName = useCallback(deployment => {
    return models?.find(model => model.id === deployment.champion)?.name;
  }, [models]);

  const getSortFunc = useCallback(() => {
    if (deployments?.filter(deployment => deployment[sortProperty] !== undefined).length) {
      switch (sortProperty) {
        case 'createdon':
          return (a, b) => sortHelper(new Date(a[sortProperty]), new Date(b[sortProperty]), sortAscending);
        case 'type':
          return (a, b) => sortHelper(modelTypeMap[a.type], modelTypeMap[b.type], sortAscending);
        case 'champion':
          return (a, b) => sortHelper(getModelName(a), getModelName(b), sortAscending);
        case 'severity':
          const sortOrder = { 'severe': 0, 'moderate': 1, 'minimal': 3, undefined: 4 };
          return (a, b) => sortHelper(sortOrder[a.severity], sortOrder[b.severity], sortAscending);
        default:
          return (a, b) => sortHelper(a[sortProperty], b[sortProperty], sortAscending);
      }
    }
    return () => 0;
  }, [deployments, sortProperty, sortAscending, getModelName]);

  const debouncePatch = useMemo(() =>
    debounce(async (settings) => await Settings.patchUserApplicationSettings(settings), 400),
  []);

  const toggleSort = useCallback(property => {
    const newOrder = sortProperty === property ? !sortAscending : true;
    setSortAscending(newOrder);
    setSortProperty(property);
    flags.isr && debouncePatch({ deploymentsSortAscending: newOrder, deploymentsSortProperty: property });
  }, [flags, sortProperty, sortAscending, debouncePatch]);

  const tryExecute = async action => {
    try {
      setDialog();
      setBusy(true);
      await action();
    } catch (err) {
      onError(serializeError(err));
    } finally {
      await reloadDeployments();
      setBusy(false);
    }
  };

  const pause = deploymentId => tryExecute(async () => await Deployments.pause(deploymentId));

  const resume = deploymentId => tryExecute(async () => await Deployments.resume(deploymentId));

  const remove = deploymentId => tryExecute(async () => await Deployments.delete(deploymentId));

  const showDialog = async (action, confirmationText, title) => {
    setDialog(<Confirm
      cancel={() => setDialog()}
      confirm={action}
      confirmationText={confirmationText}
      t={t}
      title={title} />);
  };

  const showPauseDeploymentDialog = async id => {
    showDialog(async () => await pause(id), 'confirmPause', 'pauseDeployment');
  };

  const showRemoveDeploymentDialog = async id => {
    showDialog(async () => await remove(id), 'confirmDeleteDeployment', 'deleteDeployment');
  };

  // Adds a new deployment with an initial model chosen as the champion.
  const createDeployment = async metadata => {
    try {
      setBusy(true);
      const { id } = await Deployments.create(metadata);
      await reloadDeployments();
      await reloadModels();
      return id;
    } catch (err) {
      onError(serializeError(err));
    } finally {
      setBusy(false);
    }
  };

  const navigateToDeployment = id => {
    if (id) {
      history.push(`/dashboard/deployments/${id}`);
    }
  };

  const onCreate = async (metadata, showBaselineDataNotCurrent) => {
    const id = await createDeployment(metadata);
    setCreatingDeployment(false);
    if (showBaselineDataNotCurrent) {
      setDialog(<BaselineDataNotCurrentDialog
        onSubmit={() => {
          setDialog();
          navigateToDeployment(id);
        }} />);
    } else if (id) {
      navigateToDeployment(id);
    }
    await reloadModels();
    await reloadDeployments();
  };

  return <>
    <h3>{t('deployments')}</h3>
    <div className="toolbar">
      {requireScope(scope, Scopes.Deployments) && (
        <div onClick={() => setCreatingDeployment(true)}>
          <AddEndpointIcon />
          {t('newDeployment')}
        </div>
      )}
      <div className="gridStats not-clickable">
        <b>{t('deploymentCounts', { totalRows })}</b>
        {toLocalizedArray(
          t('deploymentCountsInfo', { totalRows, totalDeployed, totalInactive, totalFailed }),
          settings?.locale?.regionCode)}
      </div>
    </div>

    <div className="table-container">
      <LoadingWrapper
        caption={t('loadingCurrentDeployments')}
        isLoading={!deployments || !models || sortProperty === undefined} />
      {deployments?.length === 0 && <p>{t('noDeploymentsPublished')}</p>}
      {deployments?.length > 0 && (
        <FlexTableHeader
          columns={{
            name         : 'name',
            champion     : 'championModel',
            status       : 'status',
            severity     : 'overallDrift',
            createdon    : 'created',
            createdbyname: 'createdBy'
          }}
          sortAscending={sortAscending}
          sortProperty={sortProperty}
          t={t}
          toggleSort={toggleSort} />
      )}
      {deployments
        ?.toSorted(getSortFunc())
        ?.map((deployment, id) => (
          <div
            key={id}
            className="flex-table row">
            <div
              className="flex-cell link"
              onClick={() => navigateToDeployment(deployment.id)}>
              <TextWithTooltip text={deployment.name} />
            </div>
            <div className="flex-cell">
              <TextWithTooltip text={getModelName(deployment)} />
            </div>
            <div className="flex-cell small">
              <div className="status">
                {mapStatusToIcon(deployment.status)}
                <TextWithTooltip text={t(StatusText[deployment.status] ?? 'unknown')} />
              </div>
            </div>
            <div className="flex-cell small">
              <Tooltip
                title={getOverallDriftTooltip(t, deployment, true)}>
                <div className='overall-drift'>
                  {getDriftStatusIcon(deployment.severity)}
                  {getSeverity(deployment)}
                </div>
              </Tooltip>
            </div>
            <div
              className="flex-cell"
              id="createdon">
              <TextWithTooltip text={convertToUTC(deployment.createdon, true)} />
            </div>
            <div className="flex-cell">
              <TextWithTooltip text={deployment.createdbyname} />
            </div>
            <div className="flex-cell icons">
              {isDeploymentActivating(deployment) ? <>
                <LoadingWrapper
                  className='centered small-icon pending'
                  inline />
                <TextWithTooltip text={t('activatingDeploymentAction')} />
              </> : <>
                {requireScope(scope, [Scopes.Deployments, Scopes.Deploy]) &&
              <div className="actionLink">
                {[Statuses.Deployed, Statuses.Failed].includes(deployment.status) &&
                  requireScope(scope, Scopes.Deploy) &&
                  <div
                    className="actionLinkItem"
                    title={t('pause')}
                    onClick={async () => await showPauseDeploymentDialog(deployment.id)}>
                    <PauseIcon />
                  </div>
                }
                {deployment.status === Statuses.Published &&
                  requireScope(scope, Scopes.Deploy) &&
                  <div
                    className="actionLinkItem"
                    title={disableNewDeployments ? t('maxDeployments') : t('activate')}
                    onClick={disableNewDeployments ? undefined : (async () => await resume(deployment.id))}>
                    <DeployIcon className={classNames({ disabled: disableNewDeployments })} />
                  </div>
                }
                {deployment.status === Statuses.Published &&
                  requireScope(scope, Scopes.Deployments) &&
                  <div
                    className="actionLinkItem"
                    title={t('delete')}
                    onClick={() => showRemoveDeploymentDialog(deployment.id)}>
                    <RemoveIcon />
                  </div>
                }
              </div>}
              </>}
            </div>
          </div>
        ))}
    </div>
    {dialog}
    {creatingDeployment && (
      <CreateDeployment
        deployments={deployments}
        models={models}
        reloadModels={reloadModels}
        onCancel={() => setCreatingDeployment(false)}
        onCreate={onCreate} />
    )}
  </>;
};

export default DeploymentsPage;
