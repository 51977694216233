export const ERROR_CODES = {
  General         : 'Error',
  NoData          : 'NoData',
  ConversionFailed: 'ConversionFailed',
  OutOfMemory     : 'OutOfMemory'
};

export const getErrorMessage = (t, errorCode) => {
  switch (errorCode) {
    case ERROR_CODES.NoData :
      return t('noScorelogData');
    case ERROR_CODES.ConversionFailed:
      return t('downloadFailedDataSet');
    case ERROR_CODES.OutOfMemory:
      return t('outOfMemory');
    default:
      return t('issueWithDownload');
  }
};