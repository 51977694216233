import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './dialogs.scss';
import { formatLocalDateTime } from '../utils/locales';

const ModelInformationDialog = ({ model, setShowModelInformationDialog, timezone }) => {
  const [t] = useTranslation();

  const createdOn = useMemo(() => {
    return formatLocalDateTime(model.createdon, timezone);
  }, [model.createdon, timezone]);

  return (
    <div className="dialogContainer">
      <div className="modalDialog modelInformation">
        <h3>{t('modelInformationHeader')}</h3>
        <p>{t('modelNameFormattedLabel', { name: model.name })}</p>
        <p>{t('modelTypeFormattedLabel', { type: t(model.type) })}</p>
        <p>{t('createdByFormattedLabel', { name: model.createdbyname })}</p>
        <p data-testid='modelCreated'>{t('createdFormattedLabel', { datetime: createdOn })}</p>
        <div className="buttons">
          <button
            className="cancelButton"
            onClick={() => {
              setShowModelInformationDialog(false);
            }}>
            {t('close')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModelInformationDialog;