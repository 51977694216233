import React, { useRef } from 'react';
import './dialogs.scss';

const Browse = props => {
  const {
    buttonText,
    acceptedFileTypes,
    initialValue,
    label,
    fileName,
    selectFile
  } = props;

  const fileInput = useRef();

  const onClick = () => {
    fileInput.current.value = '';
    fileInput.current.click();
  };

  return (
    <div
      className="browse"
      onClick={onClick}>
      {label && (
        <div className="dataFileLabel">
          <label htmlFor="dataFile">{label}</label>
        </div>
      )}
      <div className="uploadRow">
        <input
          ref={fileInput}
          accept={acceptedFileTypes}
          className="dataFile"
          type="file"
          onChange={() => selectFile?.(fileInput.current.files[0])} />
        <input
          className="fileName"
          enabled="false"
          readOnly
          value={fileName || initialValue} />
        <input
          className="browseButton"
          type="button"
          value={buttonText} />
      </div>
    </div>
  );
};

export default Browse;