import { apiFetch, apiDownload } from './api-utils';

/*

Defines API method calls for batch scoring.

*/
const BatchScore = {
  // Returns the score results info.
  getScoreResultsInfo: async (resultsId) => await apiFetch(`/api/score/${resultsId}`),

  // Returns the stability results info.
  getStabilityResultsInfo: async (resultsId) => await apiFetch(`/api/stability/${resultsId}`),

  // Returns the score results.
  getScoreResults: async (resultsId) => await apiDownload(`/api/score/${resultsId}/download`),

  // Returns the stability results.
  getStabilityResults: async (resultsId) => await apiDownload(`/api/stability/${resultsId}/download`),
};

export default BatchScore;
