import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route, NavLink } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { QueryClientProvider, QueryClient } from 'react-query';
import { serializeError } from 'serialize-error';
import i18n from './services/i18n';
import Header from './components/header';
import ModelRepository from './components/ModelRepository';
import Deployments from './components/Deployments';
import DeploymentDashboard from './components/DeploymentDashboard';
import SystemAuditLog from './components/SystemAuditLog';
import EmbeddedUpload from './components/EmbeddedUpload';
import FlagsDialog from './components/FlagsDialog';
import ApiKeys from './components/apikeys';
import SessionWrapper from './components/SessionWrapper';
import BatchScoreDownload from './components/BatchScoreDownload';
import Sessions from './api/sessions';
import Settings from './api/settings';
import { ApiKeyIcon, AuditLog, DeployIcon, ModelRepositoryIcon } from './images/index';
import { Scopes, requireScope } from './utils/scopes';
import Prototype from './components/Prototype';
import { GlobalProviders } from './utils/context';
import { availableLocales } from './utils/locales';
import { applyGlobalHighchartOptions } from './utils/chart-utils';

import './App.scss';
import SoftwareUpdateDialog from './components/SoftwareUpdateDialog';
import { CUSTOM_REL_DATE, CUSTOM_TODAY, displayNewFeature } from './utils/software-update-utils';
import ModelDashboard from './components/ModelDashboard';

const queryClient = new QueryClient();

function UpgradingDialog() {
  const [t] = useTranslation();

  return (
    <div className="dialogContainer">
      <div className="modalDialog forceUpdate">
        <h3>{t('clientUpdateRequired')}</h3>
        <p>{t('subscriptionUpdating')}</p>
        <div className="buttons">
          <button
            className="confirmButton"
            // eslint-disable-next-line no-restricted-globals
            onClick={() => location.reload() }>
            {t('reload')}
          </button>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [pendingAction, setPendingAction] = useState(false);
  const [user, setUser] = useState();
  const [scope, setScope] = useState();
  const [settings, setSettings] = useState();
  const [flags, setFlags] = useState();
  const [errors, setErrors] = useState([]);
  const [showUpgrading, setShowUpgrading] = useState(false);
  const [showFlagsDialog, setShowFlagsDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  // Software Update Dialog Testing Variable
  const [resetSoftwareUpdateValues, setResetSoftwareUpdateValues] = useState(true);

  const addError = useCallback(originalError => {
    if (originalError.status === 503) {
      // Upgrading system
      setShowUpgrading(true);
    } else {
      setErrors(errors => [
        ...errors,
        {
          originalError,
          timeStamp: new Date().toISOString(),
        }
      ]);
    }
  }, []);

  const handleKeyDown = useCallback((event) => {
    if (event.ctrlKey && event.altKey && event.shiftKey) {
      setShowFlagsDialog(true);
    }
  }, []);

  useEffect(() => {
    let current = true;
    (async () => {
      try {
        const user = await Sessions.get();
        const scope = await Sessions.getScope();
        if (!current) {
          return;
        }
        setUser(user);
        setScope(scope);
      } catch (error) {
        addError(serializeError(error));
      }
    })();
    return () => current = false;
  }, [addError]);

  useEffect(() => {
    if (flags || !user) {
      return;
    }
    let current = true;
    (async () => {
      try {
        const flags = await Sessions.getFlags();
        if (!current) {
          return;
        }
        setFlags(flags);
      } catch (error) {
        addError(serializeError(error));
      }
    })();
    return () => current = false;
  }, [addError, flags, user]);

  useEffect(() => {
    if (!user) {
      setSettings();
    }
    let current = true;
    (async () => {
      try {
        const settings = await Settings.get();
        if (!current) {
          return;
        }
        setSettings(settings);
      } catch (error) {
        addError(serializeError(error));
      }
    })();
    return () => current = false;
  }, [addError, user]);

  useEffect(() => {
    if (settings) {
      const regionCode = settings.locale?.regionCode ? settings.locale.regionCode : 'en-US';
      const locale = availableLocales.find(al => al.value === regionCode);
      applyGlobalHighchartOptions(locale);
    }
  }, [settings]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (resetSoftwareUpdateValues && flags) {
      const today = localStorage.getItem(CUSTOM_TODAY);
      const lastReleaseDate = localStorage.getItem(CUSTOM_REL_DATE);
      setShowUpdateDialog(flags.flagManipulation ? displayNewFeature(today, lastReleaseDate) : displayNewFeature());
      setResetSoftwareUpdateValues(false);
    }
  }, [flags, resetSoftwareUpdateValues]);


  return <I18nextProvider i18n={i18n}>
    {showUpgrading && <UpgradingDialog />}
    {(!user || !flags) ? null : (
      <QueryClientProvider client={queryClient}>
        <GlobalProviders
          addError={addError}
          errors={errors}
          flags={flags}
          scope={scope}
          settings={settings}>
          <Router>
            <SessionWrapper user={user}>
              <Header
                clearErrors={() => setErrors([])}
                pending={pendingAction}
                setSettings={setSettings}
                user={user} />
              {showFlagsDialog && flags.flagManipulation &&
                <FlagsDialog
                  setFlags={setFlags}
                  setResetSoftwareUpdateValues={setResetSoftwareUpdateValues}
                  setShowFlagsDialog={setShowFlagsDialog} />}
              { showUpdateDialog &&
              <SoftwareUpdateDialog
                releaseDate={localStorage.getItem(CUSTOM_REL_DATE) ?? process.env.REACT_APP_FEATURE_LAST_RELEASE}
                setShowDialog={setShowUpdateDialog} />}
              <div className="content">
                <div className="leftMenu">
                  <NavLink
                    activeClassName="active"
                    to="/deployments">
                    <div title="Deployments">
                      <DeployIcon />
                    </div>
                  </NavLink><NavLink
                    activeClassName="active"
                    to="/models">
                    <div title="Model Repository">
                      <ModelRepositoryIcon />
                    </div>
                  </NavLink>
                  {requireScope(scope, Scopes.KeysRead) && (
                    <NavLink
                      activeClassName="active"
                      to="/apikeys">
                      <div title="API Keys">
                        <ApiKeyIcon />
                      </div>
                    </NavLink>
                  )}
                  {requireScope(scope, Scopes.LogsRead) && (
                    <NavLink
                      activeClassName="active"
                      to="/auditlog">
                      <div title="System Audit Log">
                        <AuditLog />
                      </div>
                    </NavLink>
                  )}
                </div>
                <div className="main">
                  <Switch>
                    <Route
                      exact
                      path="/">
                      <Redirect to="/deployments" />
                    </Route>
                    <Route path="/models">
                      <ModelRepository
                        setBusy={setPendingAction} />
                    </Route>
                    <Route path="/deployments">
                      <Deployments
                        setBusy={setPendingAction} />
                    </Route>
                    <Route
                      path="/dashboard/deployments/:id"
                      render={props => (
                        <DeploymentDashboard
                          busy={pendingAction}
                          deploymentId={props.match.params.id}
                          setBusy={setPendingAction} />
                      )} />
                    {flags.modelenhancements && <Route
                      path="/dashboard/models/:id"
                      render={props => (
                        <ModelDashboard
                          modelId={props.match.params.id}
                          setBusy={setPendingAction} />
                      )} />}
                    <Route path="/apikeys">
                      <ApiKeys />
                    </Route>
                    {requireScope(scope, Scopes.LogsRead) &&
                    <Route path="/auditlog">
                      <SystemAuditLog
                        busy={pendingAction}
                        setBusy={setPendingAction} />
                    </Route>}
                    <Route path="/embed/upload">
                      <EmbeddedUpload />
                    </Route>
                    <Route
                      path="/batch/:data/:id"
                      render={(props) => (
                        <BatchScoreDownload
                          data={props.match.params.data}
                          resultsId={props.match.params.id} />
                      )} />
                    {flags.flagManipulation && <Route path="/prototype">
                      <Prototype />
                    </Route>
                    }
                  </Switch>
                </div>
              </div>
            </SessionWrapper>
          </Router>
        </GlobalProviders>
      </QueryClientProvider>
    )}
  </I18nextProvider>;
}

export default App;
