import React, { createContext, useContext, useEffect, useState } from 'react';
import { sessionService } from '../services/sessionService';
import { FlagsContext } from '../utils/context';
import Confirm from './confirm';
import Sessions from '../api/sessions';
import { useTranslation } from 'react-i18next';

// Wait 5 seconds after user interacts to log session activity
const USER_EVENT_TIMEOUT = 5 * 1000;

export const SessionContext = createContext({});

export const SessionStatus = {
  Current: 'current',
  Warning: 'warning',
  Expired: 'expired'
};

export const HeartbeatStatus = {
  Initializing: 'initializing',
  Successful  : 'successful',
  Failed      : 'failed'
};

const SessionWrapper = ({ user, children }) => {
  const [dialog, setDialog] = useState();
  const [sessionStatus, setSessionStatus] = useState(SessionStatus.Current);
  const [heartbeatStatus, setHeartbeatStatus] = useState(HeartbeatStatus.Initializing);

  const [t] = useTranslation();

  const flags = useContext(FlagsContext);

  sessionService.warningCallback = () => {
    setSessionStatus(SessionStatus.Warning);
    setDialog(<Confirm
      confirm={() => {
        setDialog();
        setSessionStatus(SessionStatus.Current);
        sessionService.restoreSession();
      }}
      confirmationText={'activitySessionLabel'}
      primaryButtonText={'extendSession'}
      t={t}
      title={'activitySessionTitle'}
      wide />);
  };

  sessionService.expiredCallback = () => {
    Sessions.signout();
    setSessionStatus(SessionStatus.Expired);
    setDialog(<Confirm
      confirm={() => {
        setDialog();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }}
      confirmationText={'sessionExpiredLabel'}
      t={t}
      title={'sessionExpiredTitle'}
      wide />);
  };

  sessionService.versionMismatchCallback = () => {
    setDialog(<Confirm
      confirm={() => {
        setDialog();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }}
      confirmationText={'clientRefreshRequired'}
      primaryButtonText={'reload'}
      t={t}
      title={'clientUpdateRequired'}
      wide />);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    let current = true;
    (async () => {
      const retVal = await sessionService.setHeartBeat(user);
      if (current) {
        setHeartbeatStatus(retVal ? HeartbeatStatus.Successful : HeartbeatStatus.Failed);
        sessionService.setSessionTimeout();
      }
    })();
    return () => current = false;
  }, [user]);

  useEffect(() => {
    if (!flags) {
      return;
    }

    if (flags?.flagManipulation) {
      sessionService.activitySessionTimeout = sessionStorage.sessionTimeout;
      sessionService.expiredSessionTimeout = sessionStorage.warningTimeout;
      sessionService.setSessionTimeout();
    }

    sessionService.setVersionCheck(flags.version);
  }, [flags]);

  let userEvent;
  const handleUserEvent = () => {
    // Wait for pause in user actions so the session timeout isn't
    // always being renewed
    userEvent && clearTimeout(userEvent);
    userEvent = setTimeout(() => sessionService.setSessionTimeout(), USER_EVENT_TIMEOUT);
  };

  return (
    <SessionContext.Provider value={{ sessionStatus, heartbeatStatus }}>
      <div
        className="wrapper"
        onKeyDown={handleUserEvent}
        onMouseDown={handleUserEvent}>
        {children}
      </div>
      {dialog}
    </SessionContext.Provider>
  );
};

export default SessionWrapper;