/* eslint-disable max-len */
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { serializeError } from 'serialize-error';
import Models from '../api/models';
import Reports from '../api/reports';
import Deployments from '../api/deployments';
import { Statuses } from './Deployments';
import { FileStatuses, FileUploadTypes, getFileError } from './FileUpload';
import { ReportPeriodSetting } from './ReportHeader';
import { ResponseVariableTable, PredictorVariablesTable } from './DriftVariables';
import { getPromotions } from '../utils/model-utils';
import BinNavigation from './BinNavigation';
import DriftBinNavigation from './DriftBinNavigation';
import EmptyReport from './EmptyReport';
import { getErrorMessage } from '../utils/errors/report-errors';
import { ErrorsContext, FlagsContext } from '../utils/context';
import { SessionContext, SessionStatus } from './SessionWrapper';
import { DefaultSettings } from '../utils/model-utils';

const fetchDriftProperties = async (deploymentId, reportSettings) => {
  const { start, end, period } = reportSettings;
  return await Reports.getDriftProperties(deploymentId, start, end, ReportPeriodSetting[period]);
};

const fetchDriftData = async (deploymentId, reportSettings, forceRefresh) => {
  const { start, end, period } = reportSettings;
  const startDate = moment(start).format('YYYY-MM-DD');
  const endDate = moment(end).format('YYYY-MM-DD');
  let results;
  try {
    if (forceRefresh) {
      // Check to see if there is a new report that isn't dirty first.
      const driftProperties = await fetchDriftProperties(deploymentId, reportSettings);
      results = driftProperties?.isdirty === 'true'
        ? await Reports.generateDriftReport(deploymentId, start, end, ReportPeriodSetting[period])
        : await Reports.getDriftData(deploymentId, startDate, endDate, ReportPeriodSetting[period]);
    } else {
      results = await Reports.getDriftData(deploymentId, startDate, endDate, ReportPeriodSetting[period]);
    }
  } catch (err) {
    if (err.status !== 404) {
      throw err;
    }
    results = await Reports.generateDriftReport(deploymentId, start, end, ReportPeriodSetting[period]);
  }
  return {
    report   : results,
    errorCode: results?.errorcode,
    creating : [Statuses.Creating, Statuses.Pending].includes(results?.status)
  };
};

const DriftReport = props => {
  const {
    deployment,
    id,
    refresh,
    setRefresh,
    setShowRefresh,
    setLastUpdated,
    setHasReport,
    reportSettings,
    userSettings,
    updateUserSettings
  } = props;

  const [t] = useTranslation();
  const flags = useContext(FlagsContext);
  const { onError } = useContext(ErrorsContext);
  const session = useContext(SessionContext);

  const [reportData, setReportData] = useState();
  const [promotions, setPromotions] = useState();
  const [binData, setBinData] = useState();
  const [binSchema, setBinSchema] = useState();
  const [binChampionName, setBinChampionName] = useState();
  const [binDateRange, setBinDateRange] = useState({ start: null, end: null });
  const [isCurrentDate, setIsCurrentDate] = useState(true);
  const [reloadData, setReloadData] = useState(true);
  const [enableQuery, setEnableQuery] = useState(true);
  const [errorCode, setErrorCode] = useState();
  const [isOutOfDate, setIsOutOfDate] = useState(false);
  const [isProcessingFile, setIsProcessingFile] = useState(true);
  const [uploadStatus, setUploadStatus] = useState(FileStatuses.NotFound);
  const [validationMessage, setValidationMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showReport, setShowReport] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [showMissingDataMessage, setShowMissingDataMessage] = useState(false);
  const [showBinNavigation, setShowBinNavigation] = useState(false);
  const [hasVariableImportance, setHasVariableImportance] = useState(false);

  const { sortProperty, sortAscending, expandedVariables: expanded, binIndex, predictorPage } =
    useMemo(() => userSettings, [userSettings]);

  const totalBins = useMemo(() => reportData?.reports_by_bin?.length ?? 0, [reportData]);
  const elementId = 'drift-report';

  useQuery(`drift-${binData?.model}`, () => binData?.model && Models.get(binData.model),
    {
      onSuccess: async data => {
        const fileUploadStatus = (data && data[`${FileUploadTypes.Baseline}status`]) || FileStatuses.NotFound;
        setUploadStatus(fileUploadStatus);
        setIsProcessingFile(fileUploadStatus === FileStatuses.Pending);
        setValidationMessage(getFileError(t, {
          errorCode: Number(data?.[`${FileUploadTypes.Baseline}errorcode`]),
          errorInfo: data?.[`${FileUploadTypes.Baseline}errorinfo`] ? JSON.parse(data[`${FileUploadTypes.Baseline}errorinfo`]) : ''
        }));
      },
      refetchInterval: 5000, // 5 seconds
      enabled        : isProcessingFile && session?.sessionStatus !== SessionStatus.Expired,
    });

  const downloadQueryId = `drift-${id}-download-${JSON.stringify(reportSettings)}`;
  useQuery(downloadQueryId, () => fetchDriftData(id, reportSettings, refresh),
    {
      onSuccess: async data => {
        setReportData(data.report);
        setEnableQuery(data.creating && !data.errorCode);
        if (data.errorCode) {
          setErrorCode(data.errorCode);
        }
        setRefresh(false);
      },
      onError: error => {
        console.error(error);
        onError(serializeError(error));
      },
      enabled                    : !!enableQuery && session?.sessionStatus !== SessionStatus.Expired,
      refetchInterval            : 5000, // 5 seconds
      refetchIntervalInBackground: true
    });

  const propertiesQueryId = `drift-${id}-properties-${JSON.stringify(reportSettings)}`;
  useQuery(propertiesQueryId, () => fetchDriftProperties(id, reportSettings),
    {
      onSuccess: async data => {
        setIsOutOfDate(data?.isdirty === 'true');
        setLastUpdated(data?.lastupdated);
      },
      onError: error => {
        console.error(error);
        onError(serializeError(error));
      },
      enabled                    : !enableQuery && !isOutOfDate && session?.sessionStatus !== SessionStatus.Expired,
      refetchInterval            : 5000, // 5 seconds
      refetchIntervalInBackground: true
    });

  useEffect(() => {
    let current = true;
    if (reloadData) {
      (async () => {
        const promotionsLog = await Deployments.getPromotions(id);
        // Convert raw promotions log (timestamp/guids) to readable list with model names.
        const promotions = promotionsLog.length ? await getPromotions(t, promotionsLog) : [];
        if (current) {
          setPromotions(promotions);
          setReloadData(false);
        }
      })();
    }
    return () => current = false;
  }, [t, id, reloadData]);

  const reset = () => {
    // Reset pagination when a new report has been requested.
    setReportData(null);
    setIsOutOfDate(false);
    setBinData();
    setErrorCode();
    setPromotions();
    setEnableQuery(true);
    setReloadData(true);
    setIsLoading(true);
    setShowReport(false);
    setShowValidationMessage(false);
    setShowMissingDataMessage(false);
    setShowBinNavigation(false);
  };

  useEffect(reset, [reportSettings]);
  useEffect(() => {
    if (refresh) {
      reset();
    }
  }, [refresh]);

  useEffect(() => {
    setShowRefresh(isOutOfDate);
  }, [isOutOfDate, setShowRefresh]);

  useEffect(() => {
    let current = true;
    let handler;

    const oldModelId = binData?.model;
    const bin = reportData?.reports_by_bin?.[binIndex];
    if (oldModelId !== bin?.model || (binData && !bin?.report)) {
      setIsLoading(true);
      // Reset predictor page to 1 if new bin has different champion model
      if (oldModelId) {
        updateUserSettings({ predictorPage: 1 });
      }
    }

    if (bin) {
      (async () => {
        const name = bin.name ?? (await Models.get(bin.model, 'name')).name;
        // Only retrieve model schema if previous bin has different champion model
        const modelSchema = bin.model !== oldModelId && await Models.schema(bin.model);
        if (current) {
          handler = setTimeout(() => {
            modelSchema && setBinSchema({ [modelSchema.response.name]: modelSchema.response, ...modelSchema.predictors });
            setBinData(bin);
            setBinChampionName(name);
            setBinDateRange({ start: bin.start, end: bin.end });
          }, 150);

          const hasVarImportance = Object.values(bin.report?.predictors ?? {}).some(p => p.variable_importance);
          setHasVariableImportance(hasVarImportance);
          if ((!flags.var_importance || !hasVarImportance) && sortProperty === 'variableImportance') {
            // Reset if variable importance not supported for new bin's champion
            updateUserSettings({ sortProperty: 'name', sortAscending: true });
          }
        }
      })();
    }

    return () => {
      current = false;
      clearTimeout(handler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags, reportData, binIndex, binData, sortProperty]);

  useEffect(() => {
    // Bin index out of range for this report
    const bins = reportData?.reports_by_bin;
    if (bins && Object.keys(userSettings).length && (bins.length <= binIndex || binIndex === undefined)) {
      updateUserSettings({ binIndex: flags.drift_navigation ? bins.length - 1 : 0 });
    }
  }, [flags, updateUserSettings, reportData, binIndex, userSettings]);

  useEffect(() => {
    const loading = (reportData && !binData && !errorCode) || enableQuery || reloadData || refresh || !promotions;
    const binReport = !loading && binData?.report;
    const hasValidationMessage = !binData?.report && [FileStatuses.Invalid, FileStatuses.Failed].includes(uploadStatus);

    setIsLoading(loading);
    setShowReport(!!binReport);
    setShowValidationMessage(hasValidationMessage);
    setShowMissingDataMessage(!loading && !binReport && !hasValidationMessage && !errorCode);
    setShowBinNavigation(!loading && (reportData?.reports_by_bin?.length > 1 || binData?.report));
    setHasReport(!!binReport);
  }, [binData, enableQuery, errorCode, promotions, refresh, reloadData, reportData, setHasReport, uploadStatus]);

  // Current UI state
  const isPaused = deployment.status !== Statuses.Deployed;

  const getMissingReportMessage = useCallback(() => {
    const helpLink = `${flags.helpURL}/monitor-deployed-models/upload-baseline-and-production-data/`;
    if (uploadStatus === FileStatuses.NotFound && !isPaused) {
      const message = t(showBinNavigation ? 'driftNoUploadedDataTimePeriod' : 'driftNoUploadedData');
      return <>
        {message}
        <a
          href={helpLink}
          rel="noreferrer"
          target="_blank">
          {t('uploadDriftData')}
        </a>
      </>;
    } else if (uploadStatus === FileStatuses.Pending) {
      return t('filePendingUpload');
    } else if (uploadStatus === FileStatuses.Failed) {
      return t('uploadFailedMessage');
    } else if (isPaused && isCurrentDate) {
      return uploadStatus === FileStatuses.NotFound ? t('noUploadedDataInactive') : t('noCalculationsWhenInactive');
    }
    return isCurrentDate ? t('noCalculatedBaselineData') : t('noAvailableData');
  }, [t, flags, uploadStatus, isCurrentDate, isPaused, showBinNavigation]);

  const toggleExpanded = variable => {
    if (expanded.includes(variable)) {
      updateUserSettings({ expandedVariables: expanded.filter(v => v !== variable) });
    } else {
      updateUserSettings({ expandedVariables: expanded.concat(variable) });
    }
  };

  const toggleSort = property => {
    updateUserSettings({
      sortAscending: sortProperty === property ? !sortAscending : true,
      sortProperty : property
    });
  };

  return (
    <div
      className='drift-report'
      id={elementId}>
      <div className="report-dashboard-container">
        <div className="flex-header">
          {showBinNavigation && <>
            <div className="production-data">
              {flags.drift_navigation ?
                <DriftBinNavigation
                  additionalInfo={(
                    <span className="champion-bin-label">
                      {t('championModelLabel')}
                      <span>{binChampionName ?? t('unknown')}</span>
                    </span>
                  )}
                  binDateRange={binDateRange}
                  binIndex={binIndex}
                  binLabels={reportData?.timeseries_data.label}
                  setBinIndex={index => updateUserSettings({ binIndex: index })}
                  setIsCurrentDate={setIsCurrentDate}
                  setShowReport={setShowReport}
                  timezone={deployment.timezone ?? DefaultSettings.Timezone}
                  totalBins={totalBins} /> :
                <BinNavigation
                  additionalInfo={(
                    <span className="model-name">
                      {`(${binChampionName ?? t('unknown')})`}
                    </span>
                  )}
                  binDateRange={binDateRange}
                  binIndex={binIndex}
                  setBinIndex={index => updateUserSettings({ binIndex: index })}
                  setIsCurrentDate={setIsCurrentDate}
                  timezone={deployment.timezone ?? DefaultSettings.Timezone}
                  totalBins={totalBins} />}
            </div>
          </>}
        </div>
        {isLoading ? <>
          <EmptyReport
            description={t('updatingReportDescription')}
            title={t('updatingReport')} />
        </> : <>
          {showValidationMessage &&
            <EmptyReport
              description={validationMessage || t('uploadFailedMessage')}
              title={t('reportsNotAvailable')} />}
          {showMissingDataMessage &&
            <EmptyReport
              description={getMissingReportMessage()}
              title={t('reportsNotAvailable')} />}
          {errorCode &&
            <EmptyReport
              description={getErrorMessage(t, errorCode, 'drift', flags)}
              title={t('reportsNotAvailable')} />}
          {showReport && <>
            <div className="response-table">
              <ResponseVariableTable
                expanded={expanded}
                hasVariableImportance={hasVariableImportance}
                metadata={deployment}
                promotions={promotions}
                psi={reportData.timeseries_data}
                response={binData.report.response}
                schema={binSchema}
                setBinIndex={index => updateUserSettings({ binIndex: index })}
                toggleExpanded={toggleExpanded} />
            </div>
            <div className="predictor-table">
              <PredictorVariablesTable
                currentPage={predictorPage}
                expanded={expanded}
                hasVariableImportance={hasVariableImportance}
                metadata={deployment}
                predictors={binData.report.predictors}
                promotions={promotions}
                psi={reportData.timeseries_data}
                schema={binSchema}
                setBinIndex={index => updateUserSettings({ binIndex: index })}
                setCurrentPage={page => updateUserSettings({ predictorPage: page })}
                sortAscending={sortAscending}
                sortProperty={sortProperty}
                toggleExpanded={toggleExpanded}
                toggleSort={toggleSort} />
            </div>
          </>}
        </>}
      </div>
    </div>
  );
};

export default DriftReport;
