import React from 'react';
import { ReportIcon } from '../images';

const EmptyReport = ({ title, description }) => {
  return (
    <div className='empty-report'>
      <ReportIcon />
      <div className='watermark'>
        <span className='title'>{title}</span>
        <span className='description'>{description}</span>
      </div>
    </div>
  );
};

export default EmptyReport;