import { MAX_FILE_SIZE_50_MB } from '../file-utils';

const ERROR_CODES = {
  // From SPM grove.h
  ValidationNotAttempted                : 0,
  ValidationSuccessful                  : 1,
  NoGroveIdentified                     : 2,
  UnableToReadOrLoadGrove               : 3,
  GroveDoesNotContainTranslatableModels : 4,
  TreeNetRfLogitModelsNotSupported      : 5,
  TreeNetGpsHybridModelsNotSupported    : 6,
  GroveContainsMultipleModels           : 7,
  UnsupervisedLearningModelsNotSupported: 8,
  LaggedVariablesNotSupported           : 9,
  TreeNetUpliftNotSupported             : 10,
  RegressNotSupported                   : 11,
  MarsClassificationNotSupported        : 12,
  OutOfMemory                           : 137,

  // General errors
  NameIsRequired        : 'NameIsRequired',
  NameNotUnique         : 'NameNotUnique',
  InvalidType           : 'InvalidType',
  InvalidEventValue     : 'InvalidEventValue',
  FileNotSafe           : 'FileNotSafe',
  FileSizeNotSafe       : 'FileSizeNotSafe',
  InvalidBinaryThreshold: 'InvalidBinaryThreshold',
  InvalidColumns        : 'InvalidColumns',
  InvalidSchemaText     : 'InvalidSchemaText',
  TooManyNodes          : 'TooManyNodes',
  TooManyPredictors     : 'TooManyPredictors',
  TooManyResponseClasses: 'TooManyResponseClasses',
  TooManyPredicorClasses: 'TooManyPredictorClasses'
};

export const getErrorMessage = (t, errorCode) => {
  if (!errorCode) {
    return t('errorDefault');
  }
  switch (errorCode) {
    case ERROR_CODES.NoGroveIdentified:
      return t('errorNoGrove');
    case ERROR_CODES.UnableToReadOrLoadGrove:
      return t('errorUnableToAccessGrove');
    case ERROR_CODES.GroveDoesNotContainTranslatableModels:
      return t('errorNoTranslationInGrove');
    case ERROR_CODES.TreeNetRfLogitModelsNotSupported:
      return t('errorTreeNetRFLogitNotSupported');
    case ERROR_CODES.TreeNetGpsHybridModelsNotSupported:
      return t('errorTreeNetHybridGPSNotSupported');
    case ERROR_CODES.GroveContainsMultipleModels:
      return t('errorMultipleModelsNotSupported');
    case ERROR_CODES.UnsupervisedLearningModelsNotSupported:
      return t('errorUnsupervisedLearningNotSupported');
    case ERROR_CODES.LaggedVariablesNotSupported:
      return t('errorLaggedVariablesNotSupported');
    case ERROR_CODES.TreeNetUpliftNotSupported:
      return t('errorTreeNetUpliftNotSupported');
    case ERROR_CODES.RegressNotSupported:
      return t('errorRegressionOLSNotSupported');
    case ERROR_CODES.MarsClassificationNotSupported:
      return t('errorMarsClassificationNotSupported');
    case ERROR_CODES.OutOfMemory:
      return t('errorOutOfMemory', { errorCode });
    case ERROR_CODES.NameIsRequired:
      return t('validateModelNameIsRequired');
    case ERROR_CODES.NameNotUnique:
      return t('modelNamesMustBeUnique');
    case ERROR_CODES.InvalidType:
      return t('selectedModelNotSupported');
    case ERROR_CODES.InvalidEventValue:
      return t('validateValuesNoDoubleQuotes');
    case ERROR_CODES.FileNotSafe:
      return t('validateModelProjectFileRequired');
    case ERROR_CODES.FileSizeNotSafe:
      return t('validateFileSize', { limit: MAX_FILE_SIZE_50_MB });
    case ERROR_CODES.InvalidBinaryThreshold:
      return `${t('binaryEventProbabilityThresholdLabel')} ${t('validateNumericValueGreaterLess', { val1: 0, val2: 1 })}`;
    case ERROR_CODES.InvalidColumns:
      return t('validateModelReservedVariableName');
    case ERROR_CODES.InvalidSchemaText:
      return t('validateModelInvalidSchema');
    case ERROR_CODES.TooManyNodes:
      return t('validateModelTooManyNodes');
    case ERROR_CODES.TooManyPredictors:
      return t('validateModelTooManyPredictors');
    case ERROR_CODES.TooManyResponseClasses:
      return t('validateModelTooManyResponseClasses');
    case ERROR_CODES.TooManyPredicorClasses:
      return t('validateModelTooManyPredictorClasses');
    case undefined:
      return t('uploadFailedMessage');
    default:
      return t('errorUnknown', { errorCode });
  }
};