import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './dialogs.scss';
import { WarningIcon } from '../images';

function ApiKeyDisplay(props) {
  const [t] = useTranslation();
  const [copied, setCopied] = useState(false);

  const closeDialog = () => props.onCancel();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.apiKey);
    setCopied(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <div className="dialogContainer">
      <div className="modalDialog apiKeyDisplayDialog">
        <h3>{t('copyApiKey')}</h3>
        {!props.showWarning ? (
          <div className='instructions'>{t('copyApiKeyInstructions')}</div>
        ) : (
          <div className="formControl">
            <p className="alert">
              <WarningIcon />
              <strong>{t('warning')}</strong>
              {t('copyApiKeyInstructions')}
            </p>
          </div>
        )}
        {props.user?.email && (
          <div className="formControl">
            <strong>{t('username')}</strong>
            <label>{props.user.email}</label>
          </div>
        )}
        <div className="formControl">
          <strong>{t('apiKey')}</strong>
        </div>
        <div className="formControl">
          <textarea
            className="apiKeyPassword"
            readonly
            value={props.apiKey} />
        </div>
        <div className="buttons">
          <button 
            className="copyButton" 
            onClick={copyToClipboard}>
            {t(copied ? 'copied' : 'copyToClipboard')}
          </button>
          <button 
            className="cancelButton" 
            onClick={closeDialog}>
            {t('close')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ApiKeyDisplay;
