export const ERROR_CODES = {
  PendingReplay       : '1',
  NoScoreLogData      : '2',
  MismatchCorrId      : '3',
  MismatchResponseType: '4',
  OutOfMemory         : '5'
};

export const getErrorMessage = (t, errorCode, reportType, flags) => {
  switch (errorCode) {
    case null:
    case undefined:
      return null;
    case ERROR_CODES.PendingReplay:
      return t('pendingReplay');
    case ERROR_CODES.NoScoreLogData :
      return t('noScorelogData');
    case ERROR_CODES.MismatchCorrId:
      return t('mismatchCorrId');
    case ERROR_CODES.MismatchResponseType:
      return t('mismatchResponseType');
    case ERROR_CODES.OutOfMemory:
      return t('outOfMemory');
    default:
      if (reportType === 'stability') {
        return t('stabilityDataRequiredDescription');
      } 
      const helpLink = `${flags.helpURL}/monitor-deployed-models/upload-baseline-and-production-data`;
      return (<>
        {t('driftNoUploadedData')}
        <a
          href={helpLink}
          rel="noreferrer"
          target="_blank">
          {t('uploadDriftData')}
        </a>
      </>);
  }
};