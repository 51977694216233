import { createContext, useMemo } from 'react';

export const FlagsContext = createContext({});
export const SettingsContext = createContext({});
export const ErrorsContext = createContext({});
export const ScopeContext = createContext([]);

export const GlobalProviders = ({ children, errors, addError, flags, scope, settings }) => {

  // Optimization, context only needs to re-render if
  // values in errors changed.
  const errorsContextValue = useMemo(() => {
    return { errors, onError: addError };
  }, [addError, errors]);

  return (
    <ErrorsContext.Provider value={errorsContextValue}>
      <ScopeContext.Provider value={scope}>
        <FlagsContext.Provider value={flags}>
          <SettingsContext.Provider value={settings}>
            {children}
          </SettingsContext.Provider>
        </FlagsContext.Provider>
      </ScopeContext.Provider>
    </ErrorsContext.Provider>
  );
};