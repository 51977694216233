import { apiFetch, apiDownload } from './api-utils';

/*

Defines API method calls for Deployments.

*/
const Deployments = {
  // Returns all known deployments
  getAll: async () => await apiFetch('/api/deployments'),

  // Returns a single deployment
  get: async (id, filter) => await apiFetch(`/api/deployments/${id}${filter ? `?${new URLSearchParams({ filter })}` : ''}`),

  // Returns all models associated with a single deployment
  getDeploymentModels: async id => await apiFetch(`/api/deployments/${id}/models`),

  // Creates a new deployment.
  create: async deployment => await apiFetch('/api/deployments', {
    method : 'POST',
    body   : JSON.stringify(deployment),
    headers: {
      'Content-Type'   : 'application/json',
      'Accept-Language': ''
    }
  }),

  // Removes an inactive deployment.
  delete: async id => await apiFetch(`/api/deployments/${id}`, { method: 'DELETE' }),

  // Resumes/deploys existing deployment.
  resume: async id => await apiFetch(`/api/deployments/${id}/deploy`, { method: 'POST' }),

  // Tears down an existing deployment.
  pause: async id => await apiFetch(`/api/deployments/${id}/deploy`, { method: 'DELETE' }),

  // Updates deployment metadata.
  patchDeployment: async (id, deployment) => await apiFetch(`/api/deployments/${id}`, {
    method : 'PATCH',
    body   : JSON.stringify(deployment),
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  // Upload csv data for batch scoring.
  // Browsers autofill Accept-Language, blank it out to allow API to decide.
  uploadScoreData: async (id, data) => await apiFetch(`/api/deployments/${id}/score`, {
    method : 'POST',
    body   : data,
    headers: {
      'mtb-not-logged' : 'true',
      'mtb-no-email'   : 'true',
      'Accept-Language': ''
    }
  }),

  // Upload csv data for stability logging.
  // Browsers autofill Accept-Language, blank it out to allow API to decide.
  uploadStabilityData: async (id, data) => await apiFetch(`/api/deployments/${id}/stability`, {
    method : 'POST',
    body   : data,
    headers: {
      'mtb-not-logged' : 'true',
      'mtb-no-email'   : 'true',
      'Accept-Language': ''
    }
  }),

  // Sends a message to the download worker to prepare production data for downloading.
  // Browsers autofill Accept-Language, blank it out to allow API to decide.
  prepareProductionDataDownload: async (id, downloadExtension, start, end) => {
    return await apiFetch(`/api/deployments/${id}/production/download?${new URLSearchParams({ start, end })}`, {
      method : 'POST',
      headers: {
        'Accept': {
          'csv': 'text/csv',
          'mwx': 'application/mwx'
        }[downloadExtension],
        'Accept-Language': ''
      }
    });
  },

  // Sends a message to the download worker to prepare production data for downloading.
  // Browsers autofill Accept-Language, blank it out to allow API to decide.
  downloadProductionData: async (id, downloadExtension, start, end) => {
    return await apiDownload(`/api/deployments/${id}/production/download?${new URLSearchParams({ start, end })}`, {
      method : 'GET',
      headers: {
        'Accept': {
          'csv': 'text/csv',
          'mwx': 'application/mwx'
        }[downloadExtension],
        'Accept-Language': ''
      }
    });
  },

  // Gets service metrics for a given deployment
  getMetrics: async (id, timePeriod, aggFn) => await apiFetch(`/api/deployments/${id}/metrics?period=${timePeriod}&aggFn=${aggFn}`),

  // Gets the audit log entries for a given deployment
  getAuditLog: async (id, page, type) => await apiFetch(`/api/deployments/${id}/auditLog?page=${page}&type=${type}`),

  // Gets the daily storage utilization for the deployment's score log.
  getScoreLogSize: async id => await apiFetch(`/api/deployments/${id}/logsize`),

  // Gets the list of timestamped promotions throughout a deployment.
  getPromotions: async id => await apiFetch(`/api/deployments/${id}/promote`),

  // Adds a challenger to a deployment.
  addChallenger: async (id, modelId) => await apiFetch(`/api/deployments/${id}/challengers`, {
    method : 'POST',
    body   : JSON.stringify({ modelId }),
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  // Removes a challenger from a deployment.
  removeChallenger: async (id, modelId) => await apiFetch(`/api/deployments/${id}/challengers/${modelId}`, { method: 'DELETE' }),

  // Promotes a challenger to champion within a deployment.
  promoteChallenger: async (id, modelId) => await apiFetch(`/api/deployments/${id}/challengers/${modelId}/promote`, { method: 'POST' }),

  // Executes a score against a deployment.
  score: async (id, data) => await apiFetch(`/api/deployments/${id}/score`, {
    method : 'POST',
    body   : JSON.stringify(data),
    headers: {
      'Content-Type'  : 'application/json',
      'mtb-not-logged': 'true'
    }
  }),

  // Adds a stability value to a deployment.
  stability: async (id, data) => await apiFetch(`/api/deployments/${id}/stability`, {
    method : 'POST',
    body   : JSON.stringify(data),
    headers: {
      'Content-Type'  : 'application/json',
      'mtb-not-logged': 'true'
    }
  }),
};

export default Deployments;
