import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Browse from './browse';
import { isFileSafe } from '../utils/file-utils';
import './dialogs.scss';

export const FileUploadTypes = {
  Baseline    : 'baseline',
  BatchScoring: 'batch',
  Stability   : 'stability',
  Validation  : 'validation'
};

export const FileStatuses = {
  Creating: 'creating',
  Pending : 'pending',
  Invalid : 'invalid',
  Valid   : 'valid',
  Failed  : 'failed',
  NotFound: 'notfound'
};

export const FileInfo = {
  PartialPredictors: 'partialpredictors'
};

export const getFileError = (t, error) => {
  const { errorCode, errorInfo } = error;
  switch (errorCode) {
    case 0:
      return '';
    case 1:
      return t('validateHasData');
    case 2:
      return t('validateColumnsHaveSameDataLength');
    case 3:
      return t('validateHasAllPredictors', { missingPredictors: errorInfo.join(', ') });
    case 4:
      return t('validateHasCorrectPredictorTypes', { missingPredictors: errorInfo.join(', ') });
    case 5:
      return t('validateHasResponse', { response: errorInfo });
    case 6:
      return t('validateHasPredictionScore');
    case 7:
      return t('validateHasActualResponse', { response: errorInfo });
    case 8:
      return t('validateHasCorrelationId', { variableId: errorInfo });
    case 9:
      return t('validateHasTooFewRecords', { numRecords: errorInfo });
    case 10:
      return t('validateHasTooManyRecords', { ...errorInfo });
    default:
      return t('validateGenericUploadFailure');
  }
};

export const loadFile = async ({
  t,
  inputFile,
  setValidationMessage,
  setUploading,
  acceptedFileTypes = ['.csv', '.mwx'],
  setDataUploaded,
  uploadData,
  onCancel,
  returnPayload = false
}) => {
  let successful = false;
  let payload;
  setValidationMessage('');
  const fileName = inputFile?.name;
  if (!fileName) {
    return;
  }
  const fileExtension = fileName.split('.').pop().toUpperCase();
  if (acceptedFileTypes.map(fileType => fileType.split('.').pop().toUpperCase()).includes(fileExtension)) {
    try {
      const data = new FormData();
      data.append('data', inputFile);
      setUploading?.(true);
      payload = await uploadData(data);
      successful = true;
      setDataUploaded?.(true);
      onCancel?.();
    } catch (e) {
      if (e.status === 400 && e.body.errorCode) {
        setValidationMessage(getFileError(t, e.body));
      } else {
        console.error(e);
        throw e;
      }
    } finally {
      setUploading?.(false);
    }
  } else {
    setValidationMessage(t('validateUnsupportedFileFormat'));
  }
  return returnPayload ? { payload, successful } : successful;
};

export const FileUpload = props => {
  const {
    t,
    title,
    instructionText,
    label,
    acceptedFileTypes = ['.csv', '.mwx'],
    inputFile,
    setInputFile,
    maxFileSize,
    fileName,
    setFileName,
    validationMessage,
    setValidationMessage,
    initialValue,
  } = props;

  useEffect(() => {
    setValidationMessage('');
    setFileName(inputFile?.name ?? '');
    const fileError = isFileSafe(inputFile, maxFileSize);
    if (fileError) {
      setValidationMessage(t(fileError.key, fileError.hints));
    }
  }, [inputFile, maxFileSize, setFileName, setValidationMessage, t]);

  return (<>
    {title && <h3>{title}</h3>}
    {instructionText && <p>{instructionText}</p>}
    <Browse
      acceptedFileTypes={acceptedFileTypes}
      buttonText={t('browse')}
      fileName={fileName}
      initialValue={initialValue ?? t('noFileSelected')}
      label={label}
      selectFile={setInputFile} />
    {validationMessage && <div className="validationMessage">{validationMessage}</div>}
  </>);
};

export const FileUploadDialog = props => {
  const {
    title,
    instructionText,
    maxFileSize,
    onCancel,
    uploadData,
    setDataUploaded,
    acceptedFileTypes = ['.csv', '.mwx']
  } = props;

  const [t] = useTranslation();

  const [inputFile, setInputFile] = useState();
  const [fileName, setFileName] = useState('');
  const [uploading, setUploading] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const cancelDialog = async () => {
    onCancel();
  };

  return (
    <div className='dialogContainer'>
      <div className='uploadDialog'>
        <FileUpload
          acceptedFileTypes={acceptedFileTypes}
          fileName={fileName}
          inputFile={inputFile}
          instructionText={instructionText}
          label={t('dataSet')}
          maxFileSize={maxFileSize}
          setFileName={setFileName}
          setInputFile={setInputFile}
          setValidationMessage={setValidationMessage}
          t={t}
          title={title}
          validationMessage={validationMessage} />
        <div className="buttons">
          <button
            className="cancelButton"
            disabled={uploading}
            onClick={cancelDialog}>
            {t('cancel')}
          </button>
          <button
            className="confirmButton"
            disabled={!fileName || validationMessage || uploading}
            onClick={() => loadFile({
              t,
              inputFile,
              setValidationMessage,
              setUploading,
              setDataUploaded,
              uploadData,
              onCancel
            })}>
            {uploading ? t('uploading') : t('upload')}
          </button>
        </div>
      </div>
    </div>
  );
};