import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  TextField
} from '@mtb/ui';
import classNames from 'classnames';
import { FileUpload, loadFile } from './FileUpload';
import { RequestTypes } from './Integration';
import './Integration.scss';
import { LocalizedInput, InputTypes } from './Inputs';
import { toLocalizedString } from '../utils/locales';
import { SettingsContext } from '../utils/context';

export const INITIAL_NUM_MULTI_ROWS = 3;
export const MAX_VARIABLE_ID_LENGTH = 50;
export const MAX_TEXT_INPUT_LENGTH = 80;
const ACCEPTED_FILE_TYPES = ['.csv'];

export const getValidationMessage = (t, values, columns) => {
  for (const [name, { type } ] of Object.entries(columns)) {
    const entries = values.map(entry => entry[name]);
    if (type === 'continuous' && values?.length) {
      if (entries.some(entry => entry < -1e18 || entry > 1e18)) {
        return t('validateNumericRange');
      }
    }
  }
};

const isInvalid = (value, type) => type === 'continuous' && (value < -1e18 || value > 1e18);

export const IntegrationFormMulti = ({ columnInfo, type, values, setValue }) => {
  const settings = useContext(SettingsContext);
  return (
    <Table
      className={classNames('table-form-multi', { stability: type === RequestTypes.Stability })}
      size='small'
      stickyHeader>
      <TableHead>
        <TableRow>
          {columnInfo.map(({ name }) =>
            <TableCell key={name}>{name}</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map((row, i) =>
          <TableRow key={i}>
            {columnInfo.map(({ name, type, dataType, classes, maxLength }, columnIndex) => {
              const cellValue = row[name].value;
              return (
                <TableCell
                  key={`${name}-${i}`}
                  className={classNames({
                    'text-input-cell': type !== 'categorical',
                    'error'          : isInvalid(cellValue, type)
                  })}
                  data-testid={`r${i}-c${columnIndex}`}>
                  {type !== 'categorical' ?
                    <LocalizedInput
                      allowQuotedInput={true}
                      fullWidth
                      initialType={dataType}
                      initialValue={cellValue}
                      inputProps={{ maxLength }}
                      name={name}
                      onChange={value => setValue(name, value, i)} /> :
                    <LocalizedInput
                      fullWidth
                      initialType={dataType}
                      initialValue={cellValue?.toString()}
                      select
                      onChange={value => setValue(name, value, i)}>
                      {classes.map(c => {
                        const localizedSelectValue = dataType === InputTypes.Numeric ?
                          toLocalizedString(c, settings.locale?.regionCode) : c;
                        return <MenuItem
                          key={localizedSelectValue}
                          value={localizedSelectValue}>
                          {localizedSelectValue}
                        </MenuItem>;
                      })}
                    </LocalizedInput>}
                </TableCell>);
            })}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export const IntegrationFormSingle = props => {
  const {
    columnInfo,
    variableId,
    timestampId,
    type,
    predictors,
    response,
    values,
    setValue
  } = props;

  const getColumnInfo = (columnName, property) =>
    (columnInfo.find(column => column.name === columnName) ?? {})[property];
  const settings = useContext(SettingsContext);

  return (
    <Table
      className={classNames('table-form-single', { stability: type === RequestTypes.Stability })}
      size='small'>
      <TableBody>
        <TableRow hover={false}>
          <TableCell>{variableId}</TableCell>
          <TableCell data-testid='single-row-observation-id'>
            <TextField
              fullWidth
              inputProps={{ maxLength: getColumnInfo(variableId, 'maxLength') }}
              name={variableId}
              value={values[0]?.[variableId]?.value || ''}
              onChange={({ target: { value } }) => setValue(variableId, { value, type: InputTypes.Text })} />
          </TableCell>
        </TableRow>
        {type === RequestTypes.Prediction && (
          <TableRow hover={false}>
            <TableCell>{timestampId}</TableCell>
            <TableCell data-testid='single-row-timestamp'>
              <TextField
                fullWidth
                inputProps={{ maxLength: getColumnInfo(timestampId, 'maxLength') }}
                name={timestampId}
                value={values[0]?.[timestampId]?.value || ''}
                onChange={({ target: { value } }) => setValue(timestampId, { value, type: InputTypes.Text })} />
            </TableCell>
          </TableRow>
        )}
        {(predictors) && <TableRow className='tr-divider' />}
        {(predictors || response) && values?.length && <>
          {Object.entries(predictors ?? { [response.name]: response })
            .map(([name, { type, dataType, classes }], i) => {
              return (
                <TableRow
                  key={name}
                  hover={false}>
                  <TableCell>{name}</TableCell>
                  <TableCell
                    className={classNames({ error: isInvalid(values[0]?.[name] || '', type) })}
                    data-testid={`single-row-predictor-${i}`}>
                    {(!type || type === 'continuous') ?
                      <LocalizedInput
                        allowQuotedInput={true}
                        error={isInvalid(values[0]?.[name] || '', type)}
                        fullWidth={!!response}
                        initialType={dataType}
                        initialValue={values[0]?.[name]?.value}
                        inputProps={{ maxLength: getColumnInfo(name, 'maxLength') }}
                        onChange={value => setValue(name, value)} /> :
                      <LocalizedInput
                        fullWidth={!!response}
                        initialType={dataType}
                        initialValue={values[0]?.[name]?.value}
                        select
                        onChange={value => setValue(name, value)}>
                        {classes?.map(c => {
                          const localizedSelectValue = dataType === InputTypes.Numeric ?
                            toLocalizedString(c, settings.locale?.regionCode) : c;
                          return <MenuItem
                            key={localizedSelectValue}
                            value={localizedSelectValue}>{localizedSelectValue}</MenuItem>;
                        }
                        )}
                      </LocalizedInput>}
                  </TableCell>
                </TableRow>
              );
            })}
        </>}
      </TableBody>
    </Table>
  );
};

export const IntegrationFormFile = forwardRef((props, ref) => {
  const {
    t,
    type,
    uploadData,
    dataFile,
    setDataFile,
    setValidationMessage
  } = props;

  const [fileName, setFileName] = useState('');

  // Allow the upload fn. to be called by the parent component on submit.
  useImperativeHandle(ref, () => ({
    async upload() {
      return await loadFile({
        t,
        uploadData,
        setValidationMessage,
        inputFile        : dataFile,
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        returnPayload    : true
      });
    }
  }));

  useEffect(() => {
    setFileName('');
    setDataFile();
  }, [type, setDataFile]);

  return (
    <FileUpload
      acceptedFileTypes={ACCEPTED_FILE_TYPES}
      fileName={fileName}
      inputFile={dataFile}
      setFileName={setFileName}
      setInputFile={setDataFile}
      setValidationMessage={setValidationMessage}
      t={t} />
  );
});