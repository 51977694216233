/* eslint-disable max-len */
import _isEqual from 'lodash/isEqual';
import React, { useMemo, useState, useContext } from 'react';
import { Grid, Container } from '@mtb/ui';
import { useTranslation } from 'react-i18next';
import Deployments from '../api/deployments';
import { AddModel } from '../images';
import ModelCard from './ModelCard';
import AddChallengerDialog from './AddChallengerDialog';
import ValidationDialog from './ValidationDialog';
import { Scopes, requireScope } from '../utils/scopes';
import './DeploymentModels.scss';
import { ScopeContext } from '../utils/context';
import SchemaTable from './SchemaTable';

const DeploymentModels = props => {
  const {
    deployment,
    champion,
    challengers,
    setBusy,
    updateDeployment,
    pendingDeploy,
    pendingPause,
    updateUserDeploymentSettings,
    userDeploymentSettings
  } = props;

  const [t] = useTranslation();
  const scope = useContext(ScopeContext);
  const [showAddModelDialog, setShowAddModelDialog] = useState(false);
  const { modelVariablesSortProperty, modelVariablesSortAscending, modelVariablesPage } = userDeploymentSettings;

  const deploymentModels = useMemo(() => [champion, ...challengers], [champion, challengers]);


  return (
    <>
      <Container
        className='models-content'
        maxWidth={false}>
        <Grid container>
          <Grid
            className='models-side-panel'
            item
            xs={3}>
            <h4>{t('models')}</h4>
            <div>{t('deploymentModelsDescription')}</div>
            <div className="model-cards-container">
              {deploymentModels.map((model, i) => (
                <ModelCard
                  key={`${model.id}-${i}`}
                  deployment={deployment}
                  model={model}
                  pendingDeploy={pendingDeploy}
                  pendingPause={pendingPause}
                  setBusy={setBusy}
                  updateDeployment={updateDeployment} />
              ))}
            </div>
            {requireScope(scope, [Scopes.Deployments]) &&
              <div
                className='add-model clickable'
                onClick={() => {
                  setShowAddModelDialog(true);
                }}>
                <AddModel className='add-icon' />
                <div>{t('addModel')}</div>
              </div>}
          </Grid>
          <SchemaTable
            currentPage={modelVariablesPage}
            multiModel
            schemas={deploymentModels.map(m => ({ ...m.schema, model: m.name }))}
            sortAsc={modelVariablesSortAscending}
            sortProperty={modelVariablesSortProperty}
            update={(settings) => {
              const keyMap = { page: 'modelVariablesPage', sortProperty: 'modelVariablesSortProperty', sortAscending: 'modelVariablesSortAscending' };
              const newSettings = Object.fromEntries(
                Object.keys(settings).map(key => [keyMap[key], settings[key]]));
              updateUserDeploymentSettings(newSettings);
            }} />
        </Grid>
      </Container>
      {showAddModelDialog && <>
        {deploymentModels.length < 3 ?
          <AddChallengerDialog
            addChallenger={async modelId => {
              try {
                setBusy(true);
                await Deployments.addChallenger(deployment.id, modelId);
                await updateDeployment();
                setShowAddModelDialog(false);
              } finally {
                setBusy(false);
              }
            }}
            cancel={() => setShowAddModelDialog(false)}
            champion={champion}
            deployment={deployment} /> :
          <ValidationDialog
            description={t('maximumNumberOfModelsReached')}
            header={t('modelLimit')}
            onSubmit={() => setShowAddModelDialog(false)} />}
      </>}
    </>
  );
};

export default DeploymentModels;