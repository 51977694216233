import React, { useState, useContext, useEffect } from 'react';
import AuditLog from './AuditLog';
import Models from '../api/models';
import { useTranslation } from 'react-i18next';
import AppResources from '../resources/app';
import { FlagsContext } from '../utils/context';
import Settings, { getDefaultUserAppSettings } from '../api/settings';

const SystemAuditLog = ({ setBusy, busy }) => {
  const flags = useContext(FlagsContext);
  const systemId = '00000000-0000-0000-0000-000000000000';
  const [t] = useTranslation();
  const [userSettings, setUserSettings] = useState({});

  useEffect(() => {
    document.title = `${AppResources.ApplicationName} - ${t('systemAuditLog')}`;
  });

  useEffect(() => {
    let current = true;
    (async () => {
      if (current) {
        if (flags.isr) {
          const { auditLogFilter, auditLogPage } = await Settings.getUserApplicationSettings();
          setUserSettings({ auditLogFilter, auditLogPage });
        } else {
          setUserSettings({
            auditLogFilter: getDefaultUserAppSettings('auditLogFilter'),
            auditLogPage  : getDefaultUserAppSettings('auditLogPage') });
        }
      }
    })();
    return () => current = false;
  }, [flags.isr]);


  return (
    <>
      <h3 className="system-audit-log-header">{t('systemAuditLog')}</h3>
      <AuditLog
        allowRefresh={true}
        busy={busy}
        getAuditLog={Models.getAuditLog}
        id={systemId}
        setBusy={setBusy}
        updateSettings={(payload) => {
          setUserSettings(curr => {
            return { ...curr, ...payload };
          });
          flags.isr && Settings.patchUserApplicationSettings(payload);
        }}
        userSettings={userSettings} />
    </>
  );
};

export default SystemAuditLog;