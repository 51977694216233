import React, { useState } from 'react';
import { Button } from '@mtb/ui';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const MAX_EMAILS = 100;

const EmailChips = ({ emails, removeEmail }) => {
  return (
    <div className='chip-spacing'>
      {emails.map(email => (
        <div
          key={email}
          className="email-chip">
          {email}
          <button
            className="remove-btn"
            type="button"
            onClick={() => removeEmail(email)}>
            &times;
          </button>
        </div>)
      )} </div>
  );
};

const EmailSelector = ({ emails, setEmails }) => {
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const [t] = useTranslation();
  const removeEmail = emailToRemove => setEmails(emails.filter(email => email !== emailToRemove));

  const isAddress = email => /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);

  const isInList = email => emails.includes(email);

  const isValid = (email, newEmails) => {
    let error = null;
    if (!isAddress(email)) {
      error = t('validateEmailHasValidAddress', { email });
    } else if (isInList(email)) {
      error = t('validateEmailAlreadyAdded', { email });
    } else if (emails.length >= MAX_EMAILS) {
      error = t('validateEmailLimitReached', { max: MAX_EMAILS });
    } else if (newEmails.filter(e => e === email).length > 1) {
      error = t('duplicateEmailAddress');
    }

    if (error) {
      setErrorMessage(error);
      return false;
    }

    return true;
  };

  const validateInput = () => {
    const newEmails = inputText.trim().split(/[ ,;]+/).filter(email => email.length);
    const valid = newEmails.reduce((result, email) => result && email && isValid(email, newEmails), true);
    if (valid) {
      setEmails(emails.concat(newEmails));
      setInputText('');
      setErrorMessage(null);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      validateInput();
    }
  };

  return (
    <div className="email-alerts">
      <div
        className='email-input'>
        <input
          className={classNames('input', { 'has-error': errorMessage })}
          maxLength={100}
          value={inputText}
          onBlur={() => setErrorMessage(null)}
          onChange={e => setInputText(e.target.value)}
          onKeyDown={handleKeyDown} />
        <Button
          size='small'
          variant='contained'
          onClick={validateInput}>{t('add')}
        </Button>
      </div>
      {errorMessage &&
        <p className="error">
          {errorMessage}
        </p>}
      {emails.length > 0 && (
        <EmailChips
          emails={emails}
          removeEmail={removeEmail} />
      )}
    </div>
  );
};

export default EmailSelector;