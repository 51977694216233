/* eslint-disable max-len */
import classNames from 'classnames';
import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import BaselineDataNotCurrentDialog from './BaselineDataNotCurrentDialog';
import DownloadDataDialog from './DownloadDataDialog';
import ValidationDialog from './ValidationDialog';
import ImportBaselineDataDialog from './ImportBaselineDataDialog';
import ModelImportDialog, { publishModel } from './ModelImportDialog';
import { serializeError } from 'serialize-error';
import { FlexTableHeader } from './FlexTable';
import { FileStatuses } from './FileUpload';
import LoadingWrapper from './LoadingWrapper';
import ModelBusy from './ModelBusy';
import Models from '../api/models';
import Deployments from '../api/deployments';
import Confirm from './confirm';
import AppResources from '../resources/app';
import { Scopes, requireScope } from '../utils/scopes';
import './grids.scss';
import './flex-table.scss';
import {
  AddEndpointIcon,
  DownloadIcon,
  RemoveIcon,
  WorksheetIcon,
  OvalIcon
} from '../images';
import { toLocalizedArray } from '../utils/locales';
import { modelTypeMap } from '../utils/model-file-utils';
import { convertToUTC, sortHelper } from '../utils/model-utils';
import { getErrorMessage } from '../utils/errors/download-errors';
import { ErrorsContext, FlagsContext, ScopeContext, SettingsContext } from '../utils/context';
import { SessionContext, SessionStatus } from './SessionWrapper';
import Settings, { getDefaultUserAppSettings } from '../api/settings';
import { debounce } from '../api/api-utils';

export const Statuses = {
  Champion          : 'champion',
  ChampionInactive  : 'championInactive',
  Challenger        : 'challenger',
  ChallengerInactive: 'challengerInactive',
  Creating          : 'creating',
  Deleting          : 'deleting',
  Deployed          : 'deployed',
  Destroying        : 'destroying',
  Failed            : 'failed',
  Pending           : 'pending',
  Published         : 'published',
  DeployQueued      : 'deployQueued',
  DestroyQueued     : 'destroyQueued',
  Uploading         : 'uploading',
  UploadFailed      : 'uploadFailed'
};

const StatusText = {
  [Statuses.Champion]          : 'deployedChampion',
  [Statuses.ChampionInactive]  : 'inactive',
  [Statuses.Challenger]        : 'deployed',
  [Statuses.ChallengerInactive]: 'inactive',
  [Statuses.Creating]          : 'creatingAction',
  [Statuses.Deleting]          : 'deletingAction',
  [Statuses.Destroying]        : 'destroyingAction',
  [Statuses.Failed]            : 'failed',
  [Statuses.Pending]           : 'pending',
  [Statuses.Published]         : 'ready',
  [Statuses.DeployQueued]      : 'queued',
  [Statuses.DestroyQueued]     : 'queued',
  [Statuses.Uploading]         : 'importingAction',
  [Statuses.UploadFailed]      : 'uploadFailed'
};

const IconClasses = {
  [Statuses.Champion]          : <OvalIcon className="deployed" />,
  [Statuses.ChampionInactive]  : <OvalIcon className="published" />,
  [Statuses.Challenger]        : <OvalIcon className="deployed" />,
  [Statuses.ChallengerInactive]: <OvalIcon className="published" />,
  [Statuses.Creating]          : <OvalIcon className="creating" />,
  [Statuses.Deleting]          : <OvalIcon className="deleting" />,
  [Statuses.Destroying]        : <OvalIcon className="destroying" />,
  [Statuses.Failed]            : <OvalIcon className="failed" />,
  [Statuses.Pending]           : <OvalIcon className="pending" />,
  [Statuses.DeployQueued]      : <OvalIcon className="queued" />,
  [Statuses.DestroyQueued]     : <OvalIcon className="queued" />,
  [Statuses.UploadFailed]      : <OvalIcon className="failed" />,
  [Statuses.Uploading]         : <OvalIcon className="creating" />
};
const mapStatusToIcon = status => IconClasses[status] || IconClasses.failed;

const ModelRepository = ({ setBusy }) => {
  const [dialog, setDialog] = useState();
  const [importingModel, setImportingModel] = useState(false);
  const [downloadingBaselineForModel, setDownloadingBaselineForModel] = useState();
  const [importingBaselineForModel, setImportingBaselineForModel] = useState();
  const [validationData, setValidationData] = useState({ header: '', description: '' });
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [isUploadingBaseline, setIsUploadingBaseline] = useState(false);
  const [sortAscending, setSortAscending] = useState();
  const [sortProperty, setSortProperty] = useState();
  const history = useHistory();
  const [t] = useTranslation();
  const flags = useContext(FlagsContext);
  const { onError } = useContext(ErrorsContext);
  const scope = useContext(ScopeContext);
  const settings = useContext(SettingsContext);
  const session = useContext(SessionContext);

  const getBaselineStatusText = useCallback(model => {
    switch (model.baselinestatus) {
      case 'valid':
      case 'invalid':
        return t('uploadSuccessful');
      default:
        return t('notAvailable');
    }
  }, [t]);

  const { data: deployments } = useQuery(['deployments'], async () => (await Deployments.getAll()).data, {
    onError                    : error => onError(serializeError(error)),
    refetchInterval            : 5000, // 5 seconds
    refetchIntervalInBackground: true,
    enabled                    : session?.sessionStatus !== SessionStatus.Expired,
    select                     : useCallback(deployments =>
      Object.fromEntries(deployments.map(deployment => [ deployment.id, { name: deployment.name, status: deployment.status } ])),
    [])
  });

  const modelSelect = useCallback(models => models.map(model => {
    if (model.deployment && deployments) {
      const deployment = deployments[model.deployment];
      model.status = model.deploymentrole;
      model.deploymentName = deployment?.name ?? t('none');
      if (deployment?.status === Statuses.Published) {
        model.status += 'Inactive';
      }
    } else {
      model.deploymentName = t('none');
    }
    return model;
  }), [deployments, t]);

  const { data: models, refetch: reloadModels } = useQuery(['models'], async () => (await Models.getAll()).data,
    {
      onError: error => {
        onError(serializeError(error));
      },
      refetchInterval            : 5000, // 5 seconds
      refetchIntervalInBackground: true,
      enabled                    : session?.sessionStatus !== SessionStatus.Expired,
      select                     : modelSelect
    });

  const { totalDeployed, totalInactive, totalReady, totalFailed, totalRows } = useMemo(() => {
    if (models) {
      let deployed = 0;
      let inactive = 0;
      let ready = 0;
      let failed = 0;
      models.forEach(model => {
        try {
          if (model.status === Statuses.UploadFailed || model.status === Statuses.Failed) {
            failed++;
          } else if (model.deployment) {
            if (model.status.includes('Inactive')) {
              inactive++;
            } else {
              deployed++;
            }
          } else {
            ready++;
          }
        } catch (err) {
          // Bad state; model is in a deployment that doesn't exist
          console.error(`Unknown deployment for model id: ${model.id}`);
        }
      });
      return { totalDeployed: deployed, totalInactive: inactive, totalReady: ready, totalFailed: failed, totalRows: models.length };
    }
    return { totalDeployed: 0, totalInactive: 0, totalReady: 0, totalFailed: 0, totalRows: 0 };
  }, [models]);

  const getSortFunc = useCallback(() => {
    if (models?.length > 0 && models.some(model => model[sortProperty] !== undefined)) {
      switch (sortProperty) {
        case 'createdon':
          return (a, b) => sortHelper(new Date(a[sortProperty]), new Date(b[sortProperty]), sortAscending);
        case 'type':
          return (a, b) => sortHelper(modelTypeMap[a.type], modelTypeMap[b.type], sortAscending);
        case 'deployment':
          return (a, b) => sortHelper(a.deploymentName, b.deploymentName, sortAscending);
        case 'status':
          return (a, b) => sortHelper(StatusText[a.status], StatusText[b.status], sortAscending);
        case 'baseline':
          return (a, b) => sortHelper(getBaselineStatusText(a), getBaselineStatusText(b), sortAscending);
        default:
          return (a, b) => sortHelper(a[sortProperty], b[sortProperty], sortAscending);
      }
    }
    return () => 0;
  }, [sortProperty, sortAscending, models, getBaselineStatusText]);

  useEffect(() => {
    document.title = `${AppResources.ApplicationName} - ${t('modelRepository')}`;
  });

  useEffect(() => {
    if (flags.isr) {
      let current = true;
      (async() => {
        const { modelsSortAscending, modelsSortProperty } = await Settings.getUserApplicationSettings();
        if (current) {
          if (modelsSortProperty === 'createdbyname' && !flags.modelenhancements) {
            // TODO: delete if when removing modelenhancements flag
            setSortAscending(false);
            setSortProperty('createdon');
            await Settings.patchUserApplicationSettings({ modelsSortProperty: 'createdon', modelsSortAscending: false });
          } else {
            setSortAscending(modelsSortAscending);
            setSortProperty(modelsSortProperty);
          }
        }
      })();
      return () => current = false;
    }
    setSortAscending(getDefaultUserAppSettings('modelsSortAscending'));
    setSortProperty(getDefaultUserAppSettings('modelsSortProperty'));
  }, [flags]);

  const debouncePatch = useMemo(() =>
    debounce(async (settings) => await Settings.patchUserApplicationSettings(settings), 400), []);

  const toggleSort = useCallback(property => {
    const newOrder = sortProperty === property ? !sortAscending : true;
    setSortAscending(newOrder);
    setSortProperty(property);
    flags.isr && debouncePatch({ modelsSortAscending: newOrder, modelsSortProperty: property });
  }, [flags, debouncePatch, sortAscending, sortProperty]);

  const tryExecute = async action => {
    try {
      setDialog();
      setBusy(true);
      await action();
    } catch (err) {
      onError(serializeError(err));
    } finally {
      await reloadModels();
      setBusy(false);
    }
  };

  const remove = modelId => tryExecute(async () => await Models.delete(modelId));

  const showDialog = async (action, confirmationText, title) => {
    setDialog(<Confirm
      cancel={() => setDialog()}
      confirm={action}
      confirmationText={confirmationText}
      t={t}
      title={title} />);
  };

  const showRemoveModelDialog = async id => {
    showDialog(async () => await remove(id), 'confirmDelete', 'deleteModel');
  };

  const showDownloadBaselineDataDialog = async id => {
    setDownloadingBaselineForModel(await Models.get(id));
  };

  const showImportBaselineDataDialog = async id => {
    setImportingBaselineForModel(await Models.get(id));
  };

  const onPublish = async (model, metadata, includeTrainingData, showBaselineDataNotCurrent) => {
    try {
      const { success, id } = await publishModel(model, metadata, includeTrainingData);
      if (success) {
        if (showBaselineDataNotCurrent) {
          setDialog(<BaselineDataNotCurrentDialog
            onSubmit={() => setDialog()} />);
        }
        await reloadModels();
        setImportingModel(false);
      }
      return { success, id };
    } catch (err) {
      if (err.body.errorCode) {
        // if we got a specific error keep bubbling out
        throw err;
      }
      onError(serializeError(err));
      return { success: false };
    } finally {
      setBusy(false);
    }
  };

  const onDownloadError = (errorCode) => {
    const description = getErrorMessage(t, errorCode);
    setValidationData({ header: t('actionCantBeCompleted'), description });
    setShowValidationDialog(true);
  };

  return <>
    <h3>{t('modelRepository')}</h3>
    <div className="toolbar">
      {requireScope(scope, Scopes.Models) && <>
        <div onClick={() => setImportingModel(true)}>
          <AddEndpointIcon />
          {t('importAModel')}
        </div>
      </>}
      <div className="gridStats noClick">
        <b>{t('modelRepositoryCounts', { totalRows })}</b>
        {toLocalizedArray(
          t('modelRepositoryCountsInfo', { totalRows, totalDeployed, totalInactive, totalReady, totalFailed }),
          settings?.locale?.regionCode)}
      </div>
    </div>

    <div className="table-container">
      <LoadingWrapper
        caption={t('loadingCurrentModels')}
        isLoading={!models || sortProperty === undefined} />
      {models?.length === 0 && <p>{t('noModelsPublished')}</p>}
      {models?.length > 0 && (
        <FlexTableHeader
          columns={{
            name      : 'modelName',
            type      : 'modelType',
            status    : 'status',
            deployment: 'deployment',
            baseline  : 'baselineData',
            createdon : 'created',
            ...(flags.modelenhancements && { createdbyname: 'createdBy' })
          }}
          sortAscending={sortAscending}
          sortProperty={sortProperty}
          t={t}
          toggleSort={toggleSort} />
      )}
      { models &&
      models
        ?.toSorted(getSortFunc())
        ?.map((model) => {
          const uploadingBaseline = [Statuses.DeployQueued, Statuses.DestroyQueued, Statuses.Pending, Statuses.Deployed].includes(model.baselinestatus);
          const active = model.active === 'true' || uploadingBaseline;
          const ready = model.status === Statuses.Published;
          return (
            <div
              key={model.id}
              className="flex-table row">
              <div
                className={classNames('flex-cell', { 'link': model.id && flags.modelenhancements &&
                  ![Statuses.UploadFailed, Statuses.Failed, Statuses.Uploading].includes(model.status) })}
                title={model.name}>
                <div
                  onClick={() =>
                    flags.modelenhancements &&
                  ![Statuses.UploadFailed, Statuses.Failed, Statuses.Uploading].includes(model.status)
                  && history.push(`/dashboard/models/${model.id}`)}>
                  <span>{model.name}</span>
                </div>
              </div>
              <div className="flex-cell"><span>{modelTypeMap[model.type] || '* '.concat(model.type)}</span></div>
              <div className="flex-cell">
                <div className='status'>
                  {!ready && mapStatusToIcon(model.status)}
                  <span className={classNames({ ready })}>
                    {t(StatusText[model.status] ?? 'unknown')}
                  </span>
                </div>
              </div>
              <div className={classNames('flex-cell', { 'link': model.deployment })}>
                <span>
                  <div onClick={model.deployment && (() => history.push(`/dashboard/deployments/${model.deployment}`))}>
                    <span>{model.deploymentName}</span>
                  </div>
                </span>
              </div>
              <div className="flex-cell">
                <span>{getBaselineStatusText(model)}</span>
              </div>
              <div className="flex-cell">
                <span id="createdon">{convertToUTC(model.createdon, true)}</span>
              </div>
              {flags.modelenhancements &&
              <div
                className="flex-cell"
                data-testid='createdbyname-cell'>
                <span>{model.createdbyname}</span>
              </div>}
              <div className="flex-cell icons">
                <div className="actionLink">
                  {![Statuses.Uploading, Statuses.UploadFailed, Statuses.Deleting].includes(model.status) && <>
                    {model.baselinestatus === FileStatuses.Valid && !uploadingBaseline &&
                    requireScope(scope, Scopes.ModelsRead) && (
                      <div
                        className='actionLinkItem'
                        id='download-baseline'
                        title={t('downloadBaselineData')}
                        onClick={() => showDownloadBaselineDataDialog(model.id)}>
                        <DownloadIcon className='gridIcon' />
                      </div>
                    )}
                    {requireScope(scope, Scopes.Models) && <div
                      className={classNames('actionLinkItem', { disabled: uploadingBaseline })}
                      title={t(uploadingBaseline ? 'modelBaselineBusy' : 'importBaselineData')}
                      onClick={() => !uploadingBaseline && showImportBaselineDataDialog(model.id)}>
                      <WorksheetIcon className={classNames('gridIcon', { disabled: uploadingBaseline })} />
                    </div>}
                  </>}
                  {[Statuses.Published, Statuses.UploadFailed, Statuses.Failed]
                    .includes(model.status) && !model.deployment &&
                    requireScope(scope, Scopes.Models) && (
                    <div
                      className={classNames('actionLinkItem', { 'disabled': active })}
                      title={t(active ? 'activeModel' : 'delete')}
                      onClick={() => !active && showRemoveModelDialog(model.id)}>
                      <RemoveIcon className={classNames('gridIcon', { 'disabled': active })} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
    {models?.some(model => !!modelTypeMap[model.type] === false) && <div>* {t('unsupportedModelType')}</div>}
    {dialog}
    {importingModel && (
      <ModelImportDialog
        acceptedFileTypes={flags.enablespm ? ['.MPX', '.GRV'] : ['.MPX']}
        currentModels={models}
        reloadModels={reloadModels}
        onCancel={() => setImportingModel(false)}
        onPublish={onPublish} />
    )}
    {isUploadingBaseline &&
      <ModelBusy title={t('uploadingBaselineDataFor', { name: importingBaselineForModel.name })} />}
    {downloadingBaselineForModel?.id && (
      <DownloadDataDialog
        busyHeader={t('downloadingBaselineData')}
        closeDialog={() => setDownloadingBaselineForModel({ name: downloadingBaselineForModel.name })}
        download={Models.downloadBaselineData}
        header={t('downloadBaselineData')}
        id={downloadingBaselineForModel.id}
        name={`${downloadingBaselineForModel.name} ${t('baselineData')}`}
        prepareDownload={Models.prepareBaselineDataDownload}
        onDownloadError={onDownloadError} />
    )}
    {importingBaselineForModel?.id && (
      <ImportBaselineDataDialog
        closeDialog={() => setImportingBaselineForModel({ name: importingBaselineForModel.name })}
        modelId={importingBaselineForModel.id}
        modelName={importingBaselineForModel.name}
        schema={importingBaselineForModel.schema}
        setBusy={setBusy}
        setIsUploadingBaseline={setIsUploadingBaseline}
        update={reloadModels} />
    )}
    {showValidationDialog && (
      <ValidationDialog
        description={validationData.description}
        header={validationData.header}
        onSubmit={() => setShowValidationDialog(false)} />
    )}
  </>;
};

export default ModelRepository;
