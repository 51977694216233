import React from 'react';
import { FilterIcon } from '../images';
import { MenuItem, Select } from '@mtb/ui';

export const FilterStatuses = {
  AllKeys: 'allKeys',
  Active : 'active',
  Revoked: 'revoked',
  Expired: 'expired'
};

export const applyFilter = (objects, status) => {
  if (status === FilterStatuses.AllKeys) {
    return objects;
  }
  return objects.filter(k => k.status === status);
};

const Filter = ({ filterStatus, onFilterStatusChanged, t }) => {
  return (
    <div className='filter noPad noHover'>
      <FilterIcon className='noPad' />
      <Select
        className='modeler-select-field'
        value={filterStatus}
        onChange={({ target: { value } }) => onFilterStatusChanged(value)}>
        {Object.values(FilterStatuses).map(option => (
          <MenuItem
            key={option}
            value={option}>
            {t(option)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default Filter;