import _isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState, useCallback, useRef, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import LoadingWrapper from './LoadingWrapper';
import ValidationDialog from './ValidationDialog';
import { Scopes, requireScope } from '../utils/scopes';
import { ErrorsContext, ScopeContext } from '../utils/context';

import './Dashboard.scss';
import Models from '../api/models';
import Deployments from '../api/deployments';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SchemaTable from './SchemaTable';
import { Box, Button, Stack } from '@mtb/ui';
import { DownloadIcon, RemoveIcon, WorksheetIcon } from '../images';
import { serializeError } from 'serialize-error';
import { Statuses } from './ModelRepository';
import { FileStatuses } from './FileUpload';
import DownloadDataDialog from './DownloadDataDialog';
import { getErrorMessage } from '../utils/errors/download-errors';
import ImportBaselineDataDialog from './ImportBaselineDataDialog';
import ModelBusyDialog from './ModelBusy';
import Confirm from './confirm';
import { useQuery } from 'react-query';
import { SessionContext, SessionStatus } from './SessionWrapper';
import Settings from '../api/settings';
import { debounce } from '../api/api-utils';
import { convertToUTC } from '../utils/model-utils';

const DialogTypes = {
  Validation: 'validation',
  Download  : 'download',
  Upload    : 'uploadBaseline',
  Delete    : 'delete'
};

const LoadingStatus = ({ header }) => {
  return (
    <Stack
      align='center'
      className='loading-status container-bottom-right'
      direction='row'
      spacing={2}>
      <LoadingWrapper inline />
      <label
        className='model-loading-title'
        title={header}>
        {header}
      </label>
    </Stack>
  );
};


const ModelDashboard = ({ modelId, setBusy }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const scope = useContext(ScopeContext);
  const { onError } = useContext(ErrorsContext);
  const session = useContext(SessionContext);

  const modelTitleRef = useRef(null);
  const inputSpan = useRef();

  const [editModelName, setEditModelName] = useState(false);
  const [validationMessage, setValidationMessage] = useState({ header: '', description: '' });
  const [inputWidth, setInputWidth] = useState();
  const [showDialog, setShowDialog] = useState();
  const [isUploadingBaseline, setIsUploadingBaseline] = useState(false);
  const [userSettings, setUserSettings] = useState({});
  const { data: model, refetch: updateModel } = useQuery(`model-${modelId}`,
    async () => {
      const updatedModel = await Models.get(modelId);
      if (updatedModel.deployment) {
        const { name } = await Deployments.get(updatedModel.deployment, 'name');
        updatedModel.deploymentName = name;
      }

      model.name !== updatedModel.name && setModelName(updatedModel.name);
      return updatedModel;
    }, {
      initialData: {},
      onError    : error => {
        console.error(error);
        history.push('/models');
      },
      refetchInterval            : 5000, // 5 seconds
      refetchIntervalInBackground: true,
      retry                      : (failureCount, error) => error.status !== 404 && failureCount <= 3,
      enabled                    : session?.sessionStatus !== SessionStatus.Expired
    });
  const [modelName, setModelName] = useState(model.name);

  useEffect(() => {
    if (inputSpan.current) {
      setInputWidth(inputSpan.current.offsetWidth);
    }
  }, [modelName]);

  useEffect(() => {
    let current = true;
    (async() => {
      const settings = await Settings.getUserModelSettings(modelId);
      if (current) {
        setUserSettings(settings);
      }
    })();
    return () => current = false;
  }, [ modelId]);

  const debouncePatch = useMemo(() =>
    debounce(async (settings) => await Settings.patchUserModelSettings(modelId, settings), 400), [modelId]);


  const uploadingBaseline = useMemo(() => {
    return [Statuses.DeployQueued, Statuses.DestroyQueued, Statuses.Pending, Statuses.Deployed]
      .includes(model.baselinestatus);
  },
  [model.baselinestatus]);
  const active = useMemo(() => model.active === 'true' || uploadingBaseline, [model.active, uploadingBaseline]);

  const loadingStatusText = useMemo(() => {
    if (uploadingBaseline) {
      return t('uploadingBaselineData');
    } else if (model.status === Statuses.Deleting) {
      return t('deletingAction');
    }
  }, [model.status, t, uploadingBaseline]);

  const handleRenameModel = useCallback(async () => {
    if (modelName === model.name) {
      setEditModelName(false);
      return;
    }
    try {
      setBusy(true);
      await Models.patchModel(modelId, { name: modelName });
    } catch (e) {
      const error = serializeError(e);
      if (error.status === 400 && error.body.errorCode === 'InvalidMetadata') {
        setValidationMessage(!modelName.trim().length ?
          { header: t('renameModel'), description: t('modelNamesMustBeEntered') } :
          { header: t('duplicateModelName'), description: t('modelNamesMustBeUniqueEnterNewName') }
        );
        setShowDialog(DialogTypes.Validation);
        setModelName(model.name);
      } else {
        onError(error);
      }
    } finally {
      setEditModelName(false);
      setBusy(false);
    }
  }, [model.name, modelId, modelName, onError, setBusy, t]);

  const handleEditModelName = () => {
    setEditModelName(true);
    setTimeout(() => {
      modelTitleRef.current.select();
    }, 0);
  };

  const Link = ({ children }) => {
    return (
      <span
        className={classNames({ link: model.deploymentName })}
        onClick={() => model.deploymentName && history.push(`/dashboard/deployments/${model.deployment}`)}>
        &nbsp;{children}
      </span>);
  };

  const Dialog = useMemo(() => {
    switch (showDialog) {
      case DialogTypes.Validation:
        return <ValidationDialog
          description={validationMessage.description}
          header={validationMessage.header}
          onSubmit={() => setShowDialog()} />;
      case DialogTypes.Download:
        return <DownloadDataDialog
          busyHeader={t('downloadingBaselineData')}
          closeDialog={() => setShowDialog()}
          download={Models.downloadBaselineData}
          header={t('downloadBaselineData')}
          id={modelId}
          name={`${modelName} ${t('baselineData')}`}
          prepareDownload={Models.prepareBaselineDataDownload}
          onDownloadError={(errorCode) => {
            setValidationMessage({ header: t('actionCantBeCompleted'), description: getErrorMessage(t, errorCode) });
            setShowDialog(DialogTypes.Validation);
          }} />;
      case DialogTypes.Upload:
        return <ImportBaselineDataDialog
          closeDialog={() => setShowDialog()}
          modelId={modelId}
          modelName={modelName}
          schema={model.schema}
          setBusy={setBusy}
          setIsUploadingBaseline={setIsUploadingBaseline}
          update={updateModel} />;
      case DialogTypes.Delete:
        return <Confirm
          cancel={() => setShowDialog()}
          confirm={async () => {
            await Models.delete(modelId);
            history.push('/models');
          }}
          confirmationText='confirmDelete'
          t={t}
          title='deleteModel' />;
      default:
        // Don't show dialog
    }
  }, [history, model.schema, modelId, modelName, setBusy, updateModel,
    showDialog, t, validationMessage.description, validationMessage.header]);

  return !(Object.keys(model).length && Object.keys(userSettings).length) ?
    <LoadingWrapper caption={t('loading')} /> :
    <Box>
      {model &&
          <Stack
            className='dashboard-header'
            direction='row'>
            <Stack>
              <span className='dashboard-label'>{t('modelSummary')}</span>
              <div className='overview'>
                <div className='title-container'>
                  {editModelName
                    ? <div style={{ position: 'relative' }}>
                      <input
                        ref={modelTitleRef}
                        className='title editing'
                        maxLength={50}
                        style={{ width: inputWidth }}
                        value={modelName}
                        onBlur={handleRenameModel}
                        onChange={(e) => setModelName(e.target.value)} />
                      <div className='title hidden'>
                        <span
                          ref={inputSpan}
                          style={{ whiteSpace: 'pre' }}>{modelName}</span>
                      </div>
                    </div>
                    : <>
                      <div
                        className='title'
                        onDoubleClick={requireScope(scope, Scopes.Deployments)
                          ? () => handleEditModelName()
                          : undefined
                        }>
                        <span ref={inputSpan}>{modelName}</span>
                      </div>
                      {requireScope(scope, Scopes.Deployments) &&
                      <div
                        className='edit-button clickable'
                        onClick={() => handleEditModelName()}>
                        {t('edit')}
                      </div>
                      }
                    </>
                  }
                </div>
              </div>
              <Stack
                className='model-information'
                direction='row'
                spacing={5}>
                <span>
                  {t('createdByFormattedLabel', { name: model?.createdbyname })}
                </span>
                <span
                  data-testid='createdon'>
                  {t('createdFormattedLabel', { datetime: convertToUTC(model?.createdon, true) })}
                </span>
                <span>
                  {t('modelTypeFormattedLabel', { type: t(model.type) })}
                </span>
                <span>
                  <Trans
                    components={[<Link />]}
                    i18nKey="deploymentLabel"
                    t={t}
                    values={{ name: model.deploymentName ?? t('none') }} />
                </span>
              </Stack>
            </Stack>
            {loadingStatusText ?
              <LoadingStatus
                header={loadingStatusText} /> :
              <Stack
                className='container-bottom-right'
                direction="row"
                spacing={1}>
                {requireScope(scope, Scopes.ModelsRead) &&
                <span title={t('downloadBaselineData')}>
                  <Button
                    color='default'
                    disabled={model.baselinestatus !== FileStatuses.Valid || uploadingBaseline}
                    size='small'
                    onClick={() => setShowDialog(DialogTypes.Download)}>
                    <DownloadIcon />{t('download')}
                  </Button>
                </span>}
                {requireScope(scope, Scopes.Models) && <>
                  <span title={t('importBaselineData')}>
                    <Button
                      color='default'
                      icon={<WorksheetIcon />}
                      size='small'
                      onClick={() => setShowDialog(DialogTypes.Upload)} />
                  </span>
                  <span title={t(active && !model.deployment ? 'activeModel' : 'delete')}>
                    <Button
                      color='default'
                      disabled={!!(model.deployment || model.status !== Statuses.Published || active)}
                      icon={<RemoveIcon />}
                      size='small'
                      onClick={() => setShowDialog(DialogTypes.Delete)} />
                  </span>
                </>}
              </Stack>}
          </Stack>
      }
      {!!Object.keys(model.schema?.predictors ?? {}).length && <SchemaTable
        currentPage={userSettings.schemaPage}
        schemas={[{ ...model.schema, name: model.name }]}
        sortAsc={userSettings.schemaSortAscending}
        sortProperty={userSettings.schemaSortProperty}
        update={(settings) => {
          const keyMap = { sortAscending: 'schemaSortAscending', sortProperty: 'schemaSortProperty', page: 'schemaPage' };
          const newSettings = Object.fromEntries(
            Object.keys(settings).map(key => [keyMap[key], settings[key]]));
          setUserSettings(settings => ({ ...settings, ...newSettings }));
          debouncePatch(newSettings);
        }} />}
      {isUploadingBaseline ?
        <ModelBusyDialog title={t('uploadingBaselineDataFor', { name: modelName })} />
        : Dialog}
    </Box>;
};

export default ModelDashboard;