import i18n from 'i18next';

export const init18n = (language = 'en') =>
  i18n.init({
    react: {
      nsMode     : 'fallback',
      bindI18n   : 'languageChanged loaded',
      wait       : true,
      useSuspense: false,
    },
    lng          : language,
    fallbackLng  : 'en',
    wait         : true,
    ns           : ['shared', 'softwareUpdates'],
    defaultNS    : 'shared',
    // debug: true,
    interpolation: {
      escapeValue: false,
    },
  },
  () => {
    injectResources(i18n, language);
  });

export async function changeLanguage(language) {
  if (i18n.language !== language) {
    await i18n.changeLanguage(language);
    injectResources(i18n, language);
  }
}

export function preloadNamespaces(namespaces) {
  return i18n.loadNamespaces(namespaces);
}

export function getLoadedNamespaces() {
  return i18n.options.ns;
}

export function injectResources(i18nInstance, language) {
  try {
    i18nInstance.addResources(language, 'shared', require(`../../public/locales/${language}/shared.json`));
    i18nInstance.addResources(language, 'softwareUpdates', require(`../../public/locales/${language}/softwareUpdates.json`));
  } catch (e) {
    console.error('Language does not exist');
  }
}

export default i18n;
