import { apiFetch } from './api-utils';

/*

Defines API method calls for API Keys.

*/

const Keys = {
  // Create a key
  create: async (endDate, keyName, scopes, target) => await apiFetch('/api/keys', { 
    method: 'POST', 
    body  : JSON.stringify({
      enddate: endDate, 
      keyname: keyName, 
      scopes,
      target
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  // Delete a key
  delete: async id => await fetch(`/api/keys/${id}`, { method: 'DELETE' }),

  // Patch a key
  patch: async (id, metadata) => await fetch(`/api/keys/${id}`, {
    method : 'PATCH',
    body   : JSON.stringify(metadata),
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  // Returns all known keys
  getAll: async (target) => {
    const params = (target && `?${ new URLSearchParams({ target })}`) || ''; 
    return await apiFetch(`/api/keys${params}`);
  }
};

export default Keys;