import { apiFetch } from './api-utils';

/*

Defines API method calls for Reports.

*/
const Reports = {
  // Get a drift report for a deployment ID in the specified time frame.
  getDriftData: async (id, start, end, period) => await apiFetch(`/api/reports/drift/deployments/${id}/download?${new URLSearchParams({ start, end, period })}`),

  // Get drift report properties for a deployment ID in the specified time frame.
  getDriftProperties: async (id, start, end, period) => await apiFetch(`/api/reports/drift/deployments/${id}?${new URLSearchParams({ start, end, period })}`),

  // Generate drift report data for a deployment.
  generateDriftReport: async (id, start, end, period) =>
    await apiFetch(`/api/reports/drift/deployments/${id}?${new URLSearchParams({ start, end, period })}`, { method: 'POST' }),

  // Get stability report data for a model ID in the specified time frame.
  getStabilityData: async (strategy, id, start, end, period) => await apiFetch(`/api/reports/stability/${strategy}/${id}/download?${new URLSearchParams({ start, end, period })}`),

  // Get stability report properties for a model ID in the specified time frame.
  getStabilityProperties: async (strategy, id, start, end, period) => await apiFetch(`/api/reports/stability/${strategy}/${id}?${new URLSearchParams({ start, end, period })}`),

  // Generate stability report data for either a deployment or model.
  generateStabilityReport: async (strategy, id, start, end, period) =>
    await apiFetch(`/api/reports/stability/${strategy}/${id}?${new URLSearchParams({ start, end, period })}`, { method: 'POST' }),
};

export default Reports;