import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TableContainer } from '@mtb/ui';
import './Integration.scss';
import { RequestTypes, DataTypes } from './Integration';
import {
  INITIAL_NUM_MULTI_ROWS,
  MAX_VARIABLE_ID_LENGTH,
  MAX_TEXT_INPUT_LENGTH,
  getValidationMessage,
  IntegrationFormSingle,
  IntegrationFormMulti,
  IntegrationFormFile,
} from './IntegrationForm';
import { InputTypes } from './Inputs';

const getColumnInfo = (variableId, timestampId, predictors) => {
  return [
    {
      name     : variableId,
      dataType : InputTypes.Text,
      maxLength: MAX_VARIABLE_ID_LENGTH
    },
    {
      name     : timestampId,
      dataType : InputTypes.Text,
      maxLength: MAX_TEXT_INPUT_LENGTH
    },
    ...Object.entries(predictors).map(([name, values]) => {
      if (values.type === 'categorical') {
        values.classes = values.classes.sort();
      }
      return {
        name,
        maxLength: MAX_TEXT_INPUT_LENGTH,
        ...values
      };
    })
  ];
};

// Generates an array of objects, each object containing all properties needed for one row
// (i.e. all predictor values, an ID, and a timestamp.)
const generateInitialValues = (variableId, timestampId, predictors) => {
  const initialValues = [];
  for (let i = 0; i < INITIAL_NUM_MULTI_ROWS; i++) {
    initialValues.push({
      [variableId] : { value: `UniqueID_${(i + 1).toString().padStart(3, '0')}`, type: InputTypes.Text },
      [timestampId]: { value: moment.utc().format('YYYY-MM-DD HH:mm:ss[Z]'), type: InputTypes.Text },
      ...Object.entries(predictors).reduce(
        (prev, [name, { type, classes }]) =>
          ({ ...prev, [name]: type === 'continuous' ?
            { value: 1.23, type: InputTypes.Numeric } :
            { value: classes[0], type: InputTypes.Text } }), {})
    });
  }
  return initialValues;
};

const PredictionForm = props => {
  const {
    t,
    variableId,
    timestampId,
    predictors,
    values,
    setValues,
    formType,
    dataFile,
    setDataFile,
    uploadData,
    uploadRef,
    setValidationMessage,
    setResults
  } = props;

  const [columnInfo, setColumnInfo] = useState([]);

  useEffect(() => {
    // Populate the form/grid with initial values if not set.
    if (variableId && timestampId && Object.keys(predictors ?? []).length && !Object.keys(values?.[0] ?? {}).length) {
      setColumnInfo(getColumnInfo(variableId, timestampId, predictors));
      setValues(generateInitialValues(variableId, timestampId, predictors));
    }
  }, [predictors, variableId, timestampId, values, setValues]);

  useEffect(() => {
    setValues([]);
  }, [formType, setValues]);

  useEffect(() => {
    setValidationMessage(getValidationMessage(t, values, { ...predictors }));
  }, [t, setValidationMessage, values, predictors]);

  const setRowValue = (property, value, row = 0) => {
    setValues(v => {
      const newValues = v.map((v, i) => {
        if (i === row) {
          return { ...v, [property]: value };
        }
        return v;
      });
      return newValues;
    });
  };

  return (
    <TableContainer>
      {formType === DataTypes.SingleRow &&
        <IntegrationFormSingle
          columnInfo={columnInfo}
          predictors={predictors}
          setValue={setRowValue}
          timestampId={timestampId}
          type={RequestTypes.Prediction}
          values={values}
          variableId={variableId} />}
      {formType === DataTypes.MultipleRows &&
        <IntegrationFormMulti
          columnInfo={columnInfo}
          setValue={setRowValue}
          timestampId={timestampId}
          type={RequestTypes.Prediction}
          values={values} />}
      {formType === DataTypes.DataFile &&
        <IntegrationFormFile
          ref={uploadRef}
          dataFile={dataFile}
          setDataFile={setDataFile}
          setResults={setResults}
          setValidationMessage={setValidationMessage}
          t={t}
          type={RequestTypes.Prediction}
          uploadData={uploadData} />}
    </TableContainer>);
};

export default PredictionForm;