/* eslint-disable no-multi-str */
const encoded = 'iVBORw0KGgoAAAANSUhEUgAAASwAAAAjCAYAAAAuXDcUAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAA\
dTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH5gcSDyoRSn663AAAGSFJREFUeNrtnXl8XVW1x7/73Mxtk5aOtE1nbIvYg\
XkqYx+V4cMg+lAQ8IHgExTlIR/fExmlKJPP4lOZFBCwoIAgIHMpgxSwTZGidKLQgQ5JmmZOmpvc/f747dN7cnLvzb25t02rWZ9PPje555y1915r7TWfHQ\
NQMeYUdgfYf90zvT2FPuiDPtiNIc995gP7A0MBu4vnYIBG4F2gubcJ0gd90Ae7L/gKayrwGDC8l+bRAnwVeLq3CdIHfdAHuy/4CmsAMBh5Wr0B+ci764M\
+6IN/YbA2HuAZY7pczwvem+VIgFGA10MMu5o4/yrQnRD0Qfews2i4K/DmGvfOgNB8jb6yXeadlwnSLgNYC7EEesYY8MxOZYCGyRx/rvDsYTAWOB1YDryw\
swbJ1SbZTXl0GHAI8DLwQQ7xHggcmS3eRDRLdX03oGd4bh5KTU1CCms98L61Nhqca+YKy1qstRjPI3/kUIomj6Fw7N5EBvTDtkVpXLuR5lXrYEM1tmV7L\
hVXMQobDbAdqARiPcBjgGFAEdDh8LSFiBe/OUeM7eVNOBn4GfAQO1FhAXuh9AJArbW2LtM1OjoVIh55KL9ZRe974J8HrgEuJrcK63jgJ8BFPcUbkC0PGA\
VMASYifkSAemAtMlgfA9v9Z3YTxWVQ0W8qEEX7cTIwEHiVwD7PSGH5XlXRhFEMPudEymYfTMGooXiFBTvuqdxSSevqNbByA/alJdi/Lse2tmE8L9tFHQD\
8GigANgHnWWtXZ0Jwx6QxaOOOBrahZP8/ArcNBk4FPgUWAO05YYmY8llgFvAa8CG7bhNapJx39niXAee7358GrkACmCmcAdyINturwH8SMCq9BDb02et4\
Q4rqIOA84ATkUYfz0RYZ57eQ/D8PNCcKu3oBioB9UKfAMUANUI0U7l7udyADheUrq9KjZjLq6q9TPGVc/Fq0nWjVNkxeHnl5EUxZfzh0KkybiPnLMmIPv\
YzdtDVbpVUCTHBzHgd8AbiVzBl9OnLBQRq8IHT9fOB2YCPwb3RWZtnO/1Zkqf8EnAW0dqJvAHYDIeoJDEG8wa3vPmvt0gzXMgC4AHkIAHuTTWb0nx8GAt\
8BLkFe6TrgceBvwBbknQxEXtfhwGnASagr4AZg5W6gtPLdPFuQwzAMuB+YiSKrHZC+hxWzlEzfh/K5l1A4biSxaDttazdROH4UbRsq+fiSmykcO4KBN3w\
dmlqxDc2YEYPg+P3xhgwkNu9x7MbqbJSWdT9NboFfBh6w1m5Jh9hOIQwGzkYhpSVxSFkD1CFr1JJDpnQgAWpCIU54bIM8Cn9evR0C9QT8OdcjoTsLbZy0\
QnfHoyPQxqpHymtPpMNOh4A83448q0rgOuB3KOxLFBkMRwbzcuAc5NV8A3ivl5fTjPbHWOB1tD8/g/i/NXhjWtrDWotXUsTwS79E4biR2FiM6t8+y0dfu\
55NP32YT2+6j5YPP6bhzb9RfevDxO55lthND2PXbAILZvoEzDnHYwoLuk0OdgMeCqX+AkwD5mT4/GwUK7+CknqJ1v8IYupZwCc5ZEorcCWybv9D1xBnKn\
LVL2fP9yieQxvoTGBMBjwvQCF6HvJCO3p7IbsjOHoWAVejiOBDpIBuAFbhlJUxZsePgy3AA4gvTwIHA3cAo7Pcl9lCO/AGUlh+GDgBeIdQM3l67k5HjJL\
PTqB01kwAjOdhO2JsX7uJzT+bT93L75I/fC9MYT4Nj75M7Ll3oK4RIhGIeGAt5tB9Yd+xiauK6YNBlve3yGp/lXiStzsoAc5F2vthAuFYCFqBt4GV5N66\
VyELUpXg2lDgi8i72NMV1lKktCahEKRbcBtmGnAiymUsIF35/NeEk1GifiNwKTLCNoGSAgh/9xHwbWAhyql+lyw6BrIFN68tKEz9EIW1jwCrw/emnqS12\
JjFFOYz4JgD8PoXE62sIbq5hpYPPgJr6XfAFIZddAYln5uIjbbTsGgZlXc9wfYtNdglK6C5FcqHYUpLMDMnYZeuVjtEz2NmD3gJqEC5qCOstc+nCgvdZj\
gUJfReR4nHSCaDZmKBEs3FGNOl5yaEM4YsTUei8YI4U80lg/A4KxzdQCvwIPAlFII/bK2t7gavh8L8QcigVJOGwsrFWnJFj2z5kgGUISVVjLyq19IdIyB\
3G4DrgenI8D/s5xsTyV42a0vnWTdGK/IQk+JOqrD8JHvhhFEM/8YZDDr9GACq579I1T1P0tHcSvGUcYy9/XKK9inf8VzRPmMoHDOCT75zO+33v4ApyMdc\
fibm6BmYiSMxhfnYtmg2LoRBHspDyJ39KrLGqapI+chlLkIbqZ7kXoyHyuodqMJlA9/7CXo/B5YPjES5hHY3ry3W2lgSRuY7mkfd/ZEAzkL3GUGC6IdDO\
+YRYvxeKCdRQrzNo8q/KZkQBXBEkFc31M2rAdgMNOSg5B1BRuENVLafjSxmqjmNR6HKKlRhPDDVACFalDpalDpaVTt6tKdKKIdwFAMjUILaOhxbgGgGPU\
4GFR6GI37W55CmwbEORAZ4BVLuNhO8AQX0FvBntDdORbks1wG+Qx63BwQvKHPNjsZbk60tVMUcgfKaxtF2s0/boNLqDhIrLKes+h+yH+U3fnNHRdDGYuQ\
PHYTXr4j2bfUMOuPYTsrKhwGzZlB69P7UPPYK7DsOM2gAxGKYgf2hqADaelLp7kxzlOP4Nso3TbPWLk60YEe0fYFTHENeIrV3NQklMv8KzCWuOIYA81BC\
/nuoReFbwFHIK4ghAX/J3bcqAe5/B/7DXX8a5a1uQZulDCmvI4FniCvKF4Cb3ToiSFi/AhwNlCPBakehwSvAr5BbnZCzDseRKPdxBBLACCoGrHbz+h2wP\
ovqkUEFiweRwjrX4W1Kcf8ZqMI4F7WtJPWuAhthLPLgTnF8K3H8qkJh/UPAgnDzYQjHIFRx/hKwH1J6FhVfFjscz5EkPRDAMw1VN2ejXqg8t97lwO+B+a\
iNJhcwC8nMK8DGnvDIKa02tI++guS4xM15L+B/3e9XODqfj3LGvsy1oTzwApSieS+JvExB++QEJGvG0XYRktU3rLVpK9yECstnwYDDp3VqXzCex5Cz59D\
49jJqNi6kcOzeiYkRieiaMZjjZmCmTYD2GLYgH/IicR3ec/AcsX4P/BCFEhUkrkZ5SFEMA25G2n1YCtxlSOjCWjUfdTs3Ik/gR45+b6CcQH/E9EuRm32u\
tfaTECMmoA38RID+pUhQ+gfGKSNOJb+sa9CGmIvydhXAo2gTDEJd2JciZXSutfbvCYSgAJXAr3LjvIMsbAvqSzsM+DHKpV0BvN5TpeU2xIvISBwNHGKtX\
ZBEcQxFiqfS8TSpSxNQEMcCt6HS9yrUV7TB0XIaUoCnAb8EfpykiXWyw3EiMjZvOV56aKMdjYokPyfBXgl0aJ+JZGskMnQvO5pORMrl547v37XWrs/Sy8\
pDihWkUHvSPB3k0QeoEjcRRQpNKBI5CqhFbUDXImPyN+AP7v7BqPfrMiQv1wG/tda2Bzy4KUjhz0R5yWfcfA9Ce/JoJLNPkma+OKHCMoA1hur5L1AyYzJ\
lxx24g0F1L79L87JVYGO0baxKiNTG3DVrsS9XYEcPxew3HlrbINqeq5SyRRv260hgfmWt/ShBrqccWc+PHWHSwdtB1wpVGwqbyoGbgPdRte8D4iXk0ch7\
+gJSLteGGBFuEvwAJU8NUhZ/RPmIC4gLYjDU3YIYfw9KmNYFrg1BFcbvI8/zW3QubXcgITwZbaob6VyF8dzaLnZ47kaWd2kWPKpG3tpPUej+BokbSU9AS\
uZ+4MPuciaoifhupCDmOnpsCNCsH1LcP0KVWet40QY75GJv4BdI8T2EeuSWB2hWgBTDlY6eW5PM5VikkKJIFv+EQkGIK5drkEw0oH6pbI5RKkSeSjuQrf\
LDrWsrCtkGoYS3despd7yrQzL5HPKOfBiMDMP1yCNrRnvSInm60PHqDpRr82k4EMnYZcjQPkOaDcaJ3W5jXFgIkdJ+nS41vbeC5jWfgjFse+o1tq/f0uX\
xpsUfUv9aBaaoAPPxZmxVHXgedn2VkvA5AMeo5UhAxjnCJeLeqajf5HHgkywYHEXJ5DJH+EuR9xBUChuQ4Nch97m0G5zt7t5a5Ln549QGfpqJ96A9g9ot\
nqKzsgIph9uQ53UMEqYgWLTBFwFfQx3kwY0TQ69vXIdeFdkH+G9CjXsZgkVKeDVSlPsmUET9UcjYivIx3QluCTIUE5A3eAPaZEFPowmF0hcgGbkEmBXKq\
VyEvJ75aOMEDQ9IuVWgLvv5aPOGoczRqB/agA8RV1Y4fO+58Rc63h2bg9Yev9EyF93/vlznEc9bgXi3F5LL8x1vakLPbgXuRcYxhtosyt36ClCkUQ/8hs\
4KvxalXU5DhjPtt0mSV2GsJX/oQIomjd7xlTGGwWcez/h536PwgCk0vb+KdVfeQf3CJUS31ND2aRU1Ty5k/Q9+QbSyhqHnncy4e6+i5Ihp2O1tULESG+3\
IZcWknbiQnA0M9YUh0Fh3jiP0o2ThPrtnfa/rAWCNT5NQKXkF8ubKkdeTFELPmc6XTCLcMZLngUDh4QdI0MrCw6GQ6xoUTpNkjCgKo5ag/OD0nhLM4VuH\
wgi/kXSHzDkeHY7CpoXAO2nIxnTkkb2LciDRJOvA0WIeCqHPJp67HIHSCJtRDrEuEQ6Hpw5tqkR5wYPd3J9AijkZbEZeWD5S3Nm0a3SgIksEhW7ZQgEyA\
lG6Nkq3O/q8DUnlBZSf/APKFZ/ovoshRVhMPIQNQj2q2DeSQftQ8qQ7UDBqWKf3BAGKJoyiaMIo2hqb+GTZSra9voSmig8pGDkU295B26eVxFrbiJT2p/\
S4gyg9YgaFbdtZ/8KbNC5ekU07QxdwocNilHw8FXk1DwZuOR65pI8C72epKI37aUa5jmSKtxVZk3HI8u4MKEAKcQLafGXEN8FniFvhIERQSFaRYu4+Tau\
R+38QSvK/ncVcY4j+F6Jcx13W2rVufL9RNB8ZnnRCpYOQAnoO2NrNOkD5pI1IuQxCnuhklLN5FheCJgOHZy3ybqeGLh+MvJJVwOe6mbdFm3MqUjQ9DQvb\
UFEiAozN5rWaQP5wCDJ2waKAhxqnn/XpkII+7SjSuQCF4vciBfhnpMBudfR5xdGqMrj+TOafPOnueQyYNQOvuLDrdWsxEQ8zuJR+MyYT3VhN7d8/Ir+gg\
EFH7U9kQAnN762UVwXkN27He+wN2NaIieS8F7AFVSlORsL/RyQYfqOo74Xlwn32j3NO1VPk58AiZNjrlQqccOWh9xsvRDmvMre+VuLe30ASe2EWeRzRNA\
VkpfsckxXBJND/QFb4QhQG3OHWsx9Kai9FiiUdGOU+V6Q5djUK1cc62lSj0LgAbZ50cicW5SzDnoAfJv4A5Q5TgYdksh/ZHZTZDixD3uohwH1k90bADKT\
I/0rnsM1z9KnqTl4cnT9BXpNP2xa07waj8Pv7wH+hPOxKZPSfBJZaa2NZVQkBiFlalq8l1tyKV9LZ8zTGMOzM2ZRMnUDJpHKa121i6bfmkjdmb8bO+x5e\
XoTo5q0Ujh9Je009G39yPw0Ll4C30xq4F6IE8izUSPoCsn7HAG+iV3lyBb5CSue+XEIEvfc1F3ktT6FNvhrlBPw53UDyV5YyEWw/fM6FhYki4T0L10iKw\
vQvI4G+kdRGIAj+TenS18//mcCz/poySREkutfH9wyqLqYzl/UopMsG/FDqOGBcuNiUFlHir/ec7tbxKl1Dwkzps4POTok1ojzj71Hf2CFIQc5wc78AKf\
sH0x0rcZXQGKwHNY+/QrSyhoEnHEr/Q/ejYPQwTEROg1eQT+mMyQD0m1jOzF9ejVeYT96AEoznEZk4GhuzVN33J6ofeXEH3lyDI0ytW/QRxKtR5yCL9hD\
QkOOxe+PVmSmIuY3IYr1E4mRlNMn8DApH8kgvyTnBfW7Imljikf+6zUmosvYuChE/Ir3qrQ9+lWdidze6TbkXqgjWEE+Ib3Y08E//SIceUxPQdZP7fB4l\
lnc6OFpWoGryyaiv71prbUcPOvJnI499PV3/n4JFXumg7g4YcPhGo1C9ks4KOYY8tVVojw5AvDsXFSOuRgr443TmntR6GmOItbZR9/wi1n3/56w++4esu\
/IOGt56H9vRWRlGCgsonTKe/uNHdzqNoaO+kboFi9U0uvOPr3gWHQUzB1nuk5Hr/PzOHngXwQzkbj+G8jedXnB14AtDIu/Db2vYN1WVyl0biOjYjJLvuY\
BmJLAWCev5qLv9MWBdBvKx2OGaA5QmW0vg+1loMy0lXuVajiqihwET06DH3kiewlDh+DCHrscU7UxoQkn8OlTFPCW05u7WA8p1XoeqtPcCK0I86ECydHw\
q3IFetJNQqLsI6EgxlwZUOb0KGd3xqCKdFqR0940xmLwINhZj+7rNVM9/gQ3X3kX71tq0kLfX1BOtrMlpoj3ZPJG1ewQlEG9Cyej5QOUecrZU0KUGJAz+\
D4FXJYLrDlwHJTynkzj0syj/czUwJIQ7+LuHlMlhKEzIpg8rDK+gXMkJ6IVbv1E0k9CjAqUADkfHqnghGgR/H49aFtqQbPj5qo2osleOGmmLE+FwfxejN\
xtmJJjLIkefE4lXx3bwJgRzHJ6yHMnjAnSKbBnqc/oikBfma3Atge9mAnehgtSfUbU1kcwUoLaNz4ZoEsZ3LAr11zh8IOV1LCFFH1j7drq25nQLaeUnjD\
EYz8PkRWivqaejvimdx7DtHdhoO7sogrI4a40a69YiodxTzlOqRTmEccDggDDkIwKuROHgaSg/FxSYPORJ3Ih4mkdii1+NLOGdSGjzQniGIEVyHQqbfkK\
8PywrcIK6DYXoBShUex5YluEGbkBd5VWoMfQyXM9ZYBPlORrdjaqc99E5qd+BWjfeRc2ec3HFhQAOgzyMW5AXU51gLlWo982gvqLTcX1rAZqWIY/yTtQ/\
NpUsIdB+chtSWiNQSPozVEXtF1oLSI4moUbYx1F+90XE70SJdf9klHLU0Pv5BGsrQf2Pv0Te/S2AfwrwfqgYdjeKeEoCz3pIgc9G+7XLqQzJIPMjJTyTt\
sdkIp7LeWX/Lo5D4JGk8ua8jdUoGX2Z+1yTYjMkw+UfpOdl8EwYklUITegzCGtR4WAO8U72EUhh/BiFZg+jxPsf3PpWI+GcjqzZYlQ9+yLxV3388QpRcn\
g5anI8EnlQ7yFFWY5elTjA4b2SzIsVPs1SMftp1Dk+ntSNouEEeRDeQM2KtyBF8RWU01mPNsYMFMqUoXDnOqAldDLAJyiHMg9t2pOQB7ia+Ks5xzm6PIA\
8gksTrO2PKGS8xq3nVcfHWvf9kchbrUXd9hU9oFlX4sST2teg/M8Vbn5nI69vGTI6MVQFnIx4O8bNZZ6j38YUvFyDZPGHSObedGurRsbtMGQoW5Dnfj9x\
B2EVinC+jQzGq6gy2IhaHE5Fsnszkv20IDOFZSHSr5jIwP5p3R4p7UekfzHRLWnd3h3UI2avIHkI0YEsuH8YXrKqWDvKd1XRtTLShBi+JgHu5ciNTVXls\
YhZRQlwb0bvYyV6p6kBMd13pU90c3mC+D9juAqdNf9l1CKQ79ayBb0CcxtSeFPovNEbHO3+gbyJJahCcwzyCiJuTeuRlf61uzfT3OM6t77KRBfdJvsUeQ\
Ofw/WzJYFapExXE/CSHQ6/g34l8pA+D3wTKeWY41EFsvBPAU1J1rEEVS7PQ6/OnEO8s78BtYHchPrIznZrqw7NJQr8H1IQF6ENfBzxZH6Ve/4epGjDsrs\
5jDddcOO3IA9ngVvLScirPC50eytSDHciRbIIZyy6adH5naPlxUgujyIud1XIW/sNSpwHE/+NSEG/h96s8Ofky/IHKCx9lAyq1wagYswpRyD3sCTVzTYW\
o3DMCIZdfAZecfdNtrGWVrbc+ThtGyrTORr5gv3XPXNf0rGtzSd+fEiDMSbZ2/Me8i4ajTGxFPeUOuLVG2PaA9fyAuM0+uNYa03omY4kuA1yj/MS4C52N\
G4yxrSGnvN/HYgSomVIiD9GGzC43hEodCxFCeh1xN+lK0KWq9EYsz1AuwEol+OHeEXIe/D7Zmodnkp/rB6Uyvs5vM3GmJYk94AUSx7JFQnW2oLgnMP8Dh\
3pMhx5DgOJN1aup5vmxATH9YxBnoN/8sY6pLhwfCvuhncFKME/yt3biDyYT0miHFLJRAZ0D/45CHmvY1Go7CFjvwEZYd/rSnXszkikXOuQkqlFRm2UW19\
/ZEw3uPUlW5v/az9H2+FI2W11c6lNNY9EkJHC8g/ey/gwu/Qiwu4UVle83dyX5jlIKQ/H86/1ZPxUuNPYQGnNI3xPojG6OTgw6ViZQrr/FDRbHqVDr0zW\
kg49suFdus/l6KystKAb/iRSWD3Cl2tZyywk9F+KzgSyO10044Wle+piptdyMX46HcPZri/Zaac9WUumsKt4lOt17Cy652rs3sCVK7y5nlPmSXdj9vgDx\
/ugD/pgzwQ/sdRB7//jg1z9w9I+6IM++CcF38NaiSpM43ppHttQybQP+qAPdg/w3E9vOzKdwFdYNaifo7f+rVI251T1QR/0QW6hFb3q1kxuTjnJGfw/7n\
uCA1QPoE0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDctMThUMTU6NDI6MTcrMDA6MDChRr//AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA3LTE4VDE\
1OjQyOjE3KzAwOjAw0BsHQwAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAAASUVORK5CYII=';

export default encoded;