import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronRightIcon, ChevronLeftIcon } from '@mtb/ui';
import './GridPager.scss';


const ChevronPager = (props) => {

  const {
    currentPage,
    onPageChange,
    totalRows,
    pageSize,
    label = '',
    disabled
  } = props;

  const [t] = useTranslation();
  const totalPages = Math.ceil(totalRows / pageSize);

  const incrementPage = (incr) => {
    const now = currentPage;
    const then = Math.min(totalPages, Math.max(1, (now + incr)));
    if (now !== then) {
      onPageChange(then);
    }
  };

  return (
    <div className="chevronPager">
      <div className="entries-text">
        {t('chevronCounts', {
          firstRow: totalRows === 0 ? 0 : (pageSize * (currentPage - 1)) + 1,
          lastRow : Math.min(totalRows === 0 ? 0 : (pageSize * currentPage), totalRows),
          totalRows,
          label
        })}
      </div>
      <ChevronLeftIcon
        className={classNames(currentPage === 1 || disabled ? 'icon-disabled' : 'clickable')}
        onClick={() => !disabled && incrementPage(-1)} />
      <ChevronRightIcon
        className={classNames(currentPage === totalPages || totalPages === 0 || disabled ? 'icon-disabled' : 'clickable'
        )}
        onClick={() => !disabled && incrementPage(1)} />
    </div>
  );
};

export default ChevronPager;