import React, { useState, useContext } from 'react';
import Sessions from '../api/sessions';
import { useTranslation } from 'react-i18next';
import './dialogs.scss';
import { FlagsContext } from '../utils/context';
import { CUSTOM_REL_DATE, CUSTOM_TODAY } from '../utils/software-update-utils';

const FlagsDialog = ({ setShowFlagsDialog, setFlags, setResetSoftwareUpdateValues }) => {
  const initialToday = localStorage.getItem(CUSTOM_TODAY) ?? new Date().toISOString().split('T')[0];
  const initialRelDate = localStorage.getItem(CUSTOM_REL_DATE) ?? process.env.REACT_APP_FEATURE_LAST_RELEASE;

  const [sessionTimeout, setSessionTimeout] = useState(sessionStorage.sessionTimeout || '');
  const [warningTimeout, setWarningTimeout] = useState(sessionStorage.warningTimeout || '');
  const [today, setToday] = useState(initialToday);
  const [lastRelDate, setLastRelDate] = useState(initialRelDate);
  const [clearLocalStorage, setClearLocalStorage] = useState(false);

  const flags = useContext(FlagsContext);

  const filterFlags = () => {
    const filteredFlags = Object.entries(flags).filter(([key, ]) => {
      return ['enableScoreLogging', 'isr', 'var_importance', 'modelenhancements', 'drift_navigation'].includes(key);
    });
    return Object.fromEntries(filteredFlags);
  };

  const [flagsCopy, setFlagsCopy] = useState(filterFlags());
  const [t] = useTranslation();

  const onCheckbox = (flag) => {
    const newFlag = { [flag]: !flagsCopy[flag] };
    setFlagsCopy({ ...flagsCopy, ...newFlag });
  };

  const updateFlags = async () => {
    try {
      await Sessions.updateFlags({ flags: flagsCopy });
      setFlags({ ...flags, ...flagsCopy });
    } catch (e) {
      console.error(e);
    } finally {
      setShowFlagsDialog(false);
    }
  };

  return (
    <div className="dialogContainer">
      <div className="modalDialog flags">
        <div>
          <h4>{t('modifyFlags')}</h4>
          {Object.keys(flagsCopy).map((flag) => (
            <div
              key={flag}
              className="flagCheckBox">
              <input
                checked={flagsCopy[flag]}
                id={`${flag}Checkbox`}
                type="checkbox"
                onChange={() => onCheckbox(flag)} />
              <label>{flag}</label>
            </div>
          ))}
        </div>
        <br />
        <hr />
        <br />
        <div>
          <h4>Session Time Out</h4>
          <label>Session timeout in seconds:</label>
          <input
            type='textbox'
            value={sessionTimeout}
            onChange={({ target: { value } }) => setSessionTimeout(value)} />
        </div>
        <div>
          <label>Session warning time in seconds:</label>
          <input
            type='textbox'
            value={warningTimeout}
            onChange={({ target: { value } }) => setWarningTimeout(value)} />
        </div>
        <br />
        <hr />
        <br />
        <div>
          <h4>Software Update Test Settings</h4>
          <label>Today (UTC):
            <input
              type='date'
              value={today}
              onChange={e => setToday(e.target.value)} />
          </label>
          <br />
          <label>Last Release Date (UTC):
            <input
              type='date'
              value={lastRelDate}
              onChange={e => setLastRelDate(e.target.value)} />
          </label>
          <br />
          <input
            type='checkbox'
            value={clearLocalStorage}
            onChange={e => setClearLocalStorage(e.target.checked)} />
          <label>Reset localStorage (Resets today and last release date to default values,
             clears read dialog history)</label>
        </div>
        <div className="buttons">
          <button
            className="cancelButton"
            onClick={() => {
              setShowFlagsDialog(false);
              setFlagsCopy(filterFlags());
            }}>
            {t('cancel')}
          </button>
          <button
            className="confirmButton"
            onClick={() => {
              updateFlags();
              if (!sessionTimeout) {
                sessionStorage.removeItem('sessionTimeout');
              } else {
                sessionStorage.sessionTimeout = sessionTimeout;
              }
              if (!warningTimeout) {
                sessionStorage.removeItem('warningTimeout');
              } else {
                sessionStorage.warningTimeout = warningTimeout;
              }

              if (today !== initialToday || lastRelDate !== initialRelDate) {
                localStorage.setItem(CUSTOM_TODAY, today);
                localStorage.setItem(CUSTOM_REL_DATE, lastRelDate);
                setResetSoftwareUpdateValues(true);
              }

              if (clearLocalStorage) {
                localStorage.clear();
                setResetSoftwareUpdateValues(true);
              }
            }}>
            {t('refresh')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlagsDialog;